import React, { useContext, useEffect, useRef, useState } from "react";
import PopUpAlertComponent from "../../../../components/extra/PopUpAlertComponent";
import { AuthContext } from "../../../../context/AuthContext";
import UpdateStaffAttendancePayrollPopup from "../UpdateStaffAttendancePayrollPopup";
import CreateStaffTransaction from "../CreateStaffTransaction";
import ViewStaffTransaction from "../ViewStaffTransaction";

const ViewStaffAttendancePayrollComponent = ({
  open,
  staffId,
  setStaffId,
  // staffData,
  setStaffData,
  businessData,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  handleAttendance,
  handleUpdateAttendance,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  const [editAttendanceOption, setEditAttendanceOption] = useState(null);

  // useStates  ================================================================================================================

  const [popUpAlert, setPopUpAlert] = useState(false);

  const [updateStaff, setUpdateStaff] = useState(false);

  const [viewType, setViewType] = useState("attendance");

  const [createTransaction, setCreateTransaction] = useState(false);

  const [viewTransaction, setViewTransaction] = useState(false);

  const [transactionId, setTransactionId] = useState(null);

  const { deleteSingleStaff } = useContext(AuthContext);

  const handleDelete = async (e) => {
    await deleteSingleStaff(
      userId,
      businessId,
      staffId,
      open,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus
    );
    fetchBusinessData && fetchBusinessData();
  };

  // ================================================================================================================

  const staffData = businessData.staffData.find((e) => e._id === staffId);

  // ================================================================================================================
  // handle dates According to user  ================================================================================================================
  // ================================================================================================================

  const today = new Date();

  if (staffData.staffSalaryCycle > today.getDate()) {
    // setFirstDay()
    today.setMonth(today.getMonth() - 1);
    today.setDate(Number(staffData.staffSalaryCycle));
    // todaysDate.setDate(26);
  } else {
    today.setDate(Number(staffData.staffSalaryCycle));
  }

  const [dates, setDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(today); // Track the current month displayed
  const [canGoNext, setCanGoNext] = useState(true); // Track if we can go to the next month

  useEffect(() => {
    const generateDatesFromTo = (monthDate) => {
      // Get the year and month from the current month
      const month = monthDate.getMonth(); // 0-indexed (0 = January, 1 = February, etc.)
      const year = monthDate.getFullYear();

      // console.log("month is :", month)
      // console.log("year is :", year)

      // Get the start date (10th of the current month)

      const startDate = new Date(
        year,
        month,
        Number(staffData && staffData.staffSalaryCycle)
      );

      // Get the end date (10th of the next month)
      const endDate = new Date(
        year,
        month + 1,
        Number(staffData && staffData.staffSalaryCycle) - 1
      );

      // Generate an array of dates from 10th of the current month to 10th of the next month
      const days = [];
      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        // Only include dates up to today's date (exclude future dates)
        if (date <= new Date()) {
          days.push(date.toDateString());
        }
      }
      return days;
    };

    setDates(generateDatesFromTo(currentMonth).reverse()); // Set dates from the 10th to 10th range

    // Disable the Next Month button if the 10th of next month is in the future
    const nextMonthDate = new Date(currentMonth);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    nextMonthDate.setDate(10);

    // If the 10th of next month is in the future, disable the button
    setCanGoNext(nextMonthDate <= new Date());
  }, [currentMonth, staffData]);

  // console.log("dates are:", dates)

  // Function to go to the previous month
  const handlePreviousMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1); // Go to the previous month
    setCurrentMonth(prevMonth); // Update the current month state
  };

  // Function to go to the previous month
  const handleNextMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() + 1); // Go to the previous month
    setCurrentMonth(prevMonth); // Update the current month state
  };

  // ================================================================================================================
  // ============================================================================================
  // ============================================================================================
  // ============================================================================================

  const attendanceOptionRef = useRef(null);
  const attendanceOptionListRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      attendanceOptionListRef.current &&
      !attendanceOptionListRef.current.contains(event.target) &&
      attendanceOptionRef.current &&
      !attendanceOptionRef.current.contains(event.target)
    ) {
      setEditAttendanceOption(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ============================================================================================
  // ============================================================================================

  let firstDate = new Date(dates[dates.length - 1]);

  // const prevMonth = new Date(currentMonth);
  // prevMonth.setMonth(prevMonth.getMonth() + 1);
  // setCurrentMonth(prevMonth);

  let setLastDate = new Date(dates[dates.length - 1]);
  setLastDate.setMonth(setLastDate.getMonth() + 1);

  let lastDate = new Date(setLastDate);

  //  total days =======================================

  // Get the difference in milliseconds
  const differenceInMilliseconds = lastDate - firstDate;

  // Convert milliseconds to days (1000ms = 1 second, 60 seconds = 1 minute, 60 minutes = 1 hour, 24 hours = 1 day)
  const totalDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // console.log("firstDate is :", firstDate)

  // const filteredMonthData = staffData.attendanceData

  // const filteredMonthData = staffData.attendanceData.filter( e => new Date(e.date).toLocaleDateString('en-GB') >= new Date(firstDate).toLocaleDateString('en-GB') && new Date(e.date).toLocaleDateString('en-GB') >= new Date(lastDate).toLocaleDateString('en-GB') )

  // const filteredMonthData = staffData.attendanceData.filter( e => ( new Date(e.date).toLocaleDateString('en-GB') <= new Date(dates[0]).toLocaleDateString('en-GB') ) && ( new Date(e.date).toLocaleDateString('en-GB') >= new Date(dates[dates.length-1]).toLocaleDateString('en-GB') ) )

  // const filteredMonthData = staffData.attendanceData
  const filteredMonthData = staffData.attendanceData.filter(
    (e) =>
      new Date(e.date) >= new Date(firstDate) &&
      new Date(e.date) <= new Date(lastDate)
  );

  const totalPresent = filteredMonthData.filter(
    (e) => e.attendanceStatus === "present"
  ).length;
  const totalAB = filteredMonthData.filter(
    (e) => e.attendanceStatus === "absent"
  ).length;
  const totalHD = filteredMonthData.filter(
    (e) => e.attendanceStatus === "half-day"
  ).length;
  const totalPL = filteredMonthData.filter(
    (e) => e.attendanceStatus === "paid-leave"
  ).length;
  const totalWO = filteredMonthData.filter(
    (e) => e.attendanceStatus === "week-off"
  ).length;

  // ====================================================================================================================
  // ====================================================================================================================

  const dailySalary = staffData.staffSalary / totalDays;

  const payDays = totalPresent + totalPL + totalWO + totalHD / 2;

  const totalEarning = dailySalary * payDays;

  // ====================================================================================================================
  // salary calculation =================================================================================================
  // ====================================================================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  const monthName = (e) => {
    // const monthNames = [
    //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
    //   'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    // ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = Number(e.split("-")[1]) - 1;

    const year = Number(e.split("-")[0]);

    return `${monthNames[month]} ${year}`;
  };

  const thisMonth = currentMonth.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const thisMonthTransactions = businessData.staffTransactionData
    .filter((e) => e.staffId === staffId)
    .filter((e) => monthName(e.paymentMonth) === thisMonth);

  const salaryPayment = thisMonthTransactions
    .filter((e) => monthName(e.paymentMonth) === thisMonth)
    .filter((e) => e.transactionType === "Salary")
    .reduce((sum, e) => sum + e.transactionAmount, 0);

  const bonusPayment = thisMonthTransactions
    .filter((e) => monthName(e.paymentMonth) === thisMonth)
    .filter((e) => e.transactionType === "Bonus")
    .reduce((sum, e) => sum + e.transactionAmount, 0);

  const advancePayment = thisMonthTransactions
    .filter((e) => monthName(e.paymentMonth) === thisMonth)
    .filter((e) => e.transactionType === "Advance")
    .reduce((sum, e) => sum + e.transactionAmount, 0);

  const totalPayment = salaryPayment + bonusPayment + advancePayment;

  return (
    <div>
      {viewTransaction ? (
        <ViewStaffTransaction
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          open={setViewTransaction}
          transactionId={transactionId}
          businessData={businessData}
          fetchBusinessData={fetchBusinessData}
          staffId={staffId}
          staffData={staffData}
        />
      ) : (
        ""
      )}

      {popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete staff data?`}
        />
      ) : (
        ""
      )}

      {updateStaff ? (
        <UpdateStaffAttendancePayrollPopup
          open={setUpdateStaff}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          staffData={staffData}
          staffId={staffId}
          setStaffId={setStaffId}
          fetchBusinessData={fetchBusinessData}
          setStaffData={setStaffData}
        />
      ) : (
        ""
      )}
      {createTransaction ? (
        <CreateStaffTransaction
          open={setCreateTransaction}
          staffData={staffData}
          staffId={staffId}
          businessData={businessData}
          fetchBusinessData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="view-staff">
        <div className="view-staff-main">
          {/* **************************************************************************************************** */}

          <div className="view-staff-col-filter">
            <div className="view-staff-filter-head">
              <span>Staff</span>
              {/* <div>+ Add Staff</div> */}
            </div>
            <div className="view-staff-filter-data">
              {businessData.staffData.map((e) => (
                <div
                  onClick={() => {
                    setStaffData(e);
                    setStaffId(e._id);
                  }}
                  className={
                    e._id === staffId
                      ? "view-staff-filter-data-list-active"
                      : ""
                  }
                >
                  <div className="view-staff-filter-data-heading">
                    {e.staffName}
                  </div>
                  {/* <div className="view-staff-filter-data-value">
                  <i className="fa-solid fa-arrow-up"></i>₹ 100
                </div> */}
                </div>
              ))}
            </div>
          </div>

          <div className="view-staff-col-data">
            <div className="view-staff-top">
              <div className="view-staff-top-heading">
                <i
                  class="fa-solid fa-arrow-left"
                  onClick={() => open(false)}
                ></i>{" "}
                {staffData && staffData.staffName}
              </div>
              <div className="view-staff-top-buttons">
                {/* <div className="view-staff-top-download">
                  Download Salary Slip <i class="fa-solid fa-download"></i>
                </div> */}
                <div
                  className="view-staff-top-payment"
                  onClick={() => {
                    setCreateTransaction(true);
                    console.log("currentMonth is :", currentMonth);
                  }}
                >
                  Make Payment <i class="fa-solid fa-angle-down"></i>
                </div>
              </div>
            </div>

            <div className="view-staff-top-options">
              <div className="view-staff-options">
                <div
                  onClick={() => setViewType("attendance")}
                  className={
                    viewType === "attendance" ? "view-staff-option-active" : ""
                  }
                >
                  Attendance
                </div>
                <div
                  onClick={() => setViewType("payroll")}
                  className={
                    viewType === "payroll" ? "view-staff-option-active" : ""
                  }
                >
                  Payroll
                </div>
                <div
                  onClick={() => setViewType("transaction")}
                  className={
                    viewType === "transaction" ? "view-staff-option-active" : ""
                  }
                >
                  Transactions
                </div>
                <div
                  onClick={() => setViewType("details")}
                  className={
                    viewType === "details" ? "view-staff-option-active" : ""
                  }
                >
                  Details
                </div>
              </div>
              {viewType === "details" ? (
                <div className="view-staff-top-options-buttons">
                  <div onClick={() => setUpdateStaff(true)}>Edit</div>
                  <div onClick={() => setPopUpAlert(true)}>Delete</div>
                </div>
              ) : (
                ""
              )}
            </div>

            {viewType === "attendance" ? (
              <div className="app-staff-page-data">
                <div className="app-staff-page-data-top">
                  <div className="app-staff-page-data-top-date">
                    {/* {new Date().toDateString()} */}

                    {currentMonth.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}

                    {/* _______________{firstDate.toDateString()}
                  _______________{lastDate.toDateString()} */}
                  </div>
                  <div className="app-staff-page-data-top-option">
                    <i
                      onClick={handlePreviousMonth}
                      className="fa-solid fa-angle-left"
                    ></i>
                    <span className="app-staff-page-data-top-option-month">
                      {currentMonth.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                    {canGoNext ? (
                      <i
                        onClick={handleNextMonth}
                        className="fa-solid fa-angle-right"
                      ></i>
                    ) : (
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    )}
                  </div>
                </div>

                <div className="app-staff-page-data-table">
                  <div className="app-staff-page-data-table-top">
                    <div>
                      <div className="app-staff-page-data-table-top-heading">
                        Present (P)
                      </div>
                      <div className="app-staff-page-data-table-top-value">
                        {totalPresent}
                      </div>
                    </div>
                    <div>
                      <div className="app-staff-page-data-table-top-heading">
                        Absent (A)
                      </div>
                      <div className="app-staff-page-data-table-top-value">
                        {totalAB}
                      </div>
                    </div>
                    <div>
                      <div className="app-staff-page-data-table-top-heading">
                        Half day (HD)
                      </div>
                      <div className="app-staff-page-data-table-top-value">
                        {totalHD}
                      </div>
                    </div>
                    <div>
                      <div className="app-staff-page-data-table-top-heading">
                        Paid Leave (PL)
                      </div>
                      <div className="app-staff-page-data-table-top-value">
                        {totalPL}
                      </div>
                    </div>
                    <div>
                      <div className="app-staff-page-data-table-top-heading">
                        Weekly off (WO)
                      </div>
                      <div className="app-staff-page-data-table-top-value">
                        {totalWO}
                      </div>
                    </div>
                  </div>

                  <div className="view-staff-attendance-table">
                    <div className="view-staff-attendance-table-head">
                      <div>DATE</div>
                      <div onClick={() => fetchBusinessData()}>ATTENDANCE</div>
                    </div>

                    <div className="view-staff-attendance-table-data">
                      {dates.map((e) => (
                        <div className="view-staff-attendance-table-data-body">
                          <div>{new Date(e).toDateString()}</div>

                          {/* <div>present</div> */}

                          {staffData &&
                          staffData.attendanceData.filter(
                            (a) =>
                              new Date(a.date).toLocaleDateString() ===
                              new Date(e).toLocaleDateString()
                          ).length > 0 ? (
                            staffData &&
                            staffData.attendanceData
                              .filter(
                                (a) =>
                                  new Date(a.date).toLocaleDateString() ===
                                  new Date(e).toLocaleDateString()
                              )
                              .map((a) => (
                                <div className="app-staff-attendance-options">
                                  <div
                                    onClick={() =>
                                      handleUpdateAttendance(
                                        staffData._id,
                                        a._id,
                                        e,
                                        "present"
                                      )
                                    }
                                    className={
                                      a.attendanceStatus === "present"
                                        ? "app-staff-attendance-present"
                                        : ""
                                    }
                                  >
                                    P
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleUpdateAttendance(
                                        staffData._id,
                                        a._id,
                                        e,
                                        "absent"
                                      )
                                    }
                                    className={
                                      a.attendanceStatus === "absent"
                                        ? "app-staff-attendance-absent"
                                        : ""
                                    }
                                  >
                                    A
                                  </div>
                                  <div
                                    onClick={() =>
                                      setEditAttendanceOption(a._id)
                                    }
                                  >
                                    <i class="fa-solid fa-ellipsis-vertical"></i>
                                  </div>

                                  <div
                                    div
                                    className="app-staff-attendance-type"
                                    ref={attendanceOptionRef}
                                  >
                                    {editAttendanceOption === a._id ? (
                                      <div
                                        className="app-staff-attendance-type-options"
                                        ref={attendanceOptionListRef}
                                      >
                                        <div
                                          onClick={() => {
                                            handleUpdateAttendance(
                                              staffData._id,
                                              a._id,
                                              e,
                                              "half-day"
                                            );
                                            setEditAttendanceOption(null);
                                          }}
                                        >
                                          Half Day
                                        </div>
                                        <div
                                          onClick={() => {
                                            handleUpdateAttendance(
                                              staffData._id,
                                              a._id,
                                              e,
                                              "paid-leave"
                                            );
                                            setEditAttendanceOption(null);
                                          }}
                                        >
                                          Paid Leave
                                        </div>
                                        <div
                                          onClick={() => {
                                            handleUpdateAttendance(
                                              staffData._id,
                                              a._id,
                                              e,
                                              "week-off"
                                            );
                                            setEditAttendanceOption(null);
                                          }}
                                        >
                                          Week Off
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {a.attendanceStatus === "half-day" ? (
                                    <div
                                      className={
                                        a.attendanceStatus === "half-day"
                                          ? "app-staff-attendance-half-day"
                                          : ""
                                      }
                                    >
                                      HD
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {a.attendanceStatus === "week-off" ? (
                                    <div
                                      className={
                                        a.attendanceStatus === "week-off"
                                          ? "app-staff-attendance-week-off"
                                          : ""
                                      }
                                    >
                                      WO
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {a.attendanceStatus === "paid-leave" ? (
                                    <div
                                      className={
                                        a.attendanceStatus === "paid-leave"
                                          ? "app-staff-attendance-paid-leave"
                                          : ""
                                      }
                                    >
                                      PL
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))
                          ) : (
                            <div className="app-staff-attendance-options">
                              <div
                                onClick={() =>
                                  handleAttendance(staffData._id, e, "present")
                                }
                              >
                                P
                              </div>
                              <div
                                onClick={() =>
                                  handleAttendance(staffData._id, e, "absent")
                                }
                              >
                                A
                              </div>
                              <div onClick={() => setEditAttendanceOption(e)}>
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                              </div>
                              <div
                                div
                                className="app-staff-attendance-type"
                                ref={attendanceOptionRef}
                              >
                                {editAttendanceOption === e ? (
                                  <div
                                    className="app-staff-attendance-type-options"
                                    ref={attendanceOptionListRef}
                                  >
                                    <div
                                      onClick={() => {
                                        handleAttendance(
                                          staffData._id,
                                          e,
                                          "half-day"
                                        );
                                        setEditAttendanceOption(null);
                                      }}
                                    >
                                      Half Day
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleAttendance(
                                          staffData._id,
                                          e,
                                          "paid-leave"
                                        );
                                        setEditAttendanceOption(null);
                                      }}
                                    >
                                      Paid Leave
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleAttendance(
                                          staffData._id,
                                          e,
                                          "week-off"
                                        );
                                        setEditAttendanceOption(null);
                                      }}
                                    >
                                      Week Off
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : viewType === "details" ? (
              <div className="app-staff-page-details-data">
                <div className="app-staff-page-details-data-row-3">
                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Staff Name
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {staffData && staffData.staffName}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Mobile Number
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {staffData && staffData.staffNumber}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Opening Balance
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      ₹ {staffData && staffData.openingBalance}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Salary
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      ₹ {staffData && staffData.staffSalary}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Salary Type
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {staffData && staffData.staffPayoutType}
                    </div>
                  </div>

                  <div className="app-staff-page-details-data-col">
                    <div className="app-staff-page-details-data-col-heading">
                      Salary Cycle
                    </div>
                    <div className="app-staff-page-details-data-col-value">
                      {staffData && staffData.staffSalaryCycle} To{" "}
                      {staffData && staffData.staffSalaryCycle} Every Month
                    </div>
                  </div>
                </div>
              </div>
            ) : viewType === "transaction" ? (
              <div className="app-staff-page-transaction-data">
                <div className="app-staff-page-data-table">
                  <div className="app-staff-page-data-table-head">
                    <div>Date of Payment</div>
                    <div>Payment Type</div>
                    <div>Amount</div>
                    <div>Month</div>
                  </div>
                  <div className="app-staff-page-data-table-body">
                    {businessData &&
                      businessData.staffTransactionData
                        .filter((e) => e.staffId === staffId)
                        .reverse()
                        .map((e) => (
                          <div
                            className="app-staff-page-data-table-body-data"
                            onClick={() => {
                              setTransactionId(e._id);
                              setViewTransaction(true);
                            }}
                          >
                            <div>
                              {new Date(e.transactionDate).toDateString()}
                            </div>
                            <div>{e.transactionType}</div>
                            <div>₹ {formatNumber(e.transactionAmount)}</div>
                            <div>
                              {e.transactionType === "Loan"
                                ? "-"
                                : monthName(e.paymentMonth)}
                            </div>
                          </div>
                        ))}
                    {businessData &&
                    businessData.staffTransactionData.filter(
                      (e) => e.staffId === staffId
                    ).length > 0 ? (
                      ""
                    ) : (
                      <div className="app-staff-page-data-table-empty">
                        No Payments
                      </div>
                    )}
                  </div>
                  {/* <div className="app-staff-page-data-table-end">
                  <div>Pending Amount</div>
                  <div></div>
                  <div>0</div>
                  <div>0</div>
                  <div></div>
                </div> */}
                </div>
              </div>
            ) : viewType === "payroll" ? (
              <div className="app-staff-page-data">
                <div className="app-staff-page-data-top">
                  <div className="app-staff-page-data-top-date">
                    {/* {new Date().toDateString()} */}

                    {currentMonth.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}

                    {/* _______________{firstDate.toDateString()}
                _______________{lastDate.toDateString()} */}
                  </div>
                  <div className="app-staff-page-data-top-option">
                    <i
                      onClick={handlePreviousMonth}
                      className="fa-solid fa-angle-left"
                    ></i>
                    <span className="app-staff-page-data-top-option-month">
                      {currentMonth.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                    {canGoNext ? (
                      <i
                        onClick={handleNextMonth}
                        className="fa-solid fa-angle-right"
                      ></i>
                    ) : (
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    )}
                  </div>
                </div>

                <div className="app-staff-page-data-table">
                  <div className="app-staff-page-payroll-table">
                    <div className="app-staff-page-payroll-heading">
                      <b>
                        {currentMonth.toLocaleString("default", {
                          month: "long",
                        })}
                      </b>{" "}
                      {dates.length > 0 ? (
                        <>
                          (
                          {firstDate.toLocaleString("default", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}{" "}
                          -{" "}
                          {lastDate.toLocaleString("default", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                          )
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                    <div className="app-staff-page-payroll-table-head">
                      <div>Earnings</div>
                      <div>₹ {formatNumber(totalEarning)}</div>
                    </div>
                    <div className="app-staff-page-payroll-table-data">
                      {totalPresent > 0 ? (
                        <div className="app-staff-page-payroll-table-data-body">
                          <div>Present ({totalPresent} Days)</div>
                          <div>
                            ₹ {formatNumber(totalPresent * dailySalary)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {totalPL > 0 ? (
                        <div className="app-staff-page-payroll-table-data-body">
                          <div>Paid Leave ({totalPL} Days)</div>
                          <div>₹ {formatNumber(totalPL * dailySalary)}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {totalWO > 0 ? (
                        <div className="app-staff-page-payroll-table-data-body">
                          <div>Week Off ({totalWO} Days)</div>
                          <div>₹ {formatNumber(totalWO * dailySalary)}</div>
                        </div>
                      ) : (
                        ""
                      )}

                      {totalHD > 0 ? (
                        <div className="app-staff-page-payroll-table-data-body">
                          <div>Half Day ({totalHD} Days)</div>
                          <div>
                            ₹ {formatNumber((totalHD * dailySalary) / 2)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="app-staff-page-payroll-table-head">
                      <div>Payment</div>
                      <div>₹ {formatNumber(totalPayment)}</div>
                    </div>

                    {salaryPayment > 0 ? (
                      <div className="app-staff-page-payroll-table-data-body">
                        <div>Salary </div>
                        <div>₹ {formatNumber(salaryPayment)}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    {bonusPayment > 0 ? (
                      <div className="app-staff-page-payroll-table-data-body">
                        <div>Bonus </div>
                        <div>₹ {formatNumber(bonusPayment)}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    {advancePayment > 0 ? (
                      <div className="app-staff-page-payroll-table-data-body">
                        <div>Advance Payment </div>
                        <div>₹ {formatNumber(advancePayment)}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStaffAttendancePayrollComponent;
