import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import ViewItemPage from "./view/ViewItemPage";
import UpdateItemStock from "./update/UpdateItemStock";
import CreateItemPopup from "../form/CreateItemPopup";
import ViewItemComponent from "../form/components/ViewItemComponent";

const AppItemPage = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useState ============================================================================================
  const [fetchData, setFetchData] = useState();
  const [singlefetchData, setSingleFetchData] = useState();
  const [singlefetchDataId, setSingleFetchDataId] = useState();

  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [search, setsearch] = useState("");
  const [updateStock, setUpdateStock] = useState(false);

  const [createItemForm, setCreateItemForm] = useState(false);

  const [viewData, setViewData] = useState(false);
  
  const [businessData, setBusinessData] = useState(null)

  //  ============================================================================================
  const { fetchItems, fetchBusiness } = useContext(AuthContext);

  const fetchItemData = (e) => fetchItems(userId, businessId, setFetchData);
  const fetchBusinessData = (e) => fetchBusiness(userId, businessId, setBusinessData);

  useEffect(() => {
    fetchItemData();
    fetchBusinessData();
  }, []);

  //  filtered data ==================================================================================================================

  const [filteredData, setFilteredData] = useState("");

  // sort ascending ------------------------------------------------------------------------------

  const [sortOrder, setSortOrder] = useState("");

  const handleAtoZ = () => {
    setSortOrder("z-a");
    const sorted = [...fetchData].sort((a, b) => {
      return a.itemName.localeCompare(b.itemName);
    });
    setFetchData(sorted);
  };

  // sort descending ------------------------------------------------------------------------------

  const handleZtoA = () => {
    setSortOrder("a-z");
    const sorted = [...fetchData].sort((a, b) => {
      return b.itemName.localeCompare(a.itemName);
    });
    setFetchData(sorted);
  };

  // sort number ascending ------------------------------------------------------------------------------

  const handlePriceAcs = () => {
    setSortOrder("p-9-1");
    const sorted = [...fetchData].sort((a, b) => {
      return a.itemPrice - b.itemPrice;
    });
    setFetchData(sorted);
  };

  // sort number descending ------------------------------------------------------------------------------

  const handlePriceDes = () => {
    setSortOrder("p-1-9");
    const sorted = [...fetchData].sort((a, b) => {
      return b.itemPrice - a.itemPrice;
    });
    setFetchData(sorted);
  };


  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  if (!fetchData && !businessData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div>
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      {createItemForm ? (
        <CreateItemPopup
          open={setCreateItemForm}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchItemData}
        />
      ) : (
        ""
      )}

      {updateStock ? (
        <UpdateItemStock
          open={setUpdateStock}
          itemData={singlefetchData}
          fetchData={fetchItemData}
          fetchItemData={fetchItemData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
        />
      ) : (
        ""
      )}

      {viewData ? (
        <ViewItemComponent
          open={setViewData}
          data={singlefetchData}
          itemData={fetchData}
          setSingleFetchData={setSingleFetchData}
          setSingleFetchDataId={setSingleFetchDataId}
          fetchData={fetchItemData}
          setTimerAlertStatus={setTimerAlertStatus}

          
          
          dataId={singlefetchDataId}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          businessData={businessData}

        />
      ) : (
        ""
      )}

      <div className="app-page">
        <div className="app-main-page">
          <div className="app-page-top">
            <div className="app-page-top-col">
              <div className="app-page-top-heading">Items</div>
            </div>
            <div className="app-page-top-col">
              <div className="app-page-top-search">
                <i className="fa-solid fa-magnifying-glass"></i>{" "}
                <input
                  type="text"
                  placeholder="search items...."
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
                {search ? (
                  <i
                    onClick={(e) => setsearch("")}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                ) : (
                  ""
                )}
              </div>

              {/* <Link to="/app/add-item" className="app-page-top-button"> */}
              <div
                className="app-page-top-button"
                onClick={() => setCreateItemForm(true)}
              >
                Create Item
              </div>
            </div>
          </div>

          <div className="app-page-filter app-page-filter-2">
            {/* <div className="app-page-filter-col-1 app-page-filter-col-active"> */}
            <div
              onClick={(e) => setFilteredData("")}
              className={
                filteredData === ""
                  ? "app-page-filter-col-1 app-page-filter-col-active"
                  : "app-page-filter-col-1"
              }
            >
              <div className="app-page-filter-col-icon">
                {/* <i className="fa-solid fa-users"></i> */}
                <i className="fa-solid fa-store"></i>
              </div>
              <div className="app-page-filter-col-text">
                {fetchData && fetchData.length}
                <span>All Items</span>
              </div>
            </div>

            {/* <div onClick={e=>setFilteredData("To Collect")} className={filteredData === "To Collect" ?"app-page-filter-col-2 app-page-filter-col-active" : "app-page-filter-col-2"}>
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text" >
              {fetchData.filter(e=>e.partyOpeningBalanceType === "To Collect").reduce((sum, item) => sum + item.partyOpeningBalance,0).toLocaleString()}
               
                <span>To Collect</span>
              </div>
            </div> */}

            <div
              onClick={(e) => setFilteredData("stockFilter")}
              className={
                filteredData === "stockFilter"
                  ? "app-page-filter-col-3 app-page-filter-col-active"
                  : "app-page-filter-col-3"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-store-slash"></i>
              </div>
              <div className="app-page-filter-col-text">
                {
                  fetchData && fetchData.filter(
                    (e) =>
                      e.itemType === "Product" &&
                      e.enableStock &&
                      e.itemStock < 1
                  ).length
                }

                <span>Out Of stock</span>
              </div>
            </div>
          </div>

          {/* <div className="app-page-table app-page-table-party"> */}
          <div className="app-page-table app-page-table-item">
            <div className="app-page-table-head">
              {/* <div>Item Name</div> */}

              {sortOrder === "z-a" ? (
                <div
                  onClick={handleZtoA}
                  className="app-page-table-head-filter"
                >
                  <>
                    Item Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i>
                    </div>
                  </>
                </div>
              ) : sortOrder === "a-z" ? (
                <div
                  onClick={handleAtoZ}
                  className="app-page-table-head-filter"
                >
                  <>
                    Item Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              ) : (
                <div
                  onClick={handleAtoZ}
                  className="app-page-table-head-filter"
                >
                  <>
                    Item Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              )}

              <div>Item Type</div>
              <div>Item Code</div>

              {sortOrder === "p-9-1" ? (
                <div
                  onClick={handlePriceDes}
                  className="app-page-table-head-filter"
                >
                  <>
                    Selling Price{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i>
                    </div>
                  </>
                </div>
              ) : sortOrder === "p-1-9" ? (
                <div
                  onClick={handlePriceAcs}
                  className="app-page-table-head-filter"
                >
                  <>
                  Selling Price{" "}
                    <div>
                      <i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              ) : (
                <div
                  onClick={handlePriceAcs}
                  className="app-page-table-head-filter"
                >
                  <>
                  Selling Price{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              )}
              <div>Purchase Price</div>

              {/* <div>Balance</div> */}
            </div>

            <div className="app-page-table-body">
              {
                // fetchData
                fetchData && fetchData
                  .filter((e) =>
                    filteredData === "stockFilter"
                      ? e.itemType === "Product" &&
                        e.enableStock &&
                        e.itemStock < 1
                      : e
                  )
                  .filter((e) =>
                    e.itemName
                      .toLocaleLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((e) => (
                    <div key={e._id} className="app-page-table-body-data">
                      <div
                        onClick={(a) => {
                          setViewData(true);
                          setSingleFetchData(e);
                          setSingleFetchDataId(e._id);
                        }}
                      >
                        {e.itemName}
                      </div>
                     
                     

                      <div
                        onClick={(a) => {
                          setViewData(true);
                          setSingleFetchData(e);
                          setSingleFetchDataId(e._id);
                        }}
                      >
                        {e.itemType ? e.itemType : "-"}
                      </div>


                      <div
                        onClick={(a) => {
                          setViewData(true);
                          setSingleFetchData(e);
                          setSingleFetchDataId(e._id);
                        }}
                      >
                        {/* {
                          e.itemType === "Product"?
                          <>
                          {e.itemOpeningStock} {e.itemMeasuringUnit}
                          </>
                          :"-"
                      } */}

                      {e.itemCode?e.itemCode:"-"}

                      </div>

 
                      <div
                        onClick={(a) => {
                          setViewData(true);
                          setSingleFetchData(e);
                          setSingleFetchDataId(e._id);
                        }}
                      > 
                        {e.itemPrice ? <>Rs. {
                          ( e.salePriceType === "with-tax"
                            ? e.itemTax === "0" ||
                              e.itemTax === "0.1" ||
                              e.itemTax === "0.25" ||
                              e.itemTax === "1.5" ||
                              e.itemTax === "3" ||
                              e.itemTax === "5" ||
                              e.itemTax === "6" ||
                              e.itemTax === "12" ||
                              e.itemTax === "13.8" ||
                              e.itemTax === "18" ||
                              e.itemTax === "28"
                              ? formatNumber(
                                  e.itemPrice +
                                    (e.itemPrice * Number(e.itemTax)) / 100
                                ) * 1
                              : e.itemTax === "14,12"
                              ? formatNumber(
                                  e.itemPrice +
                                    (e.itemPrice * 14) / 100 +
                                    (e.itemPrice * 12) / 100
                                ) * 1
                              : e.itemTax === "28,12"
                              ? formatNumber(
                                  e.itemPrice +
                                    (e.itemPrice * 28) / 100 +
                                    (e.itemPrice * 12) / 100
                                ) * 1
                              : e.itemTax === "28,60"
                              ? formatNumber(
                                  e.itemPrice +
                                    (e.itemPrice * 28) / 100 +
                                    (e.itemPrice * 60) / 100
                                ) * 1
                              : formatNumber(e.itemPrice)
                            : formatNumber(e.itemPrice))
                        
                        }</> : "-"}
                      </div>
 
                      <div
                        onClick={(a) => {
                          setViewData(true);
                          setSingleFetchData(e);
                          setSingleFetchDataId(e._id);
                        }}
                      > 
                        {e.itemPurchasePrice ? <>Rs. {
                          ( e.purchasePriceType === "with-tax"
                            ? e.itemTax === "0" ||
                              e.itemTax === "0.1" ||
                              e.itemTax === "0.25" ||
                              e.itemTax === "1.5" ||
                              e.itemTax === "3" ||
                              e.itemTax === "5" ||
                              e.itemTax === "6" ||
                              e.itemTax === "12" ||
                              e.itemTax === "13.8" ||
                              e.itemTax === "18" ||
                              e.itemTax === "28"
                              ? formatNumber(
                                  e.itemPurchasePrice +
                                    (e.itemPurchasePrice * Number(e.itemTax)) / 100
                                ) * 1
                              : e.itemTax === "14,12"
                              ? formatNumber(
                                  e.itemPurchasePrice +
                                    (e.itemPurchasePrice * 14) / 100 +
                                    (e.itemPurchasePrice * 12) / 100
                                ) * 1
                              : e.itemTax === "28,12"
                              ? formatNumber(
                                  e.itemPurchasePrice +
                                    (e.itemPurchasePrice * 28) / 100 +
                                    (e.itemPurchasePrice * 12) / 100
                                ) * 1
                              : e.itemTax === "28,60"
                              ? formatNumber(
                                  e.itemPurchasePrice +
                                    (e.itemPurchasePrice * 28) / 100 +
                                    (e.itemPurchasePrice * 60) / 100
                                ) * 1
                              : formatNumber(e.itemPurchasePrice)
                            : formatNumber(e.itemPurchasePrice))
                        
                        }</> : "-"}
                      </div>


                      {/* {e.itemType === "Product" ? (
                        e.enableStock ? (
                          <div
                            onClick={(a) => {
                              setUpdateStock(true);
                              setSingleFetchData(e);
                              setSingleFetchDataId(e._id);
                            }}
                            className="app-page-table-body-data-edit"
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </div>
                        ) : (
                          <div
                            onClick={(a) => {
                              setViewData(true);
                              setSingleFetchData(e);
                              setSingleFetchDataId(e._id);
                            }}
                            className="app-page-table-body-disable"
                          >
                            disabled
                          </div>
                        )
                      ) : (
                        <div
                          onClick={(a) => {
                            setViewData(true);
                            setSingleFetchData(e);
                            setSingleFetchDataId(e._id);
                          }}
                        >
                          &nbsp;
                        </div>
                      )} */}
                      {/* <div>{e.itemType === "Product"?<i className="fa-solid fa-pen-to-square"></i>:""}</div> */}

                      {/* <div className="app-page-table-body-data-edit">098709</div> */}
                    </div>
                  ))
              }
            </div>
            { fetchData && fetchData
              .filter((e) =>
                filteredData === "stockFilter"
                  ? e.itemType === "Product" && e.enableStock && e.itemStock < 1
                  : e
              )
              .filter((e) =>
                e.itemName.toLocaleLowerCase().includes(search.toLowerCase())
              ).length == 0 || fetchData && fetchData.length === 0 ? (
              <div className="app-page-table-blank">
                <span>No such data found</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppItemPage;
