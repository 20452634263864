import React from 'react'
import phonePe from "../../../../media/images/icons/phonepe.png"
import gpay from "../../../../media/images/icons/gpay.png"
import paytm from "../../../../media/images/icons/paytm.png"
import upi from "../../../../media/images/icons/upi.png"
import QRCode from "react-qr-code";

const SettingUpiComponent = ({businessData, data}) => {
  return (
    <div>

<div className="invoice-theme-1-end-col-1-bank-datails">
                
                <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                  Bank Details
                </div>

                <table className="invoice-theme-1-end-col-1-bank-datails-data">
                  <tr>
                    <td>Name:</td>
                    <td>Account Name</td>
                  </tr>
                  <tr>
                    <td>IFSC Code:</td>
                    <td>BANKCODE</td>
                  </tr>
                  <tr>
                    <td>Account No:</td>
                    <td>123456789</td>
                  </tr>
                  <tr>
                    <td>Bank:</td>
                    <td>Bank Name</td>
                  </tr>
                </table>         

       
                <div className="invoice-theme-1-end-col-1-bank-datails-upi">
                <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-1">
                <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                  Payment Qr Code
                </div>
                  <b>UPI ID:</b>
                  <div>upiId@upiId</div>
                <div className="invoice-theme-1-end-col-1-bank-icons">
                  <img src={phonePe} alt="" />
                  <img src={gpay} alt="" />
                  <img src={paytm} alt="" />
                  <img src={upi} alt="" />

                </div>
                </div>
                <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-2">
                <QRCode
        value={`https://invoician.com/`}
        size={70}
        level="M"
        // L,M,Q,H
      />
                  
                </div>
                
                </div>
                
              </div>
        
    </div>
  )
}

export default SettingUpiComponent