import React, { useContext } from "react";
import ViewInvoiceUpiComponent from "./ViewInvoiceUpiComponent";
import { AuthContext } from "../../../../context/AuthContext";

const ViewInvoiceExcelTheme = ({ data, businessData, heading, invoiceType }) => {


  
  const { link } = useContext(AuthContext);
  
  
  
  const mainColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--invoice")
    .trim();

  // Function to set RGBA color with opacity
  const rgbaColor = (opacity) => {
    const hex = mainColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  
  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  
  const taxableAmount = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (item.itemPurchasePrice * item.quantity -
        (item.itemPurchasePrice * item.discount * item.quantity) / 100)
    );
  }, 0);


  
  const totalGst = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);


  const totalCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);
  
// --------------------


const taxablePurchaseAmount = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (item.itemPurchasePrice * item.quantity -
      (item.itemPurchasePrice * item.discount * item.quantity) / 100)
  );
}, 0);

const totalPurchaseGst = data.invoiceItemsData.reduce((acc, item) => {
  return (
    acc +
    (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
      item.itemGst) /
      100) *
      item.quantity
  );
}, 0);

  const totalPurchaseCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);

  
  return (
    <div>
      <div className="">

      <div className="invoice-excel-theme-heading">
        {heading} {heading === "tax invoice"?<div>Original for recipient</div>:""}
      </div>
      
      <div className="invoice-excel-theme">
        <div className="invoice-excel-theme-main">

          <div className="invoice-excel-theme-head">
            <div className="invoice-excel-theme-head-company">
              <div className="invoice-excel-theme-head-company-name">{businessData && businessData.businessName}</div>
              {
              businessData.invoiceSettingData.invoiceAddress?
              <div className="invoice-excel-theme-head-company-Address">
                 {
                businessData.companyBillingAddress?
                <>{businessData.companyBillingAddress},</>
              :""
              }
              {
                businessData.companyCity?
                <> {businessData.companyCity},</>
              :""
              }
              {
                businessData.companyState?
                <> {businessData.companyState},</>
              :""
              }
              {
                businessData.companyPincode?
                <> {businessData.companyPincode}</>
              :""
              }
              </div>
            :""
            }



{
              businessData.invoiceSettingData.invoicePhoneNumber?
              
              <div className="invoice-excel-theme-head-company-number">
               <b>Mobile : </b> {businessData.companyPhoneNumber ? businessData.companyPhoneNumber :""}                
              </div>              
              :""}

{
  businessData.invoiceSettingData.invoiceEmail?
  businessData.companyEmail?
  <div className="invoice-excel-theme-head-company-email"><b>Email : </b>{businessData.companyEmail}</div>
  :"":""

}

{
  businessData.invoiceSettingData.invoiceGSTIN?
  businessData.companyGSTIN ?
              <div className="invoice-excel-theme-head-company-gst"><b>GSTIN : </b>{businessData.companyGSTIN}</div>
              :"":""}
            </div>
            <div className="invoice-excel-theme-head-text">INVOICE</div>
          </div>

          <div className="invoice-excel-theme-options">
            
            <div className="invoice-excel-theme-options-col-1">
              <div className="invoice-excel-theme-options-tabel">
                
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Invoice#</div>
                  <div>{data.invoiceNumber ? data.invoiceNumber : "__________"}</div>
                </div>

                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Invoice Date</div>
                  {/* <div>{data.invoiceDate ? new Date(data.invoiceDate).toDateString() : "__________"}</div> */}
                  <div>{data.invoiceDate ? (
                <>
                  {new Date(data.invoiceDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDate).getFullYear()}
                </>
              ) : (
                "__________"
              )}</div>
                </div>
{
  data.invoiceEnableDueDate?
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Due Date</div>
                  <div>
                  {new Date(data.invoiceDueDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDueDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDueDate).getFullYear()}
                  </div>
                </div>
                :""}

              </div>

            </div>

{
  data.invoiceDetailsOptions || data.invoiceVehicleNo || data.invoicePoNo ?

            <div className="invoice-excel-theme-options-col-2">
              <div className="invoice-excel-theme-options-tabel">

             
            {
              data.invoicePoNo?
              <div className="invoice-excel-theme-options-tabel-row">
                  <div>P.O. No.</div>
                  <div>{data.invoicePoNo}</div>
                </div>
                      :""
                    }
            {
              data.invoiceVehicleNo?
              <div className="invoice-excel-theme-options-tabel-row">
                  <div>Vehicle No.</div>
                  <div>{data.invoiceVehicleNo}</div>
                </div>
                      :""
                    }


{data.invoiceDetailsOptions ? (
                <>
                {
                  Object.entries(data.invoiceDetailsOptions)
                    .filter(([key, value]) => value) // Show only fields with values
                    .map(([key, value]) => (
                      <div className="invoice-excel-theme-options-tabel-row" key={key}>
                        <div>{key} </div>
                        <div>{value}</div>
                      </div>
                    ))

                  }
         </>   
          ) : (
            ""
          )}
                


              </div>

            </div>
            :""}

          </div>

          <div className="invoice-excel-theme-options">
            
            <div className="invoice-excel-theme-address">
             <div className="invoice-excel-theme-address-head" style={{ backgroundColor: rgbaColor(0.2) }}>Bill To</div>
              <div className="invoice-excel-theme-address-content">
                {
                  data.invoicePartyData && data.invoicePartyData.partyName?
                  <div className="invoice-excel-theme-address-content-name">{data.invoicePartyData.partyName}</div>
                  :""
                }

{(data.invoicePartyData &&
                data.invoicePartyData.partyBillingAddress) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingState) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingCity) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingPinCode) ? (
                <div  className="invoice-excel-theme-address-content-text">
                  {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingAddress}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingCity ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingCity}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingState}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingPinCode}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
                
                {/* <div className="invoice-excel-theme-address-content-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</div> */}

                {
                  data.invoicePartyData && data.invoicePartyData.partyNumber ?
                  <div className="invoice-excel-theme-address-content-text"><b>Number : </b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
                  :""
                }
                
                {data.invoicePartyData && data.invoicePartyData.partyGSTIN ? 
                <div className="invoice-excel-theme-address-content-text"><b>GSTIN : </b>{data.invoicePartyData && data.invoicePartyData.partyGSTIN}</div>
                :""}
              </div>
            </div>
            

            {data.invoicePartyData.partyBothAddressSame ?

            <div className="invoice-excel-theme-address">
             <div className="invoice-excel-theme-address-head" style={{ backgroundColor: rgbaColor(0.2) }}>Ship To</div>
              <div className="invoice-excel-theme-address-content">

                {
                  data.invoicePartyData && data.invoicePartyData.partyName?
                  <div className="invoice-excel-theme-address-content-name">{data.invoicePartyData.partyName}</div>
                  :""
                }

                {(data.invoicePartyData &&
                  data.invoicePartyData.partyBillingAddress) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState) ||
                (data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode) ? (
                  <div>
                    {data.invoicePartyData &&
                      data.invoicePartyData.partyBillingAddress}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingState ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingState}
                      </>
                    ) : (
                      ""
                    )}
                    {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingPinCode ? (
                      <>
                        ,{" "}
                        {data.invoicePartyData &&
                          data.invoicePartyData.partyBillingPinCode}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                
{
  data.invoicePartyData && data.invoicePartyData.partyNumber?
  <div className="invoice-excel-theme-address-content-text"><b>Mobile : </b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
:""
}
                
              </div>
            </div>

            :

            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingAddress) ||
            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingCity) ||
            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingState) ||
            (data.invoicePartyData &&
              data.invoicePartyData.partyShippingPinCode) ?


              <div className="invoice-excel-theme-address">
              <div className="invoice-excel-theme-address-head" style={{ backgroundColor: rgbaColor(0.2) }}>Ship To</div>
               <div className="invoice-excel-theme-address-content">
 
               

               <div>
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyShippingAddress}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingCity ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingCity}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingState ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingState}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingPinCode ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingPinCode}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
               
               
               
                 
 {
   data.invoicePartyData && data.invoicePartyData.partyNumber?
   <div className="invoice-excel-theme-address-content-text"><b>Mobile : </b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
 :""
 }
                 
               </div>
             </div>

            

            :""}

            
          </div>


          <div className="invoice-excel-theme-table">

            <table >
                <tr >
                  <td>#</td>
                  <td>Items</td>
                  {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
                    <td>HSN/SAC</td>
                    :""
                  }
                  <td>Qty</td>
                  <td>Rate</td>
                  {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
                    <td>Disc</td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <td>Tax</td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                    <td>CESS</td>
                    :""
                  }
                  <td>Amount</td>
                </tr>


                {data.invoiceItemsData.map((e, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>
                {e.itemName}
                  <span>
                      {
                        businessData.invoiceSettingData.invoiceItemDescription?
                        e.itemDescription?<div>{e.itemDescription}</div>:""
                        :""
                      }
                  </span>
                </td>

                {
                  data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                  0 ?
                  <td>{e.itemHsnCode ? e.itemHsnCode : "-"}</td>
                  :""
                }
                <td>{formatNumber(Number(e.quantity))*1}</td>
                <td> {
                        invoiceType === "Purchase"?
                      formatNumber(Number(e.itemPurchasePrice))*1
                      :
                      formatNumber(Number(e.itemPrice))*1
                      }
                </td>
                {
                  data.invoiceItemsData.filter((e) => e.discount).length >
                  0 ?
                  <td>{e.discount ? formatNumber(e.discount)*1 : 0}%</td>
                  :""
                }

                {
                  data.invoiceItemsData.filter((e) => e.itemGst).length >
                  0 ? 
                  <td>{e.itemGst ? e.itemGst : 0}%</td>
                  :""
                }
                {
                  data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                  <td>{e.cess ? e.cess : 0}%</td>
                  :''
                }
                <td>
                {
                        invoiceType === "Purchase"?
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPurchasePrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      :
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                </td>
              </tr>
                ))}


                <tr >
                  <td></td>
                <td>Sub Total</td>
                  {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
                    <td></td>
                    :""
                  }
                  <td></td>
                  <td></td>
                  {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
                    <td></td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <td></td>
                    :""
                  }
                  {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                    <td></td>
                    :""
                  }
                  {/* <td className="invoice-excel-theme-table-left-border">₹ {formatNumber(totalGst) * 1}</td>
                  <td className="invoice-excel-theme-table-left-border">₹ {formatNumber(totalCess) * 1}</td>
                  <td className="invoice-excel-theme-table-left-border">₹ {formatNumber(data.invoiceSubTotalAmount) * 1}</td> */}
                  <td>₹ {formatNumber(data.invoiceSubTotalAmount) * 1}</td>
                </tr>
                
                
              
            </table>
            
          </div>


          <div className="invoice-excel-theme-end">

          <div className="invoice-excel-theme-end-col-1">
            
            {
              data.invoiceNotes ?
              <>
          <div className="invoice-excel-theme-end-heading">Notes</div>
          <div className="invoice-excel-theme-end-text"><pre>{data.invoiceNotes}</pre></div>
              </>
              :""
            }

{
  businessData.invoiceSettingData.invoiceTermsConditions ? 
  <>
          <div className="invoice-excel-theme-end-heading">Terms and Conditions</div>
          <div className="invoice-excel-theme-end-text"><pre>{businessData.businessTermsConditions}</pre></div>
  </>
  :""
}
          
            <ViewInvoiceUpiComponent businessData={businessData} data={data}/>
           

          </div>

          <div className="invoice-excel-theme-end-col-2">

          <div className="invoice-excel-theme-end-col-2-amount"  style={{ backgroundColor: rgbaColor(0.2) }}>
            
            <div className="invoice-excel-theme-end-col-row">
              <div>Taxable Value</div>
              <div>
              ₹ {
                    invoiceType === "Purchase"?
                  formatNumber(taxablePurchaseAmount) * 1
                  :
                  formatNumber(taxableAmount) * 1
                  }
              </div>
            </div>
{
  data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
  <>
            <div className="invoice-excel-theme-end-col-row">
              <div>SGST</div>
              <div>
              ₹ {
                      invoiceType === "Purchase"?
                      formatNumber(totalPurchaseGst) / 2
                      :
                      formatNumber(totalGst) / 2
                    }
              </div>
            </div>

            <div className="invoice-excel-theme-end-col-row">
              <div>CGST</div>
              <div>
              ₹ {
                      invoiceType === "Purchase"?
                      formatNumber(totalPurchaseGst) / 2
                      :
                      formatNumber(totalGst) / 2
                    }
              </div>
            </div>
  </>
  :""
}
{
  data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
  <div className="invoice-excel-theme-end-col-row">
              <div>CESS</div>
              <div>₹ {
                      invoiceType === "Purchase"?
                    formatNumber(totalPurchaseCess) / 1
                    :
                    formatNumber(totalCess) / 1
                    }</div>
            </div>
            :""
            }
{
  data.invoiceExtraDiscount ? 
  <div className="invoice-excel-theme-end-col-row">
              <div>Discount</div>
              <div>₹ {formatNumber(data.invoiceExtraDiscount) * 1}</div>
            </div>
            :""
            }

            <div className="invoice-excel-theme-end-col-row-total">
              <div>Total Amount</div>
              <div>₹ {(data.invoiceTotalAmount.toFixed(2) * 1).toLocaleString()}</div>
            </div>

{
  data.receivedAmount ?
  <>
  <div className="invoice-excel-theme-end-col-row">
              <div>Paid Amount</div>
              <div>₹ {data.receivedAmount}</div>
            </div>

            <div className="invoice-excel-theme-end-col-row">
              <div>Balance</div>
              <div>₹{" "}
                      {Math.round(
                        data.invoiceTotalAmount.toFixed(2) - data.receivedAmount
                      )}
              </div>
            </div>
  </>
  :""          }

          </div>


      
          {
  businessData.companySignatureType === "Box"?

          <div className="invoice-excel-theme-signature">

          <div className="invoice-excel-theme-signature-empty"></div>
          <div className="invoice-excel-theme-signature-text">
            <span>Authorized Signatory For</span>
            <div>{businessData.businessName}</div>
          </div>

          </div>

:
businessData.companySignatureType === "Image"?


businessData.companySignature ?

<div className="invoice-excel-theme-signature">

{/* <div className="invoice-excel-theme-signature-empty"></div> */}
<img src={`${link}/${businessData.companySignature }`} alt="comapny signature"/>
<div className="invoice-excel-theme-signature-text">
  <span>Authorized Signatory For</span>
  <div>{businessData.businessName}</div>
</div>

</div>
:""
          :""}



                      




          </div>
          </div>








        </div>
      </div>
      </div>
    </div>
  );
};

export default ViewInvoiceExcelTheme;
