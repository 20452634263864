import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // links ============================================================================================

    // const link = "http://localhost:5500"; 
  const link = "https://api.invoician.com";

  // setState =======================================================================================================

  const [token, setToken] = useState(localStorage.getItem("invoician-token"));
  const [storeUserId, setStoreUserId] = useState(
    localStorage.getItem("invoician-user-id")
  );
  const [storeBusinessId, setStoreBusinessId] = useState(
    localStorage.getItem("business-id")
  );

  // save to storage ============================================================================================

  useEffect(() => {
    if (token) {
      localStorage.setItem("invoician-token", token);
    } else {
      localStorage.removeItem("invoician-token");
    }
  }, [token]);

  useEffect(() => {
    if (storeUserId) {
      localStorage.setItem("invoician-user-id", storeUserId);
    } else {
      localStorage.removeItem("invoician-user-id");
    }
  }, [storeUserId]);

  useEffect(() => {
    if (storeBusinessId) {
      localStorage.setItem("business-id", storeBusinessId);
    } else {
      localStorage.removeItem("business-id");
    }
  }, [storeBusinessId]);

  // get from storage ============================================================================================

  // const userId = localStorage.getItem("invoician-user-id");
  // const businessId = localStorage.getItem("business-id");

  // login user ============================================================================================

  const loginOtp = async (
    user,
    setUserCorrect,
    setError,
    setTimerPopupAlert,
    setTimerAlertMessage
  ) => {
    try {
      await axios.post(`${link}/login-otp`, user, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      setUserCorrect(true);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Otp successfully sent on your email");
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data);
    }
  };

  const verifyLoginOtp = async (user, setUserCorrect, setError, navigate) => {
    try {
      const response = await axios.post(`${link}/login-user`, user, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      setUserCorrect(true);
      setToken(response.data.data.userToken);
      setStoreUserId(response.data.data._id);
      setStoreBusinessId(response.data.data.selectedBusiness);
      navigate("/app");
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  // register user ============================================================================================

  const registerOtp = async (
    user,
    setOtpTransfer,
    setError,
    setTimerPopupAlert,
    setTimerAlertMessage
  ) => {
    try {
      await axios.post(`${link}/register-otp`, user, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      setOtpTransfer(true);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Otp successfully sent on your email");
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const verifyRegisterOtp = async (user, setError, navigate) => {
    try {
      const response = await axios.post(`${link}/register-user`, user, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      setToken(response.data.data.userToken);
      setStoreUserId(response.data.data._id);
      navigate("/app");
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  // logout ============================================================================================

  const logout = (navigate) => {
    try {
      setToken(null);
      setStoreUserId(null);
      setStoreBusinessId(null);
      navigate && navigate("/login");

      // alert("logout successfull")
    } catch (error) {
      alert("failed to logout");
    }
  };

  // forgot password otp ============================================================================================

  const forgotPasswordOtp = async (
    user,
    setSentOtp,
    setError,
    setTimerPopupAlert,
    setTimerAlertMessage
  ) => {
    try {
      await axios.post(`${link}/forgot-password-otp`, user, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      setSentOtp(true);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Otp successfully sent on your email");
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const updateUserPassword = async (user, setSentOtp, setError, navigate) => {
    try {
      const response = await axios.post(`${link}/update-user-password`, user, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });

      setSentOtp(true);

      setToken(response.data.data.userToken);
      setStoreUserId(response.data.data._id);
      setStoreBusinessId(response.data.data.selectedBusiness);

      navigate("/app");
    } catch (error) {
      setError(error.response.data.message);
      // console.log(error.response.data)
    }
  };

  // fetch user  ============================================================================================

  const fetchUser = async (id, setAuthUserData) => {
    try {
      const response = await axios.get(`${link}/user/${id}`, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      setAuthUserData && setAuthUserData(response.data.data);
    } catch (error) {
      // console.log("fet error is : ",error.response.data.message);
      // setAuthUserData && setAuthUserData(error.response);
      setAuthUserData && setAuthUserData(error.response.data.message);
    }
  };

  // update user  ============================================================================================

  const updateUser = async (
    userId,
    data,
    setError,
    fetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setSubmitButton
  ) => {
    try {
      await axios.put(`${link}/update-user/${userId}`, data, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      fetchData && fetchData();
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Account data updated successfully");
      setSubmitButton && setSubmitButton("done");
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response);
    }
  };

  // create business ============================================================================================

  const createBusiness = async (
    userId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    fetchUserData
  ) => {
    try {
      const response = await axios.post(
        `${link}/user/${userId}/business`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setStoreBusinessId(response.data.businessId);
      fetchUserData && fetchUserData();
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch business ============================================================================================

  const fetchBusiness = async (userId, businessId, setCompanyData) => {
    try {
      // const response = await axios.get(`${link}/company/${id}`, {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}`,
        {
          // const response = await axios.get(`http://localhost:5500/user/66dac2a2e8256b1cccc22639/business/66dad5e2013de801c66d8c0a`, {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setCompanyData && setCompanyData(response.data.data);
    } catch (error) {
      // console.log("business fetch error " , error.response.data.message);
    }
  };

  // update business ============================================================================================

  const updateBusiness = async (
    userId,
    businessId,
    data,
    fetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    setSubmitButton,
    setRemoveLogo,
    setRemoveSignature
  ) => {
    try {
      await axios.patch(`${link}/user/${userId}/business/${businessId}`, data, {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
        },
      });
      fetchData && fetchData();

      // timer popup------------------------------------
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Business data updated successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      // -----------------------------------------------
      setSubmitButton && setSubmitButton(null);
      setRemoveLogo && setRemoveLogo(null);
      setRemoveSignature && setRemoveSignature(null);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch parties ============================================================================================

  const fetchParties = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/party`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single parties ============================================================================================

  const fetchSingleParty = async (userId, businessId, partyId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/party/${partyId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleParty = async (
    userId,
    businessId,
    partyId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/party/${partyId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );

      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Party deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // create party ============================================================================================

  const createParty = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    emptyData,
    navigate,
    setData,
    open,
    setTimerAlertStatus,
    setDemoSatus
  ) => {
    try {
      const response = await axios.post(
        `${link}/user/${userId}/business/${businessId}/party`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && 
        setTimerAlertMessage("Party Created Successfully");
      emptyData && emptyData();
      navigate && navigate("/app/parties");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      console.log("res.data.pId is :", response.data.partyId)
      setData && setData({ ...data, _id : response.data.partyId});
      setDemoSatus && setDemoSatus('item')
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update party ============================================================================================

  const updateParty = async (
    userId,
    businessId,
    partyId,
    data,
    setSingleData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    open,
    setTimerAlertStatus
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/party/${partyId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleData && setSingleData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Party Updated Successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");

      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // create item ============================================================================================

  const createItem = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    emptyData,
    navigate,
    open,
    setTimerAlertStatus
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/item`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`${data.itemType} Created Successfully`);
      emptyData && emptyData();
      navigate && navigate("/app/items");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch parties ============================================================================================

  const fetchItems = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/item`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single parties ============================================================================================

  const fetchSingleItem = async (userId, businessId, itemId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/item/${itemId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleItem = async (
    userId,
    businessId,
    itemId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/item/${itemId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("Item deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update item ============================================================================================

  const updateItem = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    open,
    setTimerAlertStatus
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/item/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      // setTimerAlertMessage && setTimerAlertMessage(`${data.itemType} Updates Successfully`);
      setTimerAlertMessage && setTimerAlertMessage(`Item Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  // create invoice ============================================================================================

  const createInvoice = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/invoice`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`invoice Created Successfully`);
      navigate && navigate("/app/sale-invoice");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch invoice ============================================================================================

  const fetchInvoice = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/invoice`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleInvoice = async (
    userId,
    businessId,
    invoiceId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/invoice/${invoiceId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Invoice deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update inoice ============================================================================================

  const updateInvoice = async (
    userId,
    businessId,
    id,
    data,
    fetchBusinessData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/invoice/${id}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      fetchBusinessData && fetchBusinessData();
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Invoice Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  // create invoice ============================================================================================

  const upsertInvoiceSetting = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setSubmitButton,
    open,
    setTimerAlertStatus
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/invoice-setting`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`invoice successfully setup`);
      setSubmitButton && setSubmitButton(false);
      open && open(false);

      setTimerAlertStatus && setTimerAlertStatus(`True`);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  // fetch invoice ============================================================================================

  const fetchInvoiceSetting = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/invoice-setting`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data);
      console.log("data is ", response.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  //  ========================================================================================================================================================================================

  // create Quotation ============================================================================================

  const createQuotation = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/quotation`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Quotation Created Successfully`);
      // emptyData&&emptyData()
      navigate && navigate("/app/quotation-estimate");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch Quotation ============================================================================================

  const fetchQuotation = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/quotation`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleQuotation = async (
    userId,
    businessId,
    quotationId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/quotation/${quotationId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Quotation deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  // update inoice ============================================================================================

  const updateQuotation = async (
    userId,
    businessId,
    id,
    data,
    fetchBusinessData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/quotation/${id}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      fetchBusinessData && fetchBusinessData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Quotation Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus("True");

      open(false);
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ============================================================================================

  // create Quotation ============================================================================================

  const upsertQuotationSetting = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setSubmitButton,
    open
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/quotation-setting`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Quotation successfully setup`);
      setSubmitButton && setSubmitButton(false);

      // alert("true")

      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      // alert("false")
    }
  };

  // fetch Quotation ============================================================================================

  const fetchQuotationSetting = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/quotation-setting`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data);
      console.log("data is ", response.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // create proforma ============================================================================================

  const createProforma = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/proforma`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
      setTimerAlertMessage(`proforma Created Successfully`);
      // emptyData&&emptyData()
      navigate && navigate("/app/proforma-invoice");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch proforma ============================================================================================

  const fetchProforma = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/proforma`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleProforma = async (
    userId,
    businessId,
    proformaId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/proforma/${proformaId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("proforma deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");

      open && open(false);
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  // update inoice ============================================================================================

  const updateProforma = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/proforma/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`proforma Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus("True");
      open(false);
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };
  
  //  ============================================================================================
  
  // create DeliveryChallan ============================================================================================
  
  const createDeliveryChallan = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/deliveryChallan`,
        data,
        {
          headers: {
            Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
      setTimerAlertMessage(`DeliveryChallan Created Successfully`);
      // emptyData&&emptyData()
      setTimerAlertStatus && setTimerAlertStatus("True");
      navigate && navigate("/app/delivery-challan");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertStatus && setTimerAlertStatus("False");
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
    }
  };

  // fetch deliveryChallan ============================================================================================

  const fetchDeliveryChallan = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/deliveryChallan`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleDeliveryChallan = async (
    userId,
    businessId,
    deliveryChallanId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/deliveryChallan/${deliveryChallanId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("deliveryChallan deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");

      open && open(false);
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  // update inoice ============================================================================================

  const updateDeliveryChallan = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/deliveryChallan/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`deliveryChallan Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus("True");
      open(false);
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ============================================================================================
  //  ============================================================================================

  // create SalesReturn ============================================================================================

  const createSalesReturn = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/salesReturn`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`SalesReturn Created Successfully`);
      navigate && navigate("/app/sales-return");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch SalesReturn ============================================================================================

  const fetchSalesReturn = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/salesReturn`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleSalesReturn = async (
    userId,
    businessId,
    salesReturnId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/salesReturn/${salesReturnId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("SalesReturn deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update inoice ============================================================================================

  const updateSalesReturn = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/salesReturn/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`SalesReturn Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  // create CreditNote ============================================================================================

  const createCreditNote = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/creditNote`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`CreditNote Created Successfully`);
      navigate && navigate("/app/credit-note");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch CreditNote ============================================================================================

  const fetchCreditNote = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/creditNote`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleCreditNote = async (
    userId,
    businessId,
    creditNoteId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/creditNote/${creditNoteId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("CreditNote deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update inoice ============================================================================================

  const updateCreditNote = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/CreditNote/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`CreditNote Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  // create PurchaseInvoice ============================================================================================

  const createPurchaseInvoice = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/purchaseInvoice`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`PurchaseInvoice Created Successfully`);
      navigate && navigate("/app/purchase-invoice");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch PurchaseInvoice ============================================================================================

  const fetchPurchaseInvoice = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/purchaseInvoice`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSinglePurchaseInvoice = async (
    userId,
    businessId,
    purchaseInvoiceId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/purchaseInvoice/${purchaseInvoiceId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("PurchaseInvoice deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update PurchaseInvoice ============================================================================================

  const updatePurchaseInvoice = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/purchaseInvoice/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`PurchaseInvoice Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================


  // create PurchaseReturn ============================================================================================

  const createPurchaseReturn = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/purchaseReturn`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`PurchaseReturn Created Successfully`);
      navigate && navigate("/app/purchase-return");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch PurchaseReturn ============================================================================================

  const fetchPurchaseReturn = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/purchaseReturn`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single PurchaseReturn ============================================================================================

  const deleteSinglePurchaseReturn = async (
    userId,
    businessId,
    purchaseReturnId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/purchaseReturn/${purchaseReturnId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("PurchaseReturn deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update PurchaseReturn ============================================================================================

  const updatePurchaseReturn = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/purchaseReturn/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`PurchaseReturn Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  // create DebitNote ============================================================================================

  const createDebitNote = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/debitNote`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`DebitNote Created Successfully`);
      navigate && navigate("/app/debit-note");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch DebitNote ============================================================================================

  const fetchDebitNote = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/debitNote`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single DebitNote ============================================================================================

  const deleteSingleDebitNote = async (
    userId,
    businessId,
    debitNoteId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/debitNote/${debitNoteId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("DebitNote deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update DebitNote ============================================================================================

  const updateDebitNote = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/debitNote/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`DebitNote Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  // create PurchaseOrder ============================================================================================

  const createPurchaseOrder = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/purchaseOrder`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`PurchaseOrder Created Successfully`);
      navigate && navigate("/app/purchase-order");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch PurchaseOrder ============================================================================================

  const fetchPurchaseOrder = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/purchaseOrder`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single PurchaseOrder ============================================================================================

  const deleteSinglePurchaseOrder = async (
    userId,
    businessId,
    purchaseOrderId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/purchaseOrder/${purchaseOrderId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("PurchaseOrder deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update PurchaseOrder ============================================================================================

  const updatePurchaseOrder = async (
    userId,
    businessId,
    itemId,
    data,
    setSingleFetchData,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/purchaseOrder/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`purchaseOrder Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  
  // create Bank ============================================================================================

  const createBank = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/bank`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Bank Created Successfully`);
        setTimerAlertStatus && setTimerAlertStatus("True");
        open && open(false);

      navigate && navigate("/app/cash-bank");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch parties ============================================================================================

  const fetchBank = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/bank`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single Bank ============================================================================================

  const fetchSingleBank = async (userId, businessId, bankId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/bank/${bankId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single Bank ============================================================================================

  const deleteSingleBank = async (
    userId,
    businessId,
    bankId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/bank/${bankId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("Bank deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update Bank ============================================================================================

  const updateBank = async (
    userId,
    businessId,
    bankId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    setSingleFetchData,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/bank/${bankId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(`Bank Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  
  
  
  //  ============================================================================================
  //  ============================================================================================

  
  // create AdjustBalance ============================================================================================

  const createAdjustBalance = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/adjustBalance`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`AdjustBalance Created Successfully`);
        setTimerAlertStatus && setTimerAlertStatus("True");
        open && open(false);

      navigate && navigate("/app/cash-bank");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch AdjustBalance ============================================================================================

  const fetchAdjustBalance = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/adjustBalance`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single AdjustBalance ============================================================================================

  const fetchSingleAdjustBalance = async (userId, businessId, AdjustBalanceId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/adjustBalance/${AdjustBalanceId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single AdjustBalance ============================================================================================

  const deleteSingleAdjustBalance = async (
    userId,
    businessId,
    AdjustBalanceId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/adjustBalance/${AdjustBalanceId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("AdjustBalance deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update AdjustBalance ============================================================================================

  const updateAdjustBalance = async (
    userId,
    businessId,
    AdjustBalanceId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    setSingleFetchData,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/adjustBalance/${AdjustBalanceId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(`AdjustBalance Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  
  
  
  // create TransferBalance ============================================================================================

  const createTransferBalance = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/TransferBalance`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`TransferBalance Created Successfully`);
        setTimerAlertStatus && setTimerAlertStatus("True");
        open && open(false);

      navigate && navigate("/app/cash-bank");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch TransferBalance ============================================================================================

  const fetchTransferBalance = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/TransferBalance`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single TransferBalance ============================================================================================

  const fetchSingleTransferBalance = async (userId, businessId, TransferBalanceId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/TransferBalance/${TransferBalanceId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single TransferBalance ============================================================================================

  const deleteSingleTransferBalance = async (
    userId,
    businessId,
    TransferBalanceId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/TransferBalance/${TransferBalanceId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("TransferBalance deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };



  
  
  // create Staff ============================================================================================

  const createStaff = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/Staff`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Staff Created Successfully`);
        setTimerAlertStatus && setTimerAlertStatus("True");
        open && open(false);

      navigate && navigate("/app/Staff");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch staff ============================================================================================

  const fetchStaff = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/Staff`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single Staff ============================================================================================

  const fetchSingleStaff = async (userId, businessId, StaffId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/Staff/${StaffId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single Staff ============================================================================================

  const deleteSingleStaff = async (
    userId,
    businessId,
    StaffId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/Staff/${StaffId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("Staff deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update Staff ============================================================================================

  const updateStaff = async (
    userId,
    businessId,
    StaffId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    setSingleFetchData,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/Staff/${StaffId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(`Staff Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  
  
  // create Attendance ============================================================================================

  const createAttendance = async (
    userId,
    businessId,
    staffId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    fetchData,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/Staff/${staffId}/attendance`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      // setTimerPopupAlert && setTimerPopupAlert(true);
      // setTimerAlertMessage &&
      //   setTimerAlertMessage(`Attendance Marked Successfully`);
      //   setTimerAlertStatus && setTimerAlertStatus("True");
        fetchData&&fetchData()

    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  
  
  
  
  // update Attendance ============================================================================================

  const updateAttendance = async (
    userId,
    businessId,
    staffId,
    attendanceId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    fetchData,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/Staff/${staffId}/attendance/${attendanceId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      // setTimerPopupAlert && setTimerPopupAlert(true);
      // setTimerAlertMessage &&
      //   setTimerAlertMessage(`Attendance Marked Successfully`);
      //   setTimerAlertStatus && setTimerAlertStatus("True");
        fetchData&&fetchData()

    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };


  // ======================================================================================================================


  
  // create Expense ============================================================================================

  const createExpense = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    navigate
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/expense`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Expense Created Successfully`);
      navigate && navigate("/app/expenses");
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch Expense ============================================================================================

  const fetchExpense = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/expense`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
    } catch (error) {
      console.log("error is ->",error.response);
    }
  };

  // delete single Expense ============================================================================================

  const deleteSingleExpense = async (
    userId,
    businessId,
    expenseId,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/expense/${expenseId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage("Expense deleted successfully");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      console.log(error.response.data.message);
      // alert("false")
    }
  };

  // update PurchaseOrder ============================================================================================

  const updateExpense = async (
    userId,
    businessId,
    expenseId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    setSingleFetchData,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/expense/${expenseId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`Expense Updates Successfully`);
      open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
      // alert("true")
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
      // alert("false")
    }
  };

  //  ========================================================================================================================================================================================

  
  

  
  
  

  
  // create Expense item ============================================================================================

  const createExpenseItem = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/expenseItem`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`expenseItem Created Successfully`);
      
      // navigate && navigate("/app/items");
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log("first :", error)
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch parties ============================================================================================

  const fetchExpenseItems = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/expenseItem`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // fetch single parties ============================================================================================

  const fetchSingleExpenseItem = async (userId, businessId, itemId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/expenseItem/${itemId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.data);
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleExpenseItem = async (
    userId,
    businessId,
    itemId,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/expenseItem/${itemId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("Item deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update item ============================================================================================

  const updateExpenseItem = async (
    userId,
    businessId,
    itemId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    // setSingleFetchData,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/expenseItem/${itemId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      // setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(`Item Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  
  
  
  
  

  // update TransferBalance ============================================================================================

  const updateTransferBalance = async (
    userId,
    businessId,
    TransferBalanceId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    setSingleFetchData,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/TransferBalance/${TransferBalanceId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setSingleFetchData && setSingleFetchData(data);
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(`TransferBalance Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };

  
  

  
  
  // create StaffTransaction  ============================================================================================

  const createStaffTransaction = async (
    userId,
    businessId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    open,
    navigate,
  ) => {
    try {
      await axios.post(
        `${link}/user/${userId}/business/${businessId}/StaffTransaction`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage &&
        setTimerAlertMessage(`StaffTransaction Created Successfully`);
      
      open && open(false);
      setTimerAlertStatus && setTimerAlertStatus("True");
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log("first :", error)
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // fetch parties ============================================================================================

  const fetchStaffTransaction = async (userId, businessId, data) => {
    try {
      const response = await axios.get(
        `${link}/user/${userId}/business/${businessId}/StaffTransaction`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      data && data(response.data.reverse());
      // alert("true")
    } catch (error) {
      // alert("false")
      console.log(error.response);
    }
  };

  // delete single parties ============================================================================================

  const deleteSingleStaffTransaction = async (
    userId,
    businessId,
    staffTransactionId,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    fetchData,
    open,
  ) => {
    try {
      await axios.delete(
        `${link}/user/${userId}/business/${businessId}/StaffTransaction/${staffTransactionId}`,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage("StaffTransaction deleted successfully");
      setTimerAlertStatus && setTimerAlertStatus("True");
      fetchData && fetchData();
      open && open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      console.log(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus("False");
    }
  };

  // update StaffTransaction ============================================================================================

  const updateStaffTransaction = async (
    userId,
    businessId,
    StaffTransactionId,
    data,
    setTimerPopupAlert,
    setTimerAlertMessage,
    setTimerAlertStatus,
    fetchData,
    open,
  ) => {
    try {
      await axios.patch(
        `${link}/user/${userId}/business/${businessId}/StaffTransaction/${StaffTransactionId}`,
        data,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      fetchData && fetchData();
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(`StaffTransaction Updates Successfully`);
      setTimerAlertStatus && setTimerAlertStatus(`True`);
      open(false);
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
      setTimerAlertStatus && setTimerAlertStatus(`False`);
    }
  };
  
  
  
  
  

  
  
  
  
  //  ============================================================================================
  //  ============================================================================================
  //  ============================================================================================

  // convert quotation to invoice ============================================================================================

  const quotationToInvoice = async (
    userId,
    businessId,
    invoiceData,
    dataId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage
  ) => {
    try {
      const convertResponse = await axios.post(
        `${link}/user/${userId}/business/${businessId}/invoice`,
        invoiceData,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      if (convertResponse.status === 200) {
        const deleteResponse = await axios.delete(
          `${link}/user/${userId}/business/${businessId}/quotation/${dataId}`,
          {
            headers: {
              Authorization:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
            },
          }
        );

        if (deleteResponse.status === 200) {
          setTimerPopupAlert && setTimerPopupAlert(true);
          setTimerAlertMessage &&
            setTimerAlertMessage(`invoice created Successfully `);
          open && open(false);
        } else {
          setTimerPopupAlert && setTimerPopupAlert(true);
          setTimerAlertMessage &&
            setTimerAlertMessage(
              `invoice created Successfully, failed to delete quotation `
            );
          open && open(false);
        }
      } else {
        setTimerPopupAlert && setTimerPopupAlert(true);
        setTimerAlertMessage &&
          setTimerAlertMessage(`Failed to convert invoice`);
      }
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
    }
  };

  // convert proforma to invoice ============================================================================================

  const proformaToInvoice = async (
    userId,
    businessId,
    invoiceData,
    dataId,
    open,
    setTimerPopupAlert,
    setTimerAlertMessage
  ) => {
    try {
      const convertResponse = await axios.post(
        `${link}/user/${userId}/business/${businessId}/invoice`,
        invoiceData,
        {
          headers: {
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
          },
        }
      );
      if (convertResponse.status === 200) {
        const deleteResponse = await axios.delete(
          `${link}/user/${userId}/business/${businessId}/proforma/${dataId}`,
          {
            headers: {
              Authorization:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQ0OTg0MjN9.COsA8VmGwo5ENy8rqbWcaIesQ0rudnbrGpuOrSb6KdU",
            },
          }
        );

        if (deleteResponse.status === 200) {
          setTimerPopupAlert && setTimerPopupAlert(true);
          setTimerAlertMessage &&
            setTimerAlertMessage(`invoice created Successfully `);
          open && open(false);
        } else {
          setTimerPopupAlert && setTimerPopupAlert(true);
          setTimerAlertMessage &&
            setTimerAlertMessage(
              `invoice created Successfully, failed to delete quotation `
            );
          open && open(false);
        }
      } else {
        setTimerPopupAlert && setTimerPopupAlert(true);
        setTimerAlertMessage &&
          setTimerAlertMessage(`Failed to convert invoice`);
      }
    } catch (error) {
      setTimerPopupAlert && setTimerPopupAlert(true);
      setTimerAlertMessage && setTimerAlertMessage(error.response.data.message);
    }
  };


  //  ============================================================================================

  return (
    <AuthContext.Provider
      value={{
        link,
        // authentication ---------------------------
        loginOtp,
        verifyLoginOtp,
        registerOtp,
        verifyRegisterOtp,
        // user ---------------------------
        logout,
        fetchUser,
        updateUser,
        forgotPasswordOtp,
        updateUserPassword,
        // Business ---------------------------
        fetchBusiness,
        createBusiness,
        updateBusiness,
        // party ---------------------------
        fetchParties,
        fetchSingleParty,
        deleteSingleParty,
        createParty,
        updateParty,
        // item ---------------------------
        createItem,
        fetchItems,
        fetchSingleItem,
        deleteSingleItem,
        updateItem,
        
        // invoice setting ---------------------------
        upsertInvoiceSetting,
        fetchInvoiceSetting,
        // invoice ---------------------------
        createInvoice,
        fetchInvoice,
        deleteSingleInvoice,
        updateInvoice,
        
        // Quotation ---------------------------
        createQuotation,
        fetchQuotation,
        deleteSingleQuotation,
        updateQuotation,
        // Quotation setting ---------------------------
        upsertQuotationSetting,
        fetchQuotationSetting,
        // Proforma ---------------------------
        createProforma,
        fetchProforma,
        deleteSingleProforma,
        updateProforma,
        // deliveryChallan ---------------------------
        createDeliveryChallan,
        fetchDeliveryChallan,
        deleteSingleDeliveryChallan,
        updateDeliveryChallan,
        
        // SalesReturn ---------------------------
        createSalesReturn,
        fetchSalesReturn,
        deleteSingleSalesReturn,
        updateSalesReturn,
        
        // CreditNote ---------------------------
        createCreditNote,
        fetchCreditNote,
        deleteSingleCreditNote,
        updateCreditNote,
        
        // PurchaseInvoice ---------------------------
        createPurchaseInvoice,
        fetchPurchaseInvoice,
        deleteSinglePurchaseInvoice,
        updatePurchaseInvoice,
        
        // PurchaseReturn ---------------------------
        createPurchaseReturn,
        fetchPurchaseReturn,
        deleteSinglePurchaseReturn,
        updatePurchaseReturn,
        
        // DebitNote ---------------------------
        createDebitNote,
        fetchDebitNote,
        deleteSingleDebitNote,
        updateDebitNote,
        
        // PurchaseOrder ---------------------------
        createPurchaseOrder,
        fetchPurchaseOrder,
        deleteSinglePurchaseOrder,
        updatePurchaseOrder,
        
        // Bank ---------------------------------- 
        createBank,
        fetchBank,
        fetchSingleBank,
        deleteSingleBank,
        updateBank,
        
        // AdjustBalance ---------------------------------- 
        createAdjustBalance,
        fetchAdjustBalance,
        fetchSingleAdjustBalance,
        deleteSingleAdjustBalance,
        updateAdjustBalance,
        
        // TransferBalance ---------------------------------- 
        createTransferBalance,
        fetchTransferBalance,
        fetchSingleTransferBalance,
        deleteSingleTransferBalance,
        updateTransferBalance,
        
        // Staff ---------------------------------- 
        createStaff,
        fetchStaff,
        fetchSingleStaff,
        deleteSingleStaff,
        updateStaff,
        
        // attendance --------
        createAttendance,
        updateAttendance,
        
        // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
        
        // PurchaseOrder ---------------------------
        createExpense,
        fetchExpense,
        deleteSingleExpense,
        updateExpense,

        // Expense item ---------------------------
        createExpenseItem,
        fetchExpenseItems,
        fetchSingleExpenseItem,
        deleteSingleExpenseItem,
        updateExpenseItem,
        

        // StaffTransaction ---------------------------
        createStaffTransaction,
        fetchStaffTransaction,
        deleteSingleStaffTransaction,
        updateStaffTransaction,
        
        
        // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
        // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
        
        
        // convert quotation to invoice ---------------------------
        quotationToInvoice,
        // convert proforma to invoice ---------------------------
        proformaToInvoice,

        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
