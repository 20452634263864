import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext';
import LoadingComponent from '../../../../components/extra/LoadingComponent';
import ViewQuotationPageComponent from '../../../components/invoice/view/ViewQuotationPageComponent';

const AppQuotationPage = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useState ============================================================================================
  const [businessData, setBusinessData] = useState(null);


   //  ============================================================================================
   // fetch company  ================================================================================================================
   //  ============================================================================================

  const { fetchBusiness } = useContext(AuthContext);

  const fetchBusinessData = () => fetchBusiness(userId, businessId, setBusinessData);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  
  if (!businessData)

    return (
      <div>
        <LoadingComponent />
      </div>
    );
  
  return (
    <div>

      <ViewQuotationPageComponent businessData={businessData} fetchBusinessData={fetchBusinessData}/>
      
    </div>
  )
}

export default AppQuotationPage