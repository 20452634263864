import React from "react";
import AppNavbar from "./components/pageComponents/AppNavbar";
import AppConfiguration from "./components/main/AppConfiguration";
import { Route, Routes } from "react-router-dom";
import AppPackageDurationComponent from "./components/main/AppPackageDurationComponent";
import AppPartiesPage from "./pages/main/AppPartiesPage";
import CreatePartyForm from "./components/forms/CreatePartyForm";
import ViewPartyPage from "./pages/main/view/ViewPartyPage";
import AppItemPage from "./pages/main/AppItemPage";
import CreateItemForm from "./components/forms/CreateItemForm";
// import AppSaleInvoicePage from "./pages/main/AppSaleInvoicePage";
import CreateSaleInvoiceFormOne from "./components/forms/CreateSaleInvoiceFormOne";
import AppSaleQuotationPage from "./pages/main/AppSaleQuotationPage";
import CreateQuotationForm from "./components/forms/CreateQuotationForm";
import AppSaleproformaPage from "./pages/main/AppSaleProformaPage";
import CreateProformaForm from "./components/forms/CreateProformaForm";
import AppManageStaff from "./pages/main/AppManageStaff";
import GstCalculatorComponent from "../website/components/pages/GstCalculatorComponent";
import AppGstCalculator from "./pages/main/AppGstCalculator";
import AppEmiCalculator from "./pages/main/AppEmiCalculator";
import AppHomePage from "./pages/main/AppHomePage";
import CreateSalesInvoicePage from "./pages/form/CreateSalesInvoicePage";
import CreatePartyComponent from "./pages/form/components/CreatePartyComponent";
// import AppQuotationPage from "./pages/main/AppQuotationPage";
import CreateQuotationPage from "./pages/form/CreateQuotationPage";
// import AppProformaPage from "./pages/main/AppProformaPage";
import CreateProfomaPage from "./pages/form/CreateProfomaPage";
// import AppDeliveryChallanPage from "./pages/main/AppDeliveryChallanPage";
import CreateDeliveryChallanPage from "./pages/form/CreateDeliveryChallanPage";
// import AppSalesReturnPage from "./pages/main/AppSalesReturnPage";
import CreateSalesReturnPage from "./pages/form/CreateSalesReturnPage";
// import AppCreditNotePage from "./pages/main/AppCreditNotePage";
import CreateCreditNotePage from "./pages/form/CreateCreditNotePage";
// import AppPurchaseInvoicePage from "./pages/main/AppPurchaseInvoicePage";
import CreatePurchaseInvoicePage from "./pages/form/CreatePurchaseInvoicePage";
// import AppPurchaseReturnPage from "./pages/main/AppPurchaseReturnPage";
import CreatePurchaseReturnPage from "./pages/form/CreatePurchaseReturnPage";
// import AppDebitNotePage from "./pages/main/AppDebitNotePage";
import CreateDebitNotePage from "./pages/form/CreateDebitNotePage";
// import AppPurchaseOrderPage from "./pages/main/AppPurchaseOrderPage";
import CreatePurchaseOrderPage from "./pages/form/CreatePurchaseOrderPage";
import AppCashBankPage from "./pages/main/AppCashBankPage";
import AppTransactionPage from "./pages/main/AppTransactionPage";
import AppDashBoard from "./pages/main/AppDashBoard";
import AppStaffAttendancePayrollPage from "./pages/main/AppStaffAttendancePayrollPage";
import AppExpensePage from "./pages/main/AppExpensePage";
import CreateExpensePage from "./pages/form/CreateExpensePage";


// import CreateSalesInvoice from "./pages/invoice/CreateSalesInvoice";
// import CreateQuotation from "./pages/invoice/CreateQuotation";
import CreateSalesInvoice from "./pages/invoice/create/CreateSalesInvoice";
import AppSalesInvoicePage from "./pages/invoice/view/AppSalesInvoicePage";
import CreateQuotation from "./pages/invoice/create/CreateQuotation";
import AppQuotationPage from "./pages/invoice/view/AppQuotationPage";
import CreateSalesReturn from "./pages/invoice/create/CreateSalesReturn";
import AppSalesReturnPage from "./pages/invoice/view/AppSalesReturnPage";
import CreateCreditNote from "./pages/invoice/create/CreateCreditNote";
import AppCreditNotePage from "./pages/invoice/view/AppCreditNotePage";
import CreateDeliveryChallan from "./pages/invoice/create/CreateDeliveryChallan";
import AppDeliveryChallanPage from "./pages/invoice/view/AppDeliveryChallanPage";
import CreateProforma from "./pages/invoice/create/CreateProforma";
import AppProformaPage from "./pages/invoice/view/AppProformaPage";
import CreatePurchaseInvoice from "./pages/invoice/create/CreatePurchaseInvoice";
import AppPurchaseInvoicePage from "./pages/invoice/view/AppPurchaseInvoicePage";
import CreatePurchaseReturn from "./pages/invoice/create/CreatePurchaseReturn";
import AppPurchaseReturnPage from "./pages/invoice/view/AppPurchaseReturnPage";
import CreateDebitNote from "./pages/invoice/create/CreateDebitNote";
import AppDebitNotePage from "./pages/invoice/view/AppDebitNotePage";
import CreatePurchaseOrder from "./pages/invoice/create/CreatePurchaseOrder";
import AppPurchaseOrderPage from "./pages/invoice/view/AppPurchaseOrderPage";

const  AppMain = () => {
  return (
    <div>
      <AppConfiguration/>

      <div className="app-page-layout">
        <div className="app-page-main">
          <div className="app-page-navbar">
            
            <AppNavbar/>
            
          </div>
          <div className="app-page-content">

      <Routes>
      {/* <Route path='' element={<AppHomePage/>} /> */}
      <Route path='' element={<AppDashBoard/>} />
      <Route path='all-transaction' element={<AppTransactionPage/>} />

      <Route path='parties' element={<AppPartiesPage/>} />
      <Route path='add-party' element={<CreatePartyComponent/>} />
      
      <Route path='items' element={<AppItemPage/>} />
      
      {/* <Route path='add-sale-invoice' element={<CreateSalesInvoicePage/>} /> */}
      <Route path='sale-invoice' element={<AppSalesInvoicePage/>} />
      <Route path='add-sale-invoice' element={<CreateSalesInvoice/>} />


      <Route path='quotation-estimate' element={<AppQuotationPage/>} />
      <Route path='add-quotation-estimate' element={<CreateQuotation/>} />
      {/* <Route path='add-quotation-estimate' element={<CreateQuotationPage/>} /> */}
      
      <Route path='sales-return' element={<AppSalesReturnPage/>} />
      <Route path='add-sales-return' element={<CreateSalesReturn/>} />
      
      <Route path='credit-note' element={<AppCreditNotePage/>} />
      <Route path='add-credit-note' element={<CreateCreditNote/>} />

      
      <Route path='delivery-challan' element={<AppDeliveryChallanPage/>} />
      <Route path='add-delivery-challan' element={<CreateDeliveryChallan/>} />

      
      <Route path='proforma-invoice' element={<AppProformaPage/>} />
      <Route path='add-proforma-invoice' element={<CreateProforma/>} />
      
{/* -------------------------------------------------------------- */}

      <Route path='purchase-invoice' element={<AppPurchaseInvoicePage/>} />
      {/* <Route path='add-purchase-invoice' element={<CreatePurchaseInvoicePage/>} /> */}
      <Route path='add-purchase-invoice' element={<CreatePurchaseInvoice/>} />

      <Route path='purchase-return' element={<AppPurchaseReturnPage/>} />
      <Route path='add-purchase-return' element={<CreatePurchaseReturn/>} />

      <Route path='debit-note' element={<AppDebitNotePage/>} />
      <Route path='add-debit-note' element={<CreateDebitNote/>} />

      <Route path='purchase-order' element={<AppPurchaseOrderPage/>} />
      <Route path='add-purchase-order' element={<CreatePurchaseOrder/>} />

      <Route path='cash-bank' element={<AppCashBankPage/>} />


      <Route path='staff' element={<AppStaffAttendancePayrollPage/>} />


      <Route path='expenses' element={<AppExpensePage/>} />
      <Route path='add-expense' element={<CreateExpensePage/>} />

{/* -------------------------------------------------------------- */}
{/* -------------------------------------------------------------- */}
{/* -------------------------------------------------------------- */}
{/* -------------------------------------------------------------- */}


      {/* <Route path='quotation-estimate' element={<AppSaleQuotationPage/>} /> */}
      {/* <Route path='add-quotation-estimate' element={<CreateQuotationForm/>} /> */}

      {/* <Route path='proforma-invoice' element={<AppSaleproformaPage/>} /> */}
      {/* <Route path='add-proforma-invoice' element={<CreateProformaForm/>} /> */}

      {/* <Route path='staff' element={<AppManageStaff/>} /> */}
      <Route path='staff-attendance' element={<h1>Under Maintenance</h1>} />

      <Route path='gst-calculator' element={<AppGstCalculator/>} />
      <Route path='emi-calculator' element={<AppEmiCalculator/>} />




      {/* <Route path='' element={<AppPackageDurationComponent/>} /> */}
      {/* <Route path='add-party' element={<CreatePartyForm/>} /> */}
      {/* <Route path='add-item' element={<CreateItemForm/>} /> */}
      {/* <Route path='create-sale-invoice' element={<CreateSaleInvoiceFormOne/>} /> */}



      </Routes>
            
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default  AppMain;
