import React, { useContext } from 'react'
import { AuthContext } from '../../../../context/AuthContext';
import { toWords } from "number-to-words";

const ViewInvoiceGstTheme = ( {data, businessData, heading, invoiceType} ) => {

      
  const { link } = useContext(AuthContext);

  
  
  const mainColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--invoice")
    .trim();

  // Function to set RGBA color with opacity
  const rgbaColor = (opacity) => {
    const hex = mainColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  
  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  
  const taxableAmount = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (item.itemPurchasePrice * item.quantity -
        (item.itemPurchasePrice * item.discount * item.quantity) / 100)
    );
  }, 0);


  
  const totalGst = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);


  const totalCess = data.invoiceItemsData.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);


  console.log("business :", businessData)
    
  return (
    <div>

        <div className="invoice-gst-theme">

        <div className="invoice-gst-theme-heading">
        {heading} {heading === "tax invoice"?<div>Original for recipient</div>:""}
        </div>

        <div className="invoice-gst-theme-content">
        <div className="invoice-gst-theme-head">

            <div className="invoice-gst-theme-head-company">
            <div className="invoice-gst-theme-head-company-image">
            {businessData.companyLogo ? (
              <img
                src={`${link}/${businessData.companyLogo}`}
                alt="profile"
              />
            ) : (
              "" 
            )}
            </div>
            <div className="invoice-gst-theme-head-company-content">
                <div className="invoice-gst-theme-head-company-name">{businessData && businessData.businessName}</div>

                {
              businessData.invoiceSettingData.invoiceAddress?
                <div className="invoice-gst-theme-head-company-text">
                    
                 {
                businessData.companyBillingAddress?
                <>{businessData.companyBillingAddress},</>
              :""
              }
              {
                businessData.companyCity?
                <> {businessData.companyCity},</>
              :""
              }
              {
                businessData.companyState?
                <> {businessData.companyState},</>
              :""
              }
              {
                businessData.companyPincode?
                <> {businessData.companyPincode}</>
              :""
              }
              
                </div>
                :""}

{
              businessData.invoiceSettingData.invoicePhoneNumber?
              businessData.companyPhoneNumber ?
                <div className="invoice-gst-theme-head-company-text"><b>Mobile: </b> {businessData.companyPhoneNumber} </div>:""
                :""}

                {
  businessData.invoiceSettingData.invoiceEmail?
  businessData.companyEmail?
                <div className="invoice-gst-theme-head-company-text"><b>Email: </b>{businessData.companyEmail}</div>
                :"" :"" }

                
{
  businessData.invoiceSettingData.invoiceGSTIN?
  businessData.companyGSTIN ?
                <div className="invoice-gst-theme-head-company-text"><b>GSTIN: </b>{businessData.companyGSTIN}</div>
                :"" :"" }

            </div>

            </div>

            <div className="invoice-gst-theme-head-options">
                
                <div className="invoice-gst-theme-head-row">
                    
                    <div className="invoice-gst-theme-head-col">
                        <label>Invoice No.</label>
                        <div>{data.invoiceNumber ? data.invoiceNumber : "__________"}</div>
                    </div>

                    <div className="invoice-gst-theme-head-col">
                        <label>Invoice Date</label>
                        <div>
                        {data.invoiceDate ? (
                <>
                  {new Date(data.invoiceDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDate).getFullYear()}
                </>
              ) : (
                "__________"
              )}
                        </div>
                    </div>
                    
                    {
  data.invoiceEnableDueDate?
                    <div className="invoice-gst-theme-head-col">
                        <label>Due Date</label>
                        <div>
                  {new Date(data.invoiceDueDate)
                    .getDate()
                    .toString()
                    .padStart(2, 0)}
                  /
                  {(new Date(data.invoiceDueDate).getMonth() + 1)
                    .toString()
                    .padStart(2, 0)}
                  /{new Date(data.invoiceDueDate).getFullYear()}
                  </div>
                    </div>
                    
                :""}

                </div>


                {
  data.invoiceDetailsOptions&&data.invoiceDetailsOptions.length>0 || data.invoiceVehicleNo || data.invoicePoNo ?

                <div className="invoice-gst-theme-head-row">
                    
            {
              data.invoicePoNo?
                    <div className="invoice-gst-theme-head-col">
                        <label>P.O. No.</label>
                        <div>{data.invoicePoNo}</div>
                    </div>
                      :""
                    }

                    {
              data.invoiceVehicleNo?
                    <div className="invoice-gst-theme-head-col">
                        <label>Vehicle No.</label>
                        <div>{data.invoiceVehicleNo}</div>
                    </div>
                      :""
                    }

                    
{data.invoiceDetailsOptions ? (
                <>
                {
                  Object.entries(data.invoiceDetailsOptions)
                    .filter(([key, value]) => value) // Show only fields with values
                    .map(([key, value]) => (
                        <div className="invoice-gst-theme-head-col">
                        <label>{key} </label>
                        <div>{value}</div>
                      </div>
                    ))

                  }
         </>   
          ) : (
            ""
          )}
              

                       

                </div>
                
            :""}

            </div>

        </div>




        <div className="invoice-gst-theme-head-address">
            
         
            <div className="invoice-gst-theme-head-address-content">
                <div className="invoice-gst-theme-head-address-heading">BILL TO</div>
                <div className="invoice-gst-theme-head-address-name">{data.invoicePartyData.partyName}</div>



                {(data.invoicePartyData &&
                data.invoicePartyData.partyBillingAddress) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingState) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingCity) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingPinCode) ? (
                <div className="invoice-gst-theme-head-address-text">
                    
                  {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingAddress}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingCity ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingCity}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingState}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingPinCode}
                    </>
                  ) : (
                    ""
                  )}
                
                </div>
              ) : (
                ""
              )}



                
{
                  data.invoicePartyData && data.invoicePartyData.partyNumber ?
                  <div className="invoice-gst-theme-head-address-text"><b>Mobile :</b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
                  :""
                }

{data.invoicePartyData && data.invoicePartyData.partyGSTIN ? 
                
                <div className="invoice-gst-theme-head-address-text"><b>GSTIN :</b>{data.invoicePartyData && data.invoicePartyData.partyGSTIN}</div>
:""}

            </div>
         
            {data.invoicePartyData.partyBothAddressSame ?

            <div className="invoice-gst-theme-head-address-content">
                <div className="invoice-gst-theme-head-address-heading">SHIP TO</div>
                <div className="invoice-gst-theme-head-address-name">{data.invoicePartyData.partyName}</div>



                {(data.invoicePartyData &&
                data.invoicePartyData.partyBillingAddress) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingState) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingCity) ||
              (data.invoicePartyData &&
                data.invoicePartyData.partyBillingPinCode) ? (
                <div className="invoice-gst-theme-head-address-text">
                    
                  {data.invoicePartyData &&
                    data.invoicePartyData.partyBillingAddress}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingCity ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingCity}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingState ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingState}
                    </>
                  ) : (
                    ""
                  )}
                  {data.invoicePartyData &&
                  data.invoicePartyData.partyBillingPinCode ? (
                    <>
                      ,{" "}
                      {data.invoicePartyData &&
                        data.invoicePartyData.partyBillingPinCode}
                    </>
                  ) : (
                    ""
                  )}
                
                </div>
              ) : (
                ""
              )}



                
{
                  data.invoicePartyData && data.invoicePartyData.partyNumber ?
                  <div className="invoice-gst-theme-head-address-text"><b>Mobile :</b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
                  :""
                }

{data.invoicePartyData && data.invoicePartyData.partyGSTIN ? 
                
                <div className="invoice-gst-theme-head-address-text"><b>GSTIN :</b>{data.invoicePartyData && data.invoicePartyData.partyGSTIN}</div>
:""}

            </div>

:

(data.invoicePartyData &&
    data.invoicePartyData.partyShippingAddress) ||
  (data.invoicePartyData &&
    data.invoicePartyData.partyShippingCity) ||
  (data.invoicePartyData &&
    data.invoicePartyData.partyShippingState) ||
  (data.invoicePartyData &&
    data.invoicePartyData.partyShippingPinCode) ?

            <div className="invoice-gst-theme-head-address-content">
                <div className="invoice-gst-theme-head-address-heading">SHIP TO</div>
                <div className="invoice-gst-theme-head-address-name">{data.invoicePartyData.partyName}</div>



                
                <div className="invoice-gst-theme-head-address-text">
                    
                {data.invoicePartyData &&
                        data.invoicePartyData.partyShippingAddress}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingCity ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingCity}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingState ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingState}
                        </>
                      ) : (
                        ""
                      )}

                      {data.invoicePartyData &&
                      data.invoicePartyData.partyShippingPinCode ? (
                        <>
                          ,{" "}
                          {data.invoicePartyData &&
                            data.invoicePartyData.partyShippingPinCode}
                        </>
                      ) : (
                        ""
                      )}
                   
                
                </div>
                



                
                {
                  data.invoicePartyData && data.invoicePartyData.partyNumber ?
                  <div className="invoice-gst-theme-head-address-text"><b>Mobile :</b>{data.invoicePartyData && data.invoicePartyData.partyNumber}</div>
                  :""
                }


            </div>


            :""

}
            


            
        </div>

        <div className="invoice-gst-theme-table">
            
        <div className="invoice-gst-theme-table-head" style={{ backgroundColor: rgbaColor(0.2) }}>

            <div className='invoice-gst-theme-table-no'>S.No.</div>
            <div className='invoice-gst-theme-table-item'>items</div>

            {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
            <div className='invoice-gst-theme-table-hsn'>HSN/SAC</div>    
            :""
        }

            <div className='invoice-gst-theme-table-qty'>qty.</div>
            <div className='invoice-gst-theme-table-rate'>rate</div>

            {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
            <div className='invoice-gst-theme-table-disc'>disc.</div>
            :""
        }
            

            {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <div className='invoice-gst-theme-table-tax'>tax</div>
                    :""
                  }

{
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                    
            <div className='invoice-gst-theme-table-cess'>cess</div>
            :""
                  }

            <div className='invoice-gst-theme-table-amount'>Amount</div>
            
        </div>

        <div className="invoice-gst-theme-table-data">

        {data.invoiceItemsData.map((e, i) => (
            <div className="invoice-gst-theme-table-body">
                <div className='invoice-gst-theme-table-no'>{i + 1}</div>

                <div className='invoice-gst-theme-table-item'>{e.itemName} <span>
                      {
                        businessData.invoiceSettingData.invoiceItemDescription?
                        e.itemDescription?<div>{e.itemDescription}</div>:""
                        :""
                      }
                  </span>
                  </div>
                  
                  
                {
                  data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                  0 ?
                <div className='invoice-gst-theme-table-hsn'>{e.itemHsnCode ? e.itemHsnCode : "-"}</div>
                :""
            }
                
                <div className='invoice-gst-theme-table-qty'>{formatNumber(Number(e.quantity))*1}</div>
                <div className='invoice-gst-theme-table-rate'>
                {
                        invoiceType === "Purchase"?
                      formatNumber(Number(e.itemPurchasePrice))*1
                      :
                      formatNumber(Number(e.itemPrice))*1
                      }
                </div>

                {
                  data.invoiceItemsData.filter((e) => e.discount).length >
                  0 ?
                <div className='invoice-gst-theme-table-disc'>{e.discount ? formatNumber(e.discount)*1 : 0}%</div>
                :""
            }


{
                  data.invoiceItemsData.filter((e) => e.itemGst).length >
                  0 ? 
                <div className='invoice-gst-theme-table-tax'>{e.itemGst ? e.itemGst : 0}%</div>
                :""
            }

{
                  data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 
                  
                <div className='invoice-gst-theme-table-cess'>{e.cess ? e.cess : 0}%</div>
                :''
            }
                <div className='invoice-gst-theme-table-amount'>
                {
                        invoiceType === "Purchase"?
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPurchasePrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      :
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                    
                </div>
            </div>
                ))}


        </div>


        {
  data.invoiceExtraDiscount ? 
        <div className="invoice-gst-theme-table-body">

            <div className='invoice-gst-theme-table-no'></div>


            <div className='invoice-gst-theme-table-item'><div><div>Discount</div></div></div>

            {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
            <div className='invoice-gst-theme-table-hsn'></div>    
            :""
            }

            <div className='invoice-gst-theme-table-qty'></div>
            <div className='invoice-gst-theme-table-rate'></div>

            {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
            <div className='invoice-gst-theme-table-disc'></div>
            :""
            }


            {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <div className='invoice-gst-theme-table-tax'></div>
                    :""
                  }

            {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 

            <div className='invoice-gst-theme-table-cess'></div>
            :""
                  }

            <div className='invoice-gst-theme-table-amount'>₹ {formatNumber(data.invoiceExtraDiscount) * 1} </div>

        </div>
            :""}
        

            <div className="invoice-gst-theme-table-end">
        
        <div className="invoice-gst-theme-table-head" style={{ backgroundColor: rgbaColor(0.2) }}>

            

        <div className='invoice-gst-theme-table-no'></div>


<div className='invoice-gst-theme-table-item'><div><div>Total</div></div></div>

{
        data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
        0 ?
<div className='invoice-gst-theme-table-hsn'></div>    
:""
}

<div className='invoice-gst-theme-table-qty'></div>
<div className='invoice-gst-theme-table-rate'></div>

{
        data.invoiceItemsData.filter((e) => e.discount).length >
        0 ?
<div className='invoice-gst-theme-table-disc'></div>
:""
}


{
        data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
        <div className='invoice-gst-theme-table-tax'></div>
        :""
      }

{
        data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 

<div className='invoice-gst-theme-table-cess'></div>
:""
      }

<div className='invoice-gst-theme-table-amount'>₹ {(data.invoiceTotalAmount.toFixed(2) * 1).toLocaleString()}</div>


            
             </div>


             {
  data.receivedAmount ? 
  <>
        <div className="invoice-gst-theme-table-body">

            <div className='invoice-gst-theme-table-no'></div>

            <div className='invoice-gst-theme-table-item'>RECEIVED AMOUNT</div>

            {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
            <div className='invoice-gst-theme-table-hsn'></div>    
            :""
            }

            <div className='invoice-gst-theme-table-qty'></div>
            <div className='invoice-gst-theme-table-rate'></div>

            {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
            <div className='invoice-gst-theme-table-disc'></div>
            :""
            }


            {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <div className='invoice-gst-theme-table-tax'></div>
                    :""
                  }

            {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 

            <div className='invoice-gst-theme-table-cess'></div>
            :""
                  }

            <div className='invoice-gst-theme-table-amount'>₹ {data.receivedAmount}</div>

        </div>
        
        <div className="invoice-gst-theme-table-body">

            <div className='invoice-gst-theme-table-no'></div>

            <div className='invoice-gst-theme-table-item'>BALANCE AMOUNT</div>

            {
                    data.invoiceItemsData.filter((e) => e.itemHsnCode).length >
                    0 ?
            <div className='invoice-gst-theme-table-hsn'></div>    
            :""
            }

            <div className='invoice-gst-theme-table-qty'></div>
            <div className='invoice-gst-theme-table-rate'></div>

            {
                    data.invoiceItemsData.filter((e) => e.discount).length >
                    0 ?
            <div className='invoice-gst-theme-table-disc'></div>
            :""
            }


            {
                    data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ? 
                    <div className='invoice-gst-theme-table-tax'></div>
                    :""
                  }

            {
                    data.invoiceItemsData.filter((e) => e.cess).length > 0 ? 

            <div className='invoice-gst-theme-table-cess'></div>
            :""
                  }

            <div className='invoice-gst-theme-table-amount'>
            ₹{" "}
                      {Math.round(
                        data.invoiceTotalAmount.toFixed(2) - data.receivedAmount
                      )}
            </div>

        </div>
</>
            :""}
        

        
        
        
        </div>
        </div>


        </div>


        <div className="invoice-gst-theme-tax-table">

        <div className="invoice-gst-theme-tax-table-head" style={{ backgroundColor: rgbaColor(0.2) }}>
            <div className="invoice-gst-theme-tax-table-hsn">HSN/SAC</div>
            <div className="invoice-gst-theme-tax-table-taxable">Taxable Value</div>

            <div className="invoice-gst-theme-tax-table-gst">
                <div className="invoice-gst-theme-tax-table-gst-heading">CGST</div>
                <div className="invoice-gst-theme-tax-table-gst-heading-1">
                    <div>Rate</div>
                    <div>Amount</div>
                </div>
            </div>
            
            <div className="invoice-gst-theme-tax-table-gst">
                <div className="invoice-gst-theme-tax-table-gst-heading">CGST</div>
                <div className="invoice-gst-theme-tax-table-gst-heading-1">
                    <div>Rate</div>
                    <div>Amount</div>
                </div>
            </div>

            <div className="invoice-gst-theme-tax-table-cess">Cess</div>
            <div className="invoice-gst-theme-tax-table-total">Total Tax Amount</div>

        </div>


        {data.invoiceItemsData.map((e, i) => (

        <div className="invoice-gst-theme-tax-table-body">
            <div className="invoice-gst-theme-tax-table-hsn">{e.itemHsnCode ? e.itemHsnCode : "-"}</div>
            <div className="invoice-gst-theme-tax-table-taxable">

            {
                        invoiceType === "Purchase"?
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPurchasePrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      :
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                
            </div>

            <div className="invoice-gst-theme-tax-table-gst">
                
                <div className="invoice-gst-theme-tax-table-gst-heading-1">
                    <div>{e.itemGst ? e.itemGst/2 : 0}%</div>
                    <div>

                    {
                        invoiceType === "Purchase"?
                        formatNumber(
                          (
                              ((e.itemPurchasePrice -
                            (e.discount * e.itemPurchasePrice) / 100) *
                            e.itemGst) /
                            100/2
                          ) *
                            e.quantity
                        ) * 1
                      :
                      formatNumber(
                        (
                            ((e.itemPrice -
                          (e.discount * e.itemPrice) / 100) *
                          e.itemGst) /
                          100/2
                        ) *
                          e.quantity
                      ) * 1
                      
                      }

                  
                        
                    </div>
                </div>
            </div>
            
            <div className="invoice-gst-theme-tax-table-gst">
                
                <div className="invoice-gst-theme-tax-table-gst-heading-1">
                    <div>{e.itemGst ? e.itemGst/2 : 0}%</div>
                    <div>
                    {
                        invoiceType === "Purchase"?
                        formatNumber(
                          (
                              ((e.itemPurchasePrice -
                            (e.discount * e.itemPurchasePrice) / 100) *
                            e.itemGst) /
                            100/2
                          ) *
                            e.quantity
                        ) * 1
                      :
                      formatNumber(
                        (
                            ((e.itemPrice -
                          (e.discount * e.itemPrice) / 100) *
                          e.itemGst) /
                          100/2
                        ) *
                          e.quantity
                      ) * 1
                      
                      }
                    </div>
                </div>
            </div>

            <div className="invoice-gst-theme-tax-table-cess">
              
              
            {
                        invoiceType === "Purchase"?
                        formatNumber(
                          (
                            ((e.itemPurchasePrice -
                              (e.discount * e.itemPurchasePrice) / 100) *
                              e.cess) /
                              100
                          ) *
                            e.quantity
                        ) * 1
                      :
                      formatNumber(
                        (
                          ((e.itemPrice -
                            (e.discount * e.itemPrice) / 100) *
                            e.cess) /
                            100
                        ) *
                          e.quantity
                      ) * 1
                      
                      }
              
            {/* {formatNumber(
                            (
                              ((e.itemPrice -
                                (e.discount * e.itemPrice) / 100) *
                                e.cess) /
                                100
                            ) *
                              e.quantity
                          )} */}
            </div>
            <div className="invoice-gst-theme-tax-table-total">

              
            {
                        invoiceType === "Purchase"?
                        formatNumber(
                          (((e.itemPurchasePrice -
                            (e.discount * e.itemPurchasePrice) / 100) *
                            e.itemGst) /
                            100 +
                            ((e.itemPurchasePrice -
                              (e.discount * e.itemPurchasePrice) / 100) *
                              e.cess) /
                              100) *
                            e.quantity
                        )* 1
                      :
                      formatNumber(
                        (((e.itemPrice -
                          (e.discount * e.itemPrice) / 100) *
                          e.itemGst) /
                          100 +
                          ((e.itemPrice -
                            (e.discount * e.itemPrice) / 100) *
                            e.cess) /
                            100) *
                          e.quantity
                      ) * 1
                      
                      }
              
            </div>

        </div>
        
    ))}



        {/* <div className="invoice-gst-theme-tax-table-body">
            <div className="invoice-gst-theme-tax-table-hsn">HSN/SAC</div>
            <div className="invoice-gst-theme-tax-table-taxable">Taxable Value</div>

            <div className="invoice-gst-theme-tax-table-gst">
                
                <div className="invoice-gst-theme-tax-table-gst-heading-1">
                    <div>99%</div>
                    <div>Amount</div>
                </div>
            </div>
            
            <div className="invoice-gst-theme-tax-table-gst">
                
                <div className="invoice-gst-theme-tax-table-gst-heading-1">
                    <div>Rate</div>
                    <div>Amount</div>
                </div>
            </div>

            <div className="invoice-gst-theme-tax-table-cess">Cess</div>
            <div className="invoice-gst-theme-tax-table-total">Total Tax Amount</div>

        </div> */}

        
        <div className="invoice-gst-theme-tax-table-body"></div>

        </div>

        <div className="invoice-gst-theme-end">

            <div className="invoice-gst-theme-end-amount">
                <div className="invoice-gst-theme-end-heading">Total Amount (in words)</div>
                <div className="invoice-gst-theme-end-text">{toWords(data.invoiceTotalAmount)} rupees</div>
            </div>

            <div className="invoice-gst-theme-end-row">



 {


data.paymentType === "Bank Transfer" ||
data.paymentType === "UPI" ||
data.paymentType === "Card" ||
data.paymentType === "Cheque" ||
data.paymentType === "Netbanking" ?

data.selectedBankAccount? (
            businessData.bankData&&businessData.bankData.filter( e => data.selectedBankAccount === e._id).map( e => (
         
                <div className="invoice-gst-theme-end-bank">
                    <div className="invoice-gst-theme-end-heading">Bank Details</div>
                    
                    <div className="invoice-gst-theme-end-bank-row">
                        <div>Name:</div>
                        <span>{e.accountHolderName}</span>
                    </div>

                    <div className="invoice-gst-theme-end-bank-row">
                        <div>IFSC Code:</div>
                        <span>{e.IFSCcode}</span>
                    </div>

                    <div className="invoice-gst-theme-end-bank-row">
                        <div>Account No:</div>
                        <span>{e.accountNumber}</span>
                    </div>

                    <div className="invoice-gst-theme-end-bank-row">
                        <div>Bank:</div>
                        <span>{e.bankName}</span>
                    </div>

                </div>

                
              ))
            ) : 
              ""
              :""
            } 



{
                  businessData.businessTermsConditions ||
                  data.invoiceNotes ? 
                <div className="invoice-gst-theme-end-tc">
                  
                  {
                      data.invoiceNotes ? 
                                    <div className="invoice-gst-theme-end-notes">
                                      <div className="invoice-gst-theme-end-heading">Notes</div>
                                      <div className="invoice-gst-theme-end-text">
                                          <pre>{data.invoiceNotes}</pre>
                                      </div>
                                    </div>
                                  :""}

{
    businessData.businessTermsConditions ? 
                  <>
                    <div className="invoice-gst-theme-end-heading">Terms and Conditions</div>
                    <div className="invoice-gst-theme-end-text">
                        <pre>{businessData.businessTermsConditions}</pre>
                    </div>
                  </>
                :""}

</div>
:""}




{
  businessData.companySignatureType === "Box"?
                <div className="invoice-gst-theme-end-signature">

                    <div className="invoice-gst-theme-end-signature-box"></div>
     
     
                    <div className="invoice-gst-theme-end-signature-text">
                    Authorized Signatory For
                    <div>{businessData && businessData.businessName}</div>
                    </div>

                </div>
 :
 businessData.companySignatureType === "Image"?

 
 businessData.companySignature ?
                <div className="invoice-gst-theme-end-signature">
                    
                    <div className="invoice-gst-theme-end-signature-img">
                    <img src={`${link}/${businessData.companySignature }`} alt="comapny signature"/>
                    </div>
                    <div className="invoice-gst-theme-end-signature-text">
                    Authorized Signatory For
                    <div>{businessData && businessData.businessName}</div>
                    </div>

                </div>

             :""   :""}


                

            </div>


        </div>




        </div>
        
    </div>
  )
}

export default ViewInvoiceGstTheme