import React, { useContext, useEffect, useState } from 'react'
import AppExpenseComponent from '../../components/pageComponents/AppExpenseComponent'
import LoadingComponent from '../../../components/extra/LoadingComponent';
import { AuthContext } from '../../../context/AuthContext';

const AppExpensePage = () => {


   // get from storage ============================================================================================

   const userId = localStorage.getItem("invoician-user-id");
   const businessId = localStorage.getItem("business-id");
 
   // useState ============================================================================================
 
   const [businessData, setBusinessData] = useState(null);
 
   
   //  ============================================================================================
   const { fetchBusiness } = useContext(AuthContext);
   const fetchBusinessData = (e) =>
     fetchBusiness(userId, businessId, setBusinessData);
 
   useEffect(() => {
     fetchBusinessData();
   }, []);
 
   if (!businessData)
     return (
       <div>
         <LoadingComponent />
       </div>
     );
  
  
  
  
  return (
    <div>


        <AppExpenseComponent businessData={businessData} fetchBusinessData={fetchBusinessData}/>
        
    </div>
  )
}

export default AppExpensePage