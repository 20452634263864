import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const CreatePartyPopup = ({ open, invoicePartyData, setTimerPopupAlert, setTimerAlertMessage, setTimerAlertStatus, fetchBusinessData, setDemoSatus }) => {
    
        
  // get from storage ============================================================================================
  
  
  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  

    
    // useStates  ================================================================================================================

    
  const [addAddress, setAddAddress] = useState(false);
  const [addGstin, setAddGstin] = useState(false);

  const [loading, setLoading] = useState(false)


  const [data, setData] = useState({
    partyName: "",
    partyNumber: "",
    partyBillingAddress: "",
    partyBillingCity: "",
    partyBillingState: "",
    partyBillingPinCode: "",
    partyBothAddressSame: false,
    partyShippingAddress: "",
    partyShippingCity: "",
    partyShippingState: "",
    partyShippingPinCode: "",
    partyGSTIN: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };


//   --------------------------------------------------------------------------------------------------------------



const { createParty } = useContext(AuthContext);

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); // Prevent form submission on Enter
  }
};


const handleCreate = async (e) => {  
setLoading(true);
e.preventDefault();
await createParty(userId, businessId, data,  setTimerPopupAlert, setTimerAlertMessage, "", "", invoicePartyData, open, setTimerAlertStatus, setDemoSatus);
setLoading(false);
fetchBusinessData&&fetchBusinessData()
};



  

  return (
    <div>
      <form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Create New Party</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">
            <div className="create-party-popup-form-col">
              <label>
                PARTY NAME <span>*</span>
              </label>
              <input
                type="text"
                required
                onChange={handleChange}
                name="partyName"
                placeholder="Enter Name"
              />
            </div>

            <div className="create-party-popup-form-col">
              <label>MOBILE NUMBER</label>
              <input
                type="number"
                onChange={handleChange}
                name="partyNumber"
                placeholder="Enter Mobile Number"
              />
            </div>
            {addAddress ? (
              <>
                <div className="create-party-popup-form-head">
                  <div>Address (Optional)</div>
                  <span onClick={() => setAddAddress(false)}>Remove</span>
                </div>
                <div className="create-party-popup-form-row-flex">
                  <div>
                    <div className="create-party-popup-form-col">
                      <label>
                        BILLING ADDRESS <span>*</span>
                      </label>
                      <textarea
                        type="text"
                        required
                        onChange={handleChange}
                        name="partyBillingAddress"
                        placeholder="Enter Billing Address"
                      />
                    </div>

                    <div className="create-party-popup-form-row">
                      <div className="create-party-popup-form-col">
                        <label>STATE</label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="partyBillingState"
                          placeholder="Enter State"
                          list="state-list"
                        />

<datalist id="state-list">
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Ladakh">Ladakh</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand </option>
                        </datalist>
                        
                      </div>

                      <div className="create-party-popup-form-col">
                        <label>PINCODE</label>
                        <input
                          type="number"
                          onChange={handleChange}
                          name="partyBillingPinCode"
                          placeholder="Enter Pincode"
                        />
                      </div>
                    </div>

                    <div className="create-party-popup-form-col">
                      <label>CITY</label>
                      <input
                        type="text"
                        onChange={handleChange}
                        name="partyBillingCity"
                        placeholder="Enter City"
                      />
                    </div>
{
  data.partyBothAddressSame?

                    <div className="create-party-popup-form-option" onClick={ ()=> setData({...data, partyBothAddressSame : false}) }>
                        <i class="fa-regular fa-square-check"></i> Shipping address same as billing address 
                    </div>
:
                    <div className="create-party-popup-form-option" onClick={ ()=> setData({...data, partyBothAddressSame : true}) }>
                        <i class="fa-regular fa-square"></i> Shipping address same as billing address 
                    </div>
}


                    {/* <div
                      className="create-party-popup-form-option"
                      onClick={() =>
                        setShippingAddressSame(!shippindAddressSame)
                      }
                    >
                      {shippindAddressSame ? (
                        <i class="fa-regular fa-square-check"></i>
                      ) : (
                        <i class="fa-regular fa-square"></i>
                      )}
                      Shipping address same as billing address
                    </div> */}

                  </div>

                  {data.partyBothAddressSame ? (
                    ""
                  ) : (
                    <div>
                      <div className="create-party-popup-form-col">
                        <label>SHIPPING ADDRESS</label>
                        <textarea
                          type="text"
                          onChange={handleChange}
                          name="partyShippingAddress"
                          placeholder="Enter Shipping Address"
                        />
                      </div>

                      <div className="create-party-popup-form-row">
                        <div className="create-party-popup-form-col">
                          <label>STATE</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            name="partyShippingState"
                            placeholder="Enter State"
                            list="state-list"
                          />
                        </div>

                        <div className="create-party-popup-form-col">
                          <label>PINCODE</label>
                          <input
                            type="number"
                            onChange={handleChange}
                            name="partyShippingPinCode"
                            placeholder="Enter Pincode"
                          />
                        </div>
                      </div>

                      <div className="create-party-popup-form-col">
                        <label>CITY</label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="partyShippingCity"
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div
                className="create-party-popup-form-link"
                onClick={() => setAddAddress(true)}
              >
                + Add Address (Optional)
              </div>
            )}
            {addGstin ? (
              <>
                <div className="create-party-popup-form-head">
                  <div>GSTIN (Optional)</div>
                  <span
                    onClick={() => {
                      setAddGstin(false);
                    }}
                  >
                    Remove
                  </span>
                </div>
                <div className="create-party-popup-form-col">
                  <label>GSTIN</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="partyGSTIN"
                    placeholder="ex: 29XXXXXXXXXXXXXX"
                  />
                </div>
              </>
            ) : (
              <div
                className="create-party-popup-form-link"
                onClick={() => {
                  setAddGstin(true);
                }}
              >
                + Add GSTIN (Optional)
              </div>
            )}
          </div>

          <div className="create-party-popup-buttons">
            <div
              className="create-party-popup-button-cancel"
              onClick={() => open(false)}
            >
              Cancel
            </div>
            {/* <button className="create-party-popup-button-save" onClick={handleSaveData}>Save</button> */}
            <button className="create-party-popup-button-save">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePartyPopup;
