import React, { useRef, useState } from "react";

const InvoiceTotalAmountComponent = ({
  formatNumber,
  taxableAmount,
  totalGst,
  totalCess,
  data,
  setData,
  subTotal,
  handleWheelScroll,
  totalAmount,
  invoiceData,
}) => {
    
    // ====================================================================================================================
    
    // const [AddExtraDiscount, setAddExtraDiscount] = useState(false);
    const [AddExtraDiscount, setAddExtraDiscount] = useState(
      invoiceData&&invoiceData.invoiceExtraDiscount ? true : false
    );
    
    // ====================================================================================================================
    
  const inputSelectRef1 = useRef(null);
  const inputSelectRef2 = useRef(null);
    
    
    // ====================================================================================================================
    
    const handleExtraDiscount = (e) => {
        const value = e.target.value;
        
        if ((value >= 0 && value <= subTotal) || value === "") {
            setData({
                ...data,
                invoiceExtraDiscount: value,
            });
        }
    };
    
    const handleExtraDiscountPercentage = (e) => {
        const value = e.target.value;
        
        if ((value >= 0 && value <= 100) || value === "") {
            const dis = (subTotal * value) / 100;
            
            setData({
                ...data,
                invoiceExtraDiscount: dis,
            });
        }
    };
    
    // ====================================================================================================================
  
  

    
  return (
    <div>
      <div>
        <div className="create-invoice-page-end-amount-row">
          <div>Taxable Amount</div>
          <div>₹ {formatNumber(taxableAmount) * 1}</div>
        </div>
        {totalGst ? (
          <>
            <div className="create-invoice-page-end-amount-row">
              <div>SGST</div>
              <div>₹ {formatNumber(totalGst) / 2}</div>
            </div>

            <div className="create-invoice-page-end-amount-row">
              <div>CGST</div>
              <div>₹ {formatNumber(totalGst) / 2}</div>
            </div>
          </>
        ) : (
          ""
        )}

        {totalCess ? (
          <div className="create-invoice-page-end-amount-row">
            <div>CESS</div>
            <div>₹ {formatNumber(totalCess) * 1}</div>
          </div>
        ) : (
          ""
        )}

        <div className="create-invoice-page-end-amount-discount">
          {AddExtraDiscount ? (
            <div className="create-invoice-page-end-amount-discount-row">
              <div>Discount After Tax</div>

              <div className="create-invoice-page-end-amount-discount-col">
                <div className="create-invoice-page-end-amount-discount-input">
                  %
                  <input
                    type="number"
                    value={
                      formatNumber(
                        (data.invoiceExtraDiscount * 100) / subTotal
                      ) * 1
                    }
                    onChange={handleExtraDiscountPercentage}
                    max={100}
                    ref={inputSelectRef1}
                    onWheel={(e) => {
                      handleWheelScroll(e, inputSelectRef1);
                    }}
                  />
                </div>

                <div className="create-invoice-page-end-amount-discount-input">
                  ₹
                  <input
                    value={formatNumber(data.invoiceExtraDiscount * 1) * 1}
                    type="number"
                    name="editingExtraDiscount"
                    onChange={handleExtraDiscount}
                    ref={inputSelectRef2}
                    onWheel={(e) => {
                      handleWheelScroll(e, inputSelectRef2);
                    }}
                  />
                </div>

                <i
                  class="fa-regular fa-circle-xmark"
                  onClick={() => {
                    setAddExtraDiscount(false);
                    setData({
                      ...data,
                      invoiceExtraDiscount: 0,
                    });
                  }}
                ></i>
              </div>
            </div>
          ) : (
            <div
              className="create-invoice-page-end-link"
              onClick={() => setAddExtraDiscount(true)}
            >
              + Add Discount
            </div>
          )}
        </div>

        <div className="create-invoice-page-end-hr"></div>
        {data.roundOff ? (
          <div
            onClick={() => 
                setData({
                    ...data,
                    roundOff : false
                })
                
            }
            className="create-invoice-page-end-option"
            >
            <i class="fa-regular fa-square-check"></i>
            Auto Round Off
          </div>
        ) : (
            <div
            onClick={() => 
                setData({
                    ...data,
                    roundOff : true
                })
            }
            className="create-invoice-page-end-option"
          >
            <i class="fa-regular fa-square"></i>
            Auto Round Off
          </div>
        )}

        <div className="create-invoice-page-end-total">
          <div>Total Amount</div>
          <div>₹ {totalAmount * 1}</div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTotalAmountComponent;
