import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceAddressComponent from "./../invoiceComponent/InvoiceAddressComponent";
import TimerPopupAlertComponent from "../../../../components/extra/TimerPopupAlertComponent";
import InvoiceTotalAmountComponent from "./../invoiceComponent/InvoiceTotalAmountComponent";
import InvoicePaidAmountComponent from "./../invoiceComponent/InvoicePaidAmountComponent";
import InvoiceItemsComponent from "./../invoiceComponent/InvoiceItemsComponent";
import { AuthContext } from "../../../../context/AuthContext";
import CreatePartyPopup from "../../../pages/form/CreatePartyPopup";
import CreateItemPopup from "../../../pages/form/CreateItemPopup";
import InvoiceOptionsUpdateComponent from "../invoiceComponent/InvoiceOptionsUpdateComponent";
import InvoicePurchaseItemsComponent from "../invoiceComponent/InvoicePurchaseItemsComponent";

const UpdateDebitNotePageComponent = ({
  open,
  id,
  invoiceData,
  fetchBusinessData,
  businessData,
  handleInvoiceHeight,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // router navigate  ================================================================================================================
  const navigate = useNavigate();

  // all useStates  ================================================================================================================

  const [loading, setLoading] = useState(false);

  const [partyData, setPartyData] = useState(
    invoiceData.invoicePartyData ? invoiceData.invoicePartyData : {}
  );

  const [createParty, setCreateParty] = useState("");

  const [selectedItems, setSelectedItems] = useState(
    invoiceData.invoiceItemsData ? invoiceData.invoiceItemsData : []
  );

  const [createItem, setCreateItem] = useState(false);

  const [editingDueDate, setEditingDueDate] = useState(null);

  const [detailsOptionValue, setdetailsOptionValue] = useState({
    ...invoiceData.invoiceDetailsOptions,
    ...{},
  });

  const [AddNotes, setAddNotes] = useState(false);
  const [AddTerms, setAddTerms] = useState(false);

  
  // on Enter don't submit ====================================================================================================================

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  // handle input values ====================================================================================================================

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  // handle input on scroll ====================================================================================================================

  const handleWheelScroll = (event, ref) => {
    event.preventDefault(); // Prevent the scroll wheel from changing the input value
    if (ref.current) {
      ref.current.blur(); // Remove focus from the input
    }
  };

  // handle number float values ====================================================================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // handle number float values ====================================================================================================================

  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  const subTotal = selectedItems.reduce((acc, item) => {
    return (
      acc +
      calculateTaxedPrice(
        item.itemPurchasePrice,
        item.itemGst,
        item.quantity,
        item.discount,
        item.cess
      )
    );
  }, 0);

  const totalTax = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100 +
        ((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
          item.cess) /
          100) *
        item.quantity
    );
  }, 0);

  const totalDiscount = selectedItems.reduce((acc, item) => {
    return acc + (item.itemPurchasePrice * item.discount * item.quantity) / 100;
  }, 0);

  const taxableAmount = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (item.itemPurchasePrice * item.quantity -
        (item.itemPurchasePrice * item.discount * item.quantity) / 100)
    );
  }, 0);

  const totalGst = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);

  const totalCess = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice - (item.discount * item.itemPurchasePrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);

  //   ================================================================================================================================================================================================================================
  //   main data ================================================================================================================================================================================================================================
  //   ================================================================================================================================================================================================================================

  const today = new Date();

  const dueDate = new Date(today);
  dueDate.setDate(today.getDate() + 30);

  const [data, setData] = useState({
    invoiceNumber: invoiceData.invoiceNumber,
    invoiceDate: invoiceData.invoiceDate,
    invoiceEnableDueDate: invoiceData.invoiceEnableDueDate,
    invoiceDueDate: invoiceData.invoiceDueDate,
    invoicePartyData: invoiceData.invoicePartyData,
    invoiceItemsData: invoiceData.invoiceItemsData,
    invoiceExtraDiscount: invoiceData.invoiceExtraDiscount,
    roundOff: invoiceData.roundOff,
    fullyPaid: invoiceData.fullyPaid,
    receivedAmount: invoiceData.receivedAmount,
    paymentType: invoiceData.paymentType,
    invoiceNotes: invoiceData.invoiceNotes,
    invoiceSubTotalAmount: invoiceData.invoiceSubTotalAmount,
    invoiceTotalAmount: invoiceData.invoiceTotalAmount,
    invoiceDetailsOptions: invoiceData.invoiceDetailsOptions
      ? invoiceData.invoiceDetailsOptions
      : {},
    selectedBankAccount: invoiceData.selectedBankAccount
      ? invoiceData.selectedBankAccount
      : "",
    invoicePoNo: invoiceData.invoicePoNo ? invoiceData.invoicePoNo : "",
    invoiceVehicleNo: invoiceData.invoiceVehicleNo
      ? invoiceData.invoiceVehicleNo
      : "",

    invoiceTermsConditions:
      businessData && businessData.businessTermsConditions
        ? businessData.businessTermsConditions
        : "",
    // invoiceTermsConditions: businessData.businessTermsConditions,
  });

  const totalAmount = data.roundOff
    ? Math.round(subTotal - data.invoiceExtraDiscount)
    : formatNumber(subTotal - data.invoiceExtraDiscount);

  // save data before submit  ================================================================================================================

  const handleSaveData = () => {
    setData({
      ...data,
      invoicePartyData: partyData,
      invoiceItemsData: selectedItems,
      invoiceSubTotalAmount: subTotal,
      invoiceTotalAmount: totalAmount * 1,
      receivedAmount:
        data.fullyPaid === true ? totalAmount * 1 : data.receivedAmount,

      invoiceDetailsOptions: Object.fromEntries(
        Object.entries(detailsOptionValue).filter(([key, value]) => value)
      ),
    });
  };

  // post data ================================================================================================================

  const { updateDebitNote } = useContext(AuthContext);

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    await updateDebitNote(
      userId,
      businessId,
      id,
      data,
      fetchBusinessData,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open,
    );
    handleInvoiceHeight();

    setLoading(false);
  };

  return (
    <div>
      

      {createParty ? (
        <CreatePartyPopup
          open={setCreateParty}
          invoicePartyData={setPartyData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {createItem ? (
        <CreateItemPopup
          open={setCreateItem}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
          createType="Purchase"
        />
      ) : (
        ""
      )}

      <form onSubmit={handleUpdate}>
        <div className="create-invoice-page">
          <div className="create-invoice-page-main">
            {/* top ----------------------------------------------------------------------------------------------------------- */}

            <div className="create-invoice-page-main-top">
              <div className="create-invoice-page-main-top-heading">
                <i
                  onClick={(e) => open(false)}
                  class="fa-solid fa-arrow-left"
                ></i>
                Update Debit Note
              </div>
              <div className="create-invoice-page-main-top-buttons">
                {loading !== true && partyData && selectedItems.length > 0 ? (
                  <button onClick={handleSaveData}>Update </button>
                ) : (
                  <div className="create-invoice-page-main-top-button-save">
                    Update 
                  </div>
                )}
              </div>
            </div>

            <div className="create-invoice-page-form">
              {/* content ----------------------------------------------------------------------------------------------------------- */}
              <div className="create-invoice-page-content">
                {/* head ----------------------------------------------------------------------------------------------------------- */}

                <div className="create-invoice-page-head">
                  <InvoiceAddressComponent
                    partyData={partyData}
                    setPartyData={setPartyData}
                    businessData={businessData}
                    handleKeyDown={handleKeyDown}
                    setCreateParty={setCreateParty}
                  />

                  <InvoiceOptionsUpdateComponent
                    businessData={businessData}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    data={data}
                    setData={setData}
                    editingDueDate={editingDueDate}
                    setEditingDueDate={setEditingDueDate}
                    detailsOptionValue={detailsOptionValue}
                    setdetailsOptionValue={setdetailsOptionValue}
                    // dueDate="Yes"
                  />
                </div>

                {/* items content ----------------------------------------------------------------------------------------------------------- */}

                <InvoicePurchaseItemsComponent
                  businessData={businessData}
                  handleKeyDown={handleKeyDown}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  formatNumber={formatNumber}
                  totalDiscount={totalDiscount}
                  totalTax={totalTax}
                  subTotal={subTotal}
                  calculateTaxedPrice={calculateTaxedPrice}
                  handleWheelScroll={handleWheelScroll}
                  setCreateItem={setCreateItem}
                />

                {/* invoice end ----------------------------------------------------------------------------------------------------------- */}

                <div className="create-invoice-page-end">
                  <div className="create-invoice-page-end-col-1">
                    {AddNotes ? (
                      <div className="create-invoice-page-end-form">
                        <div className="create-invoice-page-end-head">
                          <div>Notes</div>
                          <span>
                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddNotes(false);
                                setData({
                                  ...data,
                                  invoiceNotes: "",
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                        <textarea
                          name="invoiceNotes"
                          value={data.invoiceNotes}
                          onChange={handleChange}
                          placeholder="Enter your notes"
                        ></textarea>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-end-link"
                        onClick={() => {
                          setAddNotes(true);
                        }}
                      >
                        + Add Notes
                      </div>
                    )}

                    {AddTerms || data.invoiceTermsConditions ? (
                      <div className="create-invoice-page-end-form">
                        <div className="create-invoice-page-end-head">
                          <div>Terms and Conditions</div>
                          <span>
                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddTerms(false);
                                setData({
                                  ...data,
                                  invoiceTermsConditions: "",
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                        <textarea
                          name="invoiceTermsConditions"
                          value={data.invoiceTermsConditions}
                          onChange={handleChange}
                          placeholder="Enter your terms and conditions"
                        ></textarea>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-end-link"
                        onClick={() => {
                          setAddTerms(true);
                        }}
                      >
                        + Terms and Conditions
                      </div>
                    )}

                    {data.paymentType === "Bank Transfer" ||
                    data.paymentType === "UPI" ||
                    data.paymentType === "Card" ||
                    data.paymentType === "Cheque" ||
                    data.paymentType === "Netbanking"
                      ? businessData.bankData &&
                        businessData.bankData
                          .filter((e) => data.selectedBankAccount === e._id)
                          .map((e) => (
                            <div className="create-invoice-page-end-bank">
                              <div className="create-invoice-page-end-bank-head">
                                <div>Bank Details</div>
                              </div>
                              <div className="create-invoice-page-end-bank-col">
                                <div>Account Number:</div>
                                <span>{e.accountNumber}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-col">
                                <div>IFSC Code:</div>
                                <span>{e.IFSCcode}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-col">
                                <div>Bank & Branch Name:</div>
                                <span>{e.bankName}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-col">
                                <div>Account Holder's Name:</div>
                                <span>{e.accountHolderName}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-head">
                                <div>UPI ID</div>
                              </div>
                              {e.upiId ? (
                                <th>{e.upiId}</th>
                              ) : (
                                <>No UPI ID Added</>
                              )}
                            </div>
                          ))
                      : ""}
                  </div>

                  <div className="create-invoice-page-end-amount">
                    <InvoiceTotalAmountComponent
                      formatNumber={formatNumber}
                      taxableAmount={taxableAmount}
                      totalGst={totalGst}
                      totalCess={totalCess}
                      subTotal={subTotal}
                      data={data}
                      setData={setData}
                      handleWheelScroll={handleWheelScroll}
                      totalAmount={totalAmount}
                      invoiceData={invoiceData}
                    />

                    <InvoicePaidAmountComponent
                      totalAmount={totalAmount}
                      handleChange={handleChange}
                      businessData={businessData}
                      data={data}
                      setData={setData}
                      handleWheelScroll={handleWheelScroll}
                    />
                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}
              </div>
            </div>

            {/* ----------------------------------------------------------------------------------------------------------- */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateDebitNotePageComponent;
