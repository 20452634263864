import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const CreateStaffPopup = ({
  open,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  fetchData,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [data, setData] = useState({
    staffName: "",
    staffNumber: "",
    staffPayoutType: "Monthly",
    staffSalary: "",
    staffSalaryCycle: "1",
    openingBalance: 0,
    openingBalanceType: "To Pay",
    
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  //   --------------------------------------------------------------------------------------------------------------

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const { createStaff } = useContext(AuthContext);

  const handleCreate = async (e) => {
    e.preventDefault();
    await createStaff(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open
    );

    fetchData && fetchData();
    
  };

  return (
    <div>
      {/* <form className="create-party-popup"> */}
      <form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Add Staff</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">
            <div className="create-party-popup-form-row">
              <div className="create-party-popup-form-col">
                <label>
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  required
                  onChange={handleChange}
                  name="staffName"
                  value={data.staffName}
                  placeholder="Enter Employee Name"
                />
              </div>

              <div className="create-party-popup-form-col">
                <label>
                  Mobile Number<span>*</span>
                </label>
                <input
                  type="number"
                  required
                  onChange={handleChange}
                  name="staffNumber"
                  value={data.staffNumber}
                  placeholder="9999999999"
                />
              </div>

              <div className="create-party-popup-form-col">
                <label>
                Salary Payout Type<span>*</span>
                </label>

                <select onChange={handleChange} name="staffPayoutType" value={data.staffPayoutType}>
                  <option value="Monthly">Monthly</option>
                  <option value="Daily">Daily</option>
                </select>
                
                
              </div>

              <div className="create-party-popup-form-col">
                <label>
                  Salary <span>*</span>
                </label>
                <input
                  type="number"
                  required
                  onChange={handleChange}
                  name="staffSalary"
                  value={data.staffSalary}
                  placeholder="₹ 20000"
                />
              </div>

              <div className="create-party-popup-form-col">
                <label>
                  Salary Cycle
                </label>
                <select onChange={handleChange} name="staffSalaryCycle" value={data.staffSalaryCycle}>
                    <option value="1">1 to 1 every month</option>
                    <option value="2">2 to 2 every month</option>
                    <option value="3">3 to 3 every month</option>
                    <option value="4">4 to 4 every month</option>
                    <option value="5">5 to 5 every month</option>
                    <option value="6">6 to 6 every month</option>
                    <option value="7">7 to 7 every month</option>
                    <option value="8">8 to 8 every month</option>
                    <option value="9">9 to 9 every month</option>
                    <option value="10">10 to 10 every month</option>
                    <option value="11">11 to 11 every month</option>
                    <option value="12">12 to 12 every month</option>
                    <option value="13">13 to 13 every month</option>
                    <option value="14">14 to 14 every month</option>
                    <option value="15">15 to 15 every month</option>
                    <option value="16">16 to 16 every month</option>
                    <option value="17">17 to 17 every month</option>
                    <option value="18">18 to 18 every month</option>
                    <option value="19">19 to 19 every month</option>
                    <option value="20">20 to 20 every month</option>
                    <option value="21">21 to 21 every month</option>
                    <option value="22">22 to 22 every month</option>
                    <option value="23">23 to 23 every month</option>
                    <option value="24">24 to 24 every month</option>
                    <option value="25">25 to 25 every month</option>
                    <option value="26">26 to 26 every month</option>
                    <option value="27">27 to 27 every month</option>
                    <option value="28">28 to 28 every month</option>
                </select>
              </div>

              {/* <div className="create-party-popup-form-col">
                <label>Outstanding/Opening Balance</label>
              <div>
                <input
                  type="number"
                  onChange={handleChange}
                  name="openingBalance"
                  value={data.openingBalance}
                  placeholder="₹ 0"
                />
                <select onChange={handleChange} name="openingBalanceType" value={data.openingBalanceType} id="">
                  <option value="To Pay">To Pay</option>
                  <option value="To Collect">To Collect</option>
                </select>

              </div>
              </div> */}
            </div>
          </div>

          <div className="create-party-popup-buttons">
            <div
              className="create-party-popup-button-cancel"
              onClick={() => open(false)}
            >
              Cancel
            </div>
            {/* <button className="create-party-popup-button-save" onClick={handleSaveData}>Save</button> */}
            <button className="create-party-popup-button-save">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateStaffPopup;
