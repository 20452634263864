import React, { useRef } from 'react'

const InvoicePaidAmountComponent = ({
  data,
setData,
handleWheelScroll,
totalAmount,
handleChange,
businessData,
}) => {

  const scrollRef4 = useRef(null);
  
  return (
    <div>
      <div>
        
      <div className="create-invoice-page-end-hr"></div>

<div className="create-invoice-page-end-received">
  <div className="create-invoice-page-end-received-option">
    {data.fullyPaid ? (
      <div onClick={() => 
        setData({
          ...data,
          fullyPaid : false
      })
    }>
        Mark as fully paid
        <i class="fa-regular fa-square-check"></i>
      </div>
    ) : (
      <div onClick={() => 
        setData({
        ...data,
        fullyPaid : true
    })
    }>
        Mark as fully paid
        <i class="fa-regular fa-square"></i>
      </div>
    )}
  </div>

  <div className="create-invoice-page-end-received-row">
    Paid Amount
    <div className="create-invoice-page-end-received-col">
      <div className="create-invoice-page-end-received-input">
        ₹
        {data.fullyPaid ? (
          <input
            type="number"
            value={totalAmount}
            disabled
          />
        ) : (
          <input
            type="number"
            name="receivedAmount"
            value={data.receivedAmount}
            max={totalAmount}
            min={0}
            onChange={handleChange}
            ref={scrollRef4}
            onWheel={(e) =>
              handleWheelScroll(e, scrollRef4)
            }
          />
        )}
      </div>

      <select
        name="paymentType"
        onChange={handleChange}
        value={data.paymentType}
      >
        <option value="Cash">Cash</option>
        <option value="UPI">UPI</option>
        <option value="Card">Card</option>
        <option value="Netbanking">Netbanking</option>
        <option value="Bank Transfer">Bank Transfer</option>
        <option value="Cheque">Cheque</option>
      </select>
    </div>
  </div>

  {data.paymentType === "Bank Transfer" ||
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Cheque" ||
  data.paymentType === "Netbanking" ? (
    <div className="create-invoice-page-end-received-row">
      Payment Received in
      <select
        onChange={handleChange}
        name="selectedBankAccount"
        value={data.selectedBankAccount}
      >
        <option value="" selected disabled>
          Select
        </option>
        {businessData.bankData &&
          businessData.bankData
            .filter((e) => e.activateAccount === true)
            .map((e) => (
              <option value={e._id}>{e.bankName}</option>
            ))}
      </select>
    </div>
  ) : (
    ""
  )}
</div>

<div className="create-invoice-page-end-hr"></div>

<div className="create-invoice-page-end-balance">
  <div>Balance Amount</div>
  {data.fullyPaid ? (
    <div>0.0</div>
  ) : (
    <div>₹ {totalAmount - Number(data.receivedAmount)}</div>
  )}
</div>



      </div>

    </div>
  )
}

export default InvoicePaidAmountComponent