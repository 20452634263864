import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

// CSS ================================================================================================================
import './App.css';
import './app/css/pages/PageLayout.css'
import './app/css/pages/AppPage.css'
import './app/css/components/form/AppForm.css'
import './app/css/components/form/PopupForm.css'

import './app/css/components/form/invoice/CreateInvoice.css'

import './app/css/components/form/invoice/SaleInvoice.css'
import './app/css/components/form/invoice/SaleInvoiceOne.css'
import './app/css/components/form/invoice/SaleInvoiceTwo.css'
import './app/css/components/form/invoice/SelectForn.css'
import './app/css/components/AppNavbar.css'
import './app/css/components/SettingNavbar.css'
import './app/css/pages/authentication/UserAuthenticationForm.css'
import './app/css/pages/authentication/UserWelcomePage.css'
import './app/css/components/settings/AppBusinessSettingPageComponent.css'
import './app/css/components/settings/AppAccountSettingPageComponent.css'
import './app/css/components/main/AppPackageDurationComponent.css'
import './app/css/form/AddStaffForm.css'
import './app/css/page/AppHomePage.css'

import './app/css/pages/forms/CreateInvoicePage.css'
import './app/css/pages/forms/CreatePopupForm.css'
import './app/css/pages/forms/ViewDataComponent.css'
import './app/css/pages/settings/AppSettingInvoicePage.css'
import './app/css/pages/settings/AppSettingBusinessPage.css'
import './app/css/components/form/invoice/InvoiceExcelTheme.css'
import './app/css/components/form/invoice/InvoiceGstTheme.css'




import './css/components/PopUpAlertComponent.css'
import './css/components/TimerPopupAlertComponent.css'
import './css/extra/LoadingComponent.css'
import './app/css/pages/AppTransactionPage.css'
import './app/css/pages/AppDashboardPage.css'
import './app/css/pages/AppCashBankPage.css'
import './app/css/pages/AppStaffAttendancePayrollPage.css'
import './app/css/pages/AppExpensePage.css'

import './app/css/components/AppMobileNavbar.css'

import './app/css/pages/invoice/AppInvoicePage.css'
import './app/css/components/invoice/ViewInvoiceData.css'

// website css ===============================================================================================================

import './website/css/components/PricingPageComponent.css'
// import './website/css/components/WebNavbar.css'
import './website/css/components/GstCalculatorComponent.css'
import './website/css/components/main/HeaderComponent.css'
import './website/css/components/main/Navbar.css'
import './website/css/components/main/TopFeaturesComponent.css'
import './website/css/components/main/HowWeWorkComponent.css'
import './website/css/components/main/FeatureComponent.css'
import './website/css/components/main/StartInvoicingComponent.css'
import './website/css/components/main/FooterComponent.css'
import './website/css/components/main/NumberIncrement.css'
import './website/css/components/main/InvoiceTypesComponent.css'
import './website/css/components/main/AnytimeAnywhereComponent.css'



// import './others/css/Other.css'


// import './css/app/pages/PageLayout.css'
// import './css/app/component/AppNavbar.css'
// import './css/app/component/AppHomePageComponent.css'
// import './css/app/component/setting/AppSettingCompanyPageComponent.css'

// import './others/css/OtherAdmin.css'
// import './others/css/OtherLoading.css'



// import './css/extra/Classes.css'
// import './css/extra/LoadingComponent.css'
// import './css/app/pages/authentication/UserRegisterPage.css'
// import './css/app/component/SideNavbar.css'
// import './css/app/component/setting/SettingNavbar.css'
// import './css/app/component/setting/AppSettingGeneralPageComponent.css'
// import './css/app/form/FormComponent.css'

// app pages ===============================================================================================================

import UserRegisterPage from './app/pages/authentication/UserRegisterPage';
import UserLoginPage from './app/pages/authentication/UserLoginPage';
import UserForgotPasswordPage from './app/pages/authentication/UserForgotPasswordPage';
import UserWelcomePage from './app/pages/authentication/UserWelcomePage';
import AppConfiguration from './app/components/main/AppConfiguration';
// import AppHomePage from './app/pages/main/AppHomePage';

// import AppBusinessSettingPage from './app/pages/setting/AppBusinessSettingPage';
// import AppAccountSettingPage from './app/pages/setting/AppAccountSettingPage';


// import OtherPage from './others/pages/OtherPage';
import OtherAdminComponent from './others/component/OtherAdminComponent';


// website pages ===============================================================================================================
import HomePage from './website/HomePage';
import AppMain from './app/AppMain';
import AppSetting from './app/AppSetting';
import OtherPage from './others/pages/OtherPage';
import OtherProductList from './others/pages/OtherProductList';
import PricingPage from './website/PricingPage';
import GstCalculator from './website/pages/GstCalculator';
import ScrollToTop from './website/css/components/others/ScrollToTop';
import LoanEMICalculator from './website/pages/LoanEMICalculator';
import CreateSalesInvoicePage from './app/pages/form/CreateSalesInvoicePage';
import CreatePartyComponent from './app/pages/form/components/CreatePartyComponent';
// import AppPartiesPage from './app/pages/main/AppPartiesPage';
// import OtherApp from './others/pages/OtherApp';
// import AppMain from './app/AppMain';



// import AppSettingAccountPage from './app/pages/setting/AppSettingAccountPage';
// import AppSettingCompanyPage from './app/pages/setting/AppSettingCompanyPage';
// import UserDetailsPage from './app/pages/authentication/UserDetailsPage';
// import AppSettingGeneralPage from './app/pages/setting/AppSettingGeneralPage';


function App() {
  return (
    <div className="App">

<AuthProvider>



<BrowserRouter>

<ScrollToTop/>

      <Routes>

{/* Website pages =================================================================================================================================== */}
        <Route path='/' element={<HomePage/>} />
        <Route path='/pricing' element={<PricingPage/>} />
        <Route path='/gst-calculator' element={<GstCalculator/>} />
        <Route path='/business-loan-emi-calculator' element={<LoanEMICalculator/>} />




{/* App =================================================================================================================================== */}
        <Route path='/app/*' element={<AppMain/>} />
        <Route path='/app/setting/*' element={<AppSetting/>} />

        
        
        
        
{/* APP pages =================================================================================================================================== */}
        <Route path='/login' element={<UserLoginPage/>} />
        <Route path='/register' element={<UserRegisterPage/>} />
        <Route path='/update-password' element={<UserForgotPasswordPage/>} />
        <Route path='/app-configuration' element={<AppConfiguration/>} />
        <Route path='/welcome-user' element={<UserWelcomePage/>} />
        {/* <Route path='/app' element={<AppHomePage/>} />
        <Route path='/app/parties' element={<AppPartiesPage/>} /> */}



{/* APP setting =================================================================================================================================== */}
 
        {/* <Route path='/app/setting/business' element={<AppBusinessSettingPage/>} />
        <Route path='/app/setting/account' element={<AppAccountSettingPage/>} /> */}

        {/* <Route path='/app/setting' element={<AppSettingGeneralPage/>} /> */}



{/* APP others =================================================================================================================================== */}

        <Route path='/other' element={<OtherPage/>} />
        {/* <Route path='/other' element={<UserWelcomePage2 />} /> */}
        {/* <Route path='/other2' element={<OtherProductList/>} /> */}
        {/* <Route path='/other3' element={<CreatePartyComponent/>} /> */}

        
        <Route path='/other1' element={<CreateSalesInvoicePage/>} />



        <Route path='/bhumu-admin' element={<OtherAdminComponent/>} />

        {/* <Route path='/app/main/*' element={<OtherApp/>} /> */}



      </Routes>
</BrowserRouter>
     
      
</AuthProvider>
    </div>
  );
}

export default App;
