import React, { useState } from "react";

const SelectPartyComponent = ({
  handleKeyDown,
  partyListRef,
  businessData,
  setPartyData,
  setSelectParty,
  setCreateParty,
}) => {
  const [searchParty, setSearchParty] = useState("");

  return (
    <div>
      <div className="create-invoice-page-head-add-party" ref={partyListRef}>
        <div className="create-invoice-page-head-add-party-search">
          <input
            onKeyDown={handleKeyDown}
            type="text"
            placeholder="Search party by name"
            value={searchParty}
            onChange={(e) => {
              setSearchParty(e.target.value);
              setSearchParty("");
            }}
          />
        </div>
        <div className="create-invoice-page-head-add-party-head">
          <div>Party Name</div>
        </div>
        <div className="create-invoice-page-head-add-party-data">
          {businessData.partyData
            .filter((e) =>
              e.partyName
                .toLocaleLowerCase()
                .includes(searchParty.toLowerCase())
            )
            .reverse()
            .map((e) => (
              <div
                className="create-invoice-page-head-add-party-data-row"
                onClick={() => {
                  setPartyData(e);
                }}
              >
                <div>{e.partyName}</div>
              </div>
            ))}
        </div>

        {businessData.partyData.filter((e) =>
          e.partyName.toLocaleLowerCase().includes(searchParty.toLowerCase())
        ).length <= 0 ? (
          <div className="create-invoice-page-head-add-party-data-empty">
            No Party Found
          </div>
        ) : (
          ""
        )}

        <div className="create-invoice-page-head-add-party-create">
          <div
            onClick={() => {
              setCreateParty(true);
              setSelectParty(false);
            }}
          >
            + Create Party
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPartyComponent;
