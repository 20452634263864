import React from 'react'
import phonePe from "../../../../media/images/icons/phonepe.png"
import gpay from "../../../../media/images/icons/gpay.png"
import paytm from "../../../../media/images/icons/paytm.png"
import upi from "../../../../media/images/icons/upi.png"
import QRCode from "react-qr-code";

const ViewInvoiceUpiComponent = ({businessData, data}) => {
  return (
    <div>

{


  data.paymentType === "Bank Transfer" ||
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Cheque" ||
  data.paymentType === "Netbanking" ?

data.selectedBankAccount? (
              businessData.bankData&&businessData.bankData.filter( e => data.selectedBankAccount === e._id).map( e => (
                
              <div className="invoice-theme-1-end-col-1-bank-datails">
                
                <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                  Bank Details
                </div>

                <table className="invoice-theme-1-end-col-1-bank-datails-data">
                  <tr>
                    <td>Name:</td>
                    <td>{e.accountHolderName}</td>
                  </tr>
                  <tr>
                    <td>IFSC Code:</td>
                    <td>{e.IFSCcode}</td>
                  </tr>
                  <tr>
                    <td>Account No:</td>
                    <td>{e.accountNumber}</td>
                  </tr>
                  <tr>
                    <td>Bank:</td>
                    <td>{e.bankName}</td>
                  </tr>
                </table>         

{
  e.upiId?
       
                <div className="invoice-theme-1-end-col-1-bank-datails-upi">
                <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-1">
                <div className="invoice-theme-1-end-col-1-bank-datails-heading">
                  Payment Qr Code
                </div>
                  <b>UPI ID:</b>
                  <div>{e.upiId}</div>
                <div className="invoice-theme-1-end-col-1-bank-icons">
                  <img src={phonePe} alt="" />
                  <img src={gpay} alt="" />
                  <img src={paytm} alt="" />
                  <img src={upi} alt="" />

                </div>
                </div>
                <div className="invoice-theme-1-end-col-1-bank-datails-upi-col-2">
                <QRCode
        value={`upi://pay?pa=${e.upiId}`}
        size={70}
        level="M"
        // L,M,Q,H
      />
                  
                </div>
                
                </div>
                :""}
                
              </div>
              ))
            ) : 
              ""
              :""
            }
        
    </div>
  )
}

export default ViewInvoiceUpiComponent