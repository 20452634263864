import React, { useContext, useState } from "react";

import { AuthContext } from "../../../context/AuthContext";

const UpdateExpenseItemPopup = ({ itemData, itemId, open, setTimerPopupAlert, setTimerAlertMessage, setTimerAlertStatus, fetchBusinessData  }) => {

          
  // get from storage ============================================================================================
  
  
  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  

  
  //  ================================================================================================================

  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(4);
  };
  

    
    // useStates  ================================================================================================================

    const [loading, setLoading] = useState(false)
  
    const [purchasePrice, setPurchasePrice] = useState(
    
    
      itemData.purchasePriceType === "with-tax"
        ? itemData.itemTax === "0" ||
          itemData.itemTax === "0.1" ||
          itemData.itemTax === "0.25" ||
          itemData.itemTax === "1.5" ||
          itemData.itemTax === "3" ||
          itemData.itemTax === "5" ||
          itemData.itemTax === "6" ||
          itemData.itemTax === "12" ||
          itemData.itemTax === "13.8" ||
          itemData.itemTax === "18" ||
          itemData.itemTax === "28"
          ? formatNumber(
              itemData.itemPurchasePrice +
                (itemData.itemPurchasePrice * Number(itemData.itemTax)) / 100
            ) * 1
          : itemData.itemTax === "14,12"
          ? formatNumber(
              itemData.itemPurchasePrice +
                (itemData.itemPurchasePrice * 14) / 100 +
                (itemData.itemPurchasePrice * 12) / 100
            ) * 1
          : itemData.itemTax === "28,12"
          ? formatNumber(
              itemData.itemPurchasePrice +
                (itemData.itemPurchasePrice * 28) / 100 +
                (itemData.itemPurchasePrice * 12) / 100
            ) * 1
          : itemData.itemTax === "28,60"
          ? formatNumber(
              itemData.itemPurchasePrice +
                (itemData.itemPurchasePrice * 28) / 100 +
                (itemData.itemPurchasePrice * 60) / 100
            ) * 1
          : itemData.itemPurchasePrice
        : itemData.itemPurchasePrice
    
      
    );

  // console.log("item data is :", itemData)
  
  const [data, setData] = useState({
    
    itemName: itemData.itemName ? itemData.itemName : "",
    itemType: itemData.itemType ? itemData.itemType : "Product",
    itemPurchasePrice: itemData.itemPurchasePrice ? itemData.itemPurchasePrice : 0,
    purchasePriceType: itemData.purchasePriceType ? itemData.purchasePriceType : "with-tax",
    itemMeasuringUnit: itemData.itemMeasuringUnit ? itemData.itemMeasuringUnit : "PCS",
    itemHsnCode: itemData.itemHsnCode ? itemData.itemHsnCode : "",
    itemTax: itemData.itemTax ? itemData.itemTax : "0",
    itcApplicable : itemData.itcApplicable ? itemData.itcApplicable : "Eligible",

  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };


  const saveData = () => {

    console.log("data is : ", data)
    
  };

  // ----------------------------------------------------------------------------------------------------------

  
  const handlePurchaseTaxType = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      purchasePriceType: value,
    });

    if (value === "without-tax") {
      setData({
        ...data,
        itemPurchasePrice: purchasePrice,
        purchasePriceType: value,
      });
    } else if (value === "with-tax") {
      if (
        data.itemTax === "0" ||
        data.itemTax === "0.1" ||
        data.itemTax === "0.25" ||
        data.itemTax === "1.5" ||
        data.itemTax === "3" ||
        data.itemTax === "5" ||
        data.itemTax === "6" ||
        data.itemTax === "12" ||
        data.itemTax === "13.8" ||
        data.itemTax === "18" ||
        data.itemTax === "28"
      ) {
        const newVal = purchasePrice / (1 + Number(data.itemTax) / 100);
        setData({
          ...data,
          itemPurchasePrice: formatNumber(newVal),
          purchasePriceType: value,
        });
      } else if (
        data.itemTax === "14,12" ||
        data.itemTax === "28,12" ||
        data.itemTax === "28,60"
      ) {
        if (data.itemTax === "14,12") {
          const newVal = purchasePrice / (1 + 14 / 100 + 12 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
            purchasePriceType: value,
          });
        } else if (data.itemTax === "28,12") {
          const newVal = purchasePrice / (1 + 28 / 100 + 12 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
            purchasePriceType: value,
          });
        } else if (data.itemTax === "28,60") {
          const newVal = purchasePrice / (1 + 28 / 100 + 60 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
            purchasePriceType: value,
          });
        }
      }
    }
  };

  // ----------------------------------------------------------------------------------------------------------

  const handleTaxChange = (e) => {
    const purchaseValue = Number(purchasePrice);

    const value = e.target.value;

    setData({
      ...data,
      itemTax: value,
    });

    if (
      data.purchasePriceType === "with-tax"
    ) {
      if (
        value === "0" ||
        value === "0.1" ||
        value === "0.25" ||
        value === "1.5" ||
        value === "3" ||
        value === "5" ||
        value === "6" ||
        value === "12" ||
        value === "13.8" ||
        value === "18" ||
        value === "28"
      ) {
        const newPurchaseVal = purchaseValue / (1 + Number(value) / 100);
        setData({
          ...data,
          itemTax: value,
          itemPurchasePrice: formatNumber(newPurchaseVal),
        });
      } else if (value === "14,12" || value === "28,12" || value === "28,60") {
        if (value === "14,12") {
          const newPurchaseVal = purchaseValue / (1 + 14 / 100 + 12 / 100);

          setData({
            ...data,
            itemTax: value,
            itemPurchasePrice: formatNumber(newPurchaseVal),
          });
        } else if (value === "28,12") {
          const newPurchaseVal = purchaseValue / (1 + 28 / 100 + 12 / 100);

          setData({
            ...data,
            itemTax: value,
            itemPurchasePrice: formatNumber(newPurchaseVal),
          });
        } else if (value === "28,60") {
          const newPurchaseVal = purchaseValue / (1 + 28 / 100 + 60 / 100);

          setData({
            ...data,
            itemTax: value,
            itemPurchasePrice: formatNumber(newPurchaseVal),
          });
        }
      }
    } 
    
    
    
    else if( data.purchasePriceType === "with-tax" ){
      if (
        value === "0" ||
        value === "0.1" ||
        value === "0.25" ||
        value === "1.5" ||
        value === "3" ||
        value === "5" ||
        value === "6" ||
        value === "12" ||
        value === "13.8" ||
        value === "18" ||
        value === "28"
      ) {
        const newVal = purchasePrice / (1 + Number(value) / 100);
        setData({
          ...data,
          itemPurchasePrice: formatNumber(newVal),
        });
      } else if (
        value === "14,12" ||
        value === "28,12" ||
        value === "28,60"
      ) {
        if (value === "14,12") {
          const newVal = purchasePrice / (1 + 14 / 100 + 12 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
          });
        } else if (value === "28,12") {
          const newVal = purchasePrice / (1 + 28 / 100 + 12 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
          });
        } else if (value === "28,60") {
          const newVal = purchasePrice / (1 + 28 / 100 + 60 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
          });
        }
      }
    } 
    


    
    
  };

  // ----------------------------------------------------------------------------------------------------------


  // ----------------------------------------------------------------------------------------------------------

  const handlePurchasePrice = (e) => {
    const value = e.target.value;

    setPurchasePrice(value);

    if (data.purchasePriceType === "with-tax") {
      if (
        data.itemTax === "0" ||
        data.itemTax === "0.1" ||
        data.itemTax === "0.25" ||
        data.itemTax === "1.5" ||
        data.itemTax === "3" ||
        data.itemTax === "5" ||
        data.itemTax === "6" ||
        data.itemTax === "12" ||
        data.itemTax === "13.8" ||
        data.itemTax === "18" ||
        data.itemTax === "28"
      ) {
        const newVal = value / (1 + Number(data.itemTax) / 100);
        setData({
          ...data,
          itemPurchasePrice: formatNumber(newVal),
        });
      } else if (
        data.itemTax === "14,12" ||
        data.itemTax === "28,12" ||
        data.itemTax === "28,60"
      ) {
        if (data.itemTax === "14,12") {
          const newVal = value / (1 + 14 / 100 + 12 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
          });
        } else if (data.itemTax === "28,12") {
          const newVal = value / (1 + 28 / 100 + 12 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
          });
        } else if (data.itemTax === "28,60") {
          const newVal = value / (1 + 28 / 100 + 60 / 100);

          setData({
            ...data,
            itemPurchasePrice: formatNumber(newVal),
          });
        }
      }
    } else if (data.purchasePriceType === "without-tax") {
      setData({
        ...data,
        itemPurchasePrice: value,
      });
    }
  };


  // =================================================================================================
  
  
const { updateExpenseItem } = useContext(AuthContext);

const handleCreate = async (e) => {  
  setLoading(true);
  e.preventDefault();
  await updateExpenseItem(userId, businessId, itemId, data, setTimerPopupAlert, setTimerAlertMessage, setTimerAlertStatus, open);
  setLoading(false);
  fetchBusinessData&&fetchBusinessData()

};
  
  

  return (
    // <form >
    <form onSubmit={ handleCreate }>
    <div className="create-item-popup">
      <div className="create-expense-item-popup-main">
        <div className="create-item-popup-top">
          <div>Update Expense Item</div>
          <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
        </div>


          {/* ----------------------------------------------------------------------------------------- */}
          <div className="create-item-popup-form">

          <div className="create-item-popup-form-basic">
                <div className="create-item-popup-form-row">

                  
                <div className="create-item-popup-form-col">
                  <label>
                    Item Name
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    onChange={handleChange}
                    name="itemName"
                    value={data.itemName}
                    placeholder=""
                  />
                </div>
                  
                  
                  <div className="create-item-popup-form-col">
                    <label>
                      Item Type <span>*</span>
                    </label>
                    <div className="create-item-popup-form-row">
                      <div
                        onClick={() =>
                          setData({ ...data, itemType: "Product" })
                        }
                        className="create-item-popup-form-col-type"
                      >
                        <span>Product</span>
                        <input
                          required
                          checked={data.itemType === "Product" ? true : false}
                          onChange={handleChange}
                          value="Product"
                          type="radio"
                          name="itemType"
                        />
                      </div>

                      <div
                        onClick={() =>
                          setData({ ...data, itemType: "Service" })
                        }
                        className="create-item-popup-form-col-type"
                      >
                        <span>Service</span>
                        <input
                          required
                          checked={data.itemType === "Service" ? true : false}
                          onChange={handleChange}
                          value="Service"
                          type="radio"
                          name="itemType"
                        />
                      </div>
                    </div>
                  </div>

               
               
                  
                 
                  <div className="create-item-popup-form-col">
                    <label>
                      Purchase Price 
                      {/* {data.itemPurchasePrice} - {data.purchasePriceType} */}
                    </label>
                    <div className="create-item-popup-form-col-sale">
                      <span>₹</span>
                      <input
                      required
                        type="number"
                        onChange={handlePurchasePrice}
                        value={purchasePrice}
                        min={0}
                        placeholder=""
                      />
                      <select
                        onChange={handlePurchaseTaxType}
                        value={data.purchasePriceType}
                      >
                        <option value="with-tax">With Tax</option>
                        <option value="without-tax">Without Tax</option>
                      </select>
                    </div>
                  </div>

                  
                  <div className="create-item-popup-form-col">
                    <label>Measuring Unit</label>

                    <select
                      onChange={handleChange}
                      name="itemMeasuringUnit"
                      value={data.itemMeasuringUnit}
                    >
                      <option value="PCS"> Pieces(PCS)</option>
                      <option value="LAD"> Ladi(LAD)</option>
                      <option value="PET"> Peti(PET)</option>
                      <option value="JAR"> Jars(JAR)</option>
                      <option value="HEGAR"> Hanger(HEGAR)</option>
                      <option value="POCH"> Pouch(POCH)</option>
                      <option value="BOR"> Bora(BOR)</option>
                      <option value="COIL"> Coil(COIL)</option>
                      <option value="FT"> Feet(FT)</option>
                      <option value="IN"> Inches(IN)</option>
                      <option value="PRT"> Portion(PRT)</option>
                      <option value="CASE"> Case(CASE)</option>
                      <option value="EACH"> Each(EACH)</option>
                      <option value="CPS"> Capsules(CPS)</option>
                      <option value="PADS"> Pads(PADS)</option>
                      <option value="REEL"> Reel(REEL)</option>
                      <option value="BLISTER"> Blister(BLISTER)</option>
                      <option value="PAD"> Pad(PAD)</option>
                      <option value="PRS"> Pairs(PRS)</option>
                      <option value="QTL"> Quintal(QTL)</option>
                      <option value="ROL"> Rolls(ROL)</option>
                      <option value="SET"> Sets(SET)</option>
                      <option value="SQF"> Square Feet(SQF)</option>
                      <option value="SQM"> Square Meters(SQM)</option>
                      <option value="SQY"> Square Yards(SQY)</option>
                      <option value="TBS"> Tablets(TBS)</option>
                      <option value="TGM"> Ten Gross(TGM)</option>
                      <option value="THD"> Thousands(THD)</option>
                      <option value="TON"> Tonnes(TON)</option>
                      <option value="TUB"> Tubes(TUB)</option>
                      <option value="UGS"> Us Gallons(UGS)</option>
                      <option value="UNT"> Units(UNT)</option>
                      <option value="YDS"> Yards(YDS)</option>
                      <option value="OTH"> Others(OTH)</option>
                      <option value="HRS"> Hours(HRS)</option>
                      <option value="MINS"> Minutes(MINS)</option>
                      <option value="LTR"> Litre(LTR)</option>
                      <option value="MTON"> Metric Ton(MTON)</option>
                      <option value="MLG"> Milligram(MLG)</option>
                      <option value="BCK"> Buckets(BCK)</option>
                      <option value="GLS"> Glasses(GLS)</option>
                      <option value="PLT"> Plates(PLT)</option>
                      <option value="CTS"> Carats(CTS)</option>
                      <option value="STRP"> Strips(STRP)</option>
                      <option value="CFT"> Cubic Foot(CFT)</option>
                      <option value="VIAL"> Vials(VIAL)</option>
                      <option value="BAG"> Bags(BAG)</option>
                      <option value="BAL"> Bale(BAL)</option>
                      <option value="BDL"> Bundles(BDL)</option>
                      <option value="BKL"> Buckles(BKL)</option>
                      <option value="BOU"> Billions Of Units(BOU)</option>
                      <option value="BOX"> Box(BOX)</option>
                      <option value="BTL"> Bottles(BTL)</option>
                      <option value="BUN"> Bunches(BUN)</option>
                      <option value="CAN"> Cans(CAN)</option>
                      <option value="CBM"> Cubic Meter(CBM)</option>
                      <option value="CCM"> Cubic Centimeter(CCM)</option>
                      <option value="CMS"> Centimeter(CMS)</option>
                      <option value="CTN"> Cartons(CTN)</option>
                      <option value="DOZ"> Dozen(DOZ)</option>
                      <option value="DRM"> Drum(DRM)</option>
                      <option value="GGR"> Great Gross(GGR)</option>
                      <option value="GMS"> Grams(GMS)</option>
                      <option value="GRS"> Gross(GRS)</option>
                      <option value="GYD"> Gross Yards(GYD)</option>
                      <option value="KGS"> Kilograms(KGS)</option>
                      <option value="KLR"> Kiloliter(KLR)</option>
                      <option value="KME"> Kilometre(KME)</option>
                      <option value="MLT"> Millilitre(MLT)</option>
                      <option value="MTR"> Meters(MTR)</option>
                      <option value="NOS"> Numbers(NOS)</option>
                      <option value="PAC"> Packs(PAC)</option>
                      <option value="CNT"> Cents(CNT)</option>
                      <option value="RFT"> Running Foot(RFT)</option>
                      <option value="RIM"> Rim(RIM)</option>
                      <option value="TRIP"> Trp(TRIP)</option>
                      <option value="TIN"> Tin(TIN)</option>
                      <option value="CHUDI"> Chudi(CHUDI)</option>
                      <option value="PATTA"> Patta(PATTA)</option>
                      <option value="KIT"> Kit(KIT)</option>
                      <option value="SLEVES"> Sleves(SLEVES)</option>
                      <option value="CUFT"> Cubic Feet(CUFT)</option>
                      <option value="RMT"> Running Meter(RMT)</option>
                      <option value="TRY"> Trays(TRY)</option>
                      <option value="AMP"> Ampoule(AMP)</option>
                      <option value="PAIR"> Pair(PAIR)</option>
                      <option value="ANA"> Aana(ANA)</option>
                      <option value="CFM"> Cubic Feet Per Minute(CFM)</option>
                      <option value="YRS"> Years(YRS)</option>
                      <option value="UOM"> Month (UOM)</option>
                      <option value="FT2"> Feet Square(FT2)</option>
                      <option value="MAN-DAY"> Man-days(MAN-DAY)</option>
                      <option value="NIGHT"> Nights(NIGHT)</option>
                      <option value="DAY"> Days(DAY)</option>
                      <option value="SQIN"> Square Inches(SQIN)</option>
                      <option value="WEEK"> Weeks(WEEK)</option>
                      <option value="PERSON"> Persons(PERSON)</option>
                      <option value="DEZ"> Daze(DEZ)</option>
                      <option value="LOT"> Lot(LOT)</option>
                      <option value="EA"> Ea(EA)</option>
                      <option value="SAC"> Sachet(SAC)</option>
                      <option value="REAM"> Ream(REAM)</option>
                      <option value="SEC"> Seconds(SEC)</option>
                      <option value="BRASS"> Brass(BRASS)</option>
                      <option value="ROOM"> Room(ROOM)</option>
                      <option value="NONE"> None(NONE)</option>
                      <option value="CT"> Carat(CT)</option>
                      <option value="COPY"> Copy(COPY)</option>
                      <option value="CRM"> Cream(CRM)</option>
                      <option value="RS"> Rupees(RS)</option>
                      <option value="SEM"> Semester(SEM)</option>
                      <option value="COURSE"> Course(COURSE)</option>
                      <option value="JL"> Jhal(JL)</option>
                      <option value="TKT"> Ticket(TKT)</option>
                      <option value="KW"> Kilowatt(KW)</option>
                      <option value="JO"> Jhola(JO)</option>
                      <option value="W"> Watt(W)</option>
                      <option value="MBPS"> Mbps(MBPS)</option>
                      <option value="JHL"> Jhola Jhal(JHL)</option>
                      <option value="AC"> Acre(AC)</option>
                      <option value="HA"> Hectare(HA)</option>
                      <option value="CHAIN"> Ch(CHAIN)</option>
                      <option value="BOLUS"> Bolus(BOLUS)</option>
                      <option value="PAGE"> Pages(PAGE)</option>
                      <option value="G NEX"> Aaaaaaaaaaa(G NEX)</option>
                      <option value="KVA"> Kilovolt-amp(KVA)</option>
                      <option value="PT"> Point(PT)</option>
                      <option value="MRK"> Mark(MRK)</option>
                      <option value="STONE"> Stone(STONE)</option>
                      <option value="BRICK"> Brick(BRICK )</option>
                      <option value="PKT"> Packets(PKT)</option>
                      <option value="BAR"> Barni(BAR)</option>
                      <option value="SHEET"> Sheet(SHEET)</option>
                      <option value="ITEM ID"> Item Id(ITEM ID)</option>
                      <option value="MM"> Millimeter(MM)</option>
                      <option value="CONT"> Container(CONT)</option>
                      <option value="SQCM"> Square Centimeters(SQCM)</option>
                      <option value="INS"> Insertion(INS)</option>
                      <option value="LBS"> Libra Pondo(LBS)</option>
                      <option value="LENG"> Length(LENG)</option>
                      <option value="GEL"> Gel(GEL)</option>
                      <option value="SYRP"> Syrup(SYRP)</option>
                      <option value="SSN"> Session(SSN)</option>
                      <option value="KT"> Katta(KT)</option>
                      <option value="OR"> Outer(OR)</option>
                      <option value="QTY"> Quantity(QTY)</option>
                      <option value="CARD"> Card(CARD)</option>
                      <option value="BALL"> Balls(BALL)</option>
                      <option value="M/C"> Millicoulomb(M/C)</option>
                      <option value="TEST"> Test(TEST)</option>
                      <option value="CAP"> Cap(CAP)</option>
                      <option value="BK"> Book(BK)</option>
                      <option value="CHOKA"> Choka(CHOKA)</option>
                      <option value="DANGLER"> Dangler(DANGLER)</option>
                      <option value="STICKER"> Stickers(STICKER)</option>
                      <option value="GAZ"> Square Gaz(GAZ)</option>
                      <option value="FTS"> Fts(FTS)</option>
                      <option value="CUP"> Cup(CUP)</option>
                      <option value="SERVICE"> Service(SERVICE)</option>
                      <option value="SKINS"> Skins(SKINS)</option>
                      <option value="PHILE"> Phile(PHILE)</option>
                      <option value="HMT"> Helmet(HMT)</option>
                      <option value="RTI"> Ratti(RTI)</option>
                      <option value="FREE"> Free(FREE)</option>
                      <option value="JOB"> Job(JOB)</option>
                      <option value="FIT"> Fit(FIT)</option>
                      <option value="SPRAY"> Spray(SPRAY)</option>
                      <option value="OINT"> Ointment(OINT)</option>
                      <option value="DROP"> Drop(DROP)</option>
                      <option value="DAILY"> Daily(DAILY)</option>
                      <option value="INJECTION"> Ijn(INJECTION)</option>
                      <option value="INJECTION)"> Inj(INJECTION )</option>
                      <option value="TRK"> Truck(TRK)</option>
                      <option value="ΜG"> Microgram(ΜG)</option>
                      <option value="CRT"> Crate(CRT)</option>
                      <option value="QUAD"> Quad(QUAD)</option>
                      <option value="/DAY"> Per Day(/DAY)</option>
                      <option value="LS)"> Lump Sum (LS )</option>
                      <option value="GAJ"> Square Gaj(GAJ)</option>
                      <option value="CV"> Cv(CV)</option>
                      <option value="PWP"> Per Watt Peak(PWP)</option>
                    </select>
                  </div>

                  
                  <div className="create-item-popup-form-col">
                    <label>{data.itemType === "Product" ? "HSN" : "SAC"}</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="itemHsnCode"
                      value={data.itemHsnCode}
                      placeholder=""
                    />
                  </div>


                  <div className="create-item-popup-form-col">
                    <label>GST Tax Rate(%)</label>
                    <select
                      onChange={handleTaxChange}
                      value={data.itemTax}
                      name="itemTax"
                      id=""
                    >
                      <option value="0"> None</option>
                      <option value="0.1"> GST @ 0.1%</option>
                      <option value="0.25"> GST @ 0.25%</option>
                      <option value="1.5"> GST @ 1.5%</option>
                      <option value="3"> GST @ 3%</option>
                      <option value="5"> GST @ 5%</option>
                      <option value="6"> GST @ 6%</option>
                      <option value="12"> GST @ 12%</option>
                      <option value="13.8"> GST @ 13.8%</option>
                      <option value="14,12"> GST @ 14% + cess @ 12%</option>
                      <option value="18"> GST @ 18%</option>
                      <option value="28"> GST @ 28%</option>
                      <option value="28,12"> GST @ 28% + Cess @ 12%</option>
                      <option value="28,60"> GST @ 28% + Cess @ 60%</option>
                    </select>
                  </div>
                  
                  
                  <div className="create-item-popup-form-col">
                    <label>ITC Applicable</label>
                    <select
                      onChange={handleChange}
                      value={data.itcApplicable}
                      name="itcApplicable"
                    >
                      <option value="Eligible">Eligible</option>
                      <option value="Ineligible">Ineligible</option>
                      <option value="Ineligible Others">Ineligible Others</option>
                    </select>
                  </div>
                  
                </div>
              </div>
            
          </div>
        {/* ----------------------------------------------------------------------------------------- */}

        <div className="create-item-popup-buttons">
          <div className="create-item-popup-button" onClick={() => open(false)}>
            Cancel
          </div>
          {data.itemType && data.itemName  ? (
            <button className="create-item-popup-button">Save</button>
          ) : (
            <div className="create-item-popup-button" onClick={saveData}>
              Save
            </div>
          )}
        </div>
      </div>
    </div>
    </form>
  );
};

export default UpdateExpenseItemPopup;
