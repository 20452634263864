import React, { useEffect, useRef, useState } from "react";

const InvoiceAddressComponent = ({
  partyData,
  setPartyData,
  businessData,
  handleKeyDown,
  setCreateParty,
}) => {
  const [selectParty, setSelectParty] = useState(false);
  const [searchParty, setSearchParty] = useState("");

  // handle outside click ====================================================================================================================

  const selectPartyRef = useRef(null);
  const partyListRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      partyListRef.current &&
      !partyListRef.current.contains(event.target) &&
      selectPartyRef.current &&
      !selectPartyRef.current.contains(event.target)
    ) {
      setSelectParty(false);
      setSearchParty("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  

  return (
    <>
      

      <div className="create-invoice-page-head-address">
        <div className="create-invoice-page-head-billing-address">
          <div className="create-invoice-page-head-address-head">
            <span>Bill To</span>
            {partyData ? (
              <div onClick={() => setPartyData("")}>Change Party</div>
            ) : (
              ""
            )}
          </div>

          {partyData ? (
            <div className="create-invoice-page-head-address-content">
              {partyData.partyName ? (
                <div className="create-invoice-page-head-address-name">
                  {partyData.partyName}
                </div>
              ) : (
                ""
              )}

              {partyData.partyBillingAddress ||
              partyData.partyBillingCity ||
              partyData.partyBillingState ||
              partyData.partyBillingPinCode ? (
                <div className="create-invoice-page-head-address-col">
                  <span>Address</span>
                  <div>
                    {partyData.partyBillingAddress}
                    {partyData.partyBillingCity ? (
                      <>, {partyData.partyBillingCity}</>
                    ) : (
                      ""
                    )}
                    {partyData.partyBillingState ? (
                      <>, {partyData.partyBillingState}</>
                    ) : (
                      ""
                    )}
                    {partyData.partyBillingPinCode ? (
                      <> - {partyData.partyBillingPinCode}</>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {partyData.partyNumber ? (
                <div className="create-invoice-page-head-address-col">
                  <span>Phone Number</span>
                  <div>{partyData.partyNumber}</div>
                </div>
              ) : (
                ""
              )}

              {partyData.partyPanNumber ? (
                <div className="create-invoice-page-head-address-col">
                  <span>PAN Number</span>
                  <div>{partyData.partyPanNumber}</div>
                </div>
              ) : (
                ""
              )}

              {partyData.partyGSTIN ? (
                <div className="create-invoice-page-head-address-col">
                  <span>GSTIN</span>
                  <div>{partyData.partyGSTIN}</div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <>
              {selectParty ? (
                <div
                  className="create-invoice-page-head-add-party"
                  ref={partyListRef}
                >
                  <div className="create-invoice-page-head-add-party-search">
                    <input
                      onKeyDown={handleKeyDown}
                      type="text"
                      placeholder="Search party by name"
                      value={searchParty}
                      onChange={(e) => setSearchParty(e.target.value)}
                    />
                  </div>
                  <div className="create-invoice-page-head-add-party-head">
                    <div>Party Name</div>
                  </div>
                  <div className="create-invoice-page-head-add-party-data">
                    {businessData.partyData
                      .filter((e) =>
                        e.partyName
                          .toLocaleLowerCase()
                          .includes(searchParty.toLowerCase())
                      )
                      .reverse()
                      .map((e) => (
                        <div
                          className="create-invoice-page-head-add-party-data-row"
                          onClick={() => {
                            setPartyData(e);
                          }}
                        >
                          <div>{e.partyName}</div>
                        </div>
                      ))}
                  </div>

                  {businessData.partyData.filter((e) =>
                    e.partyName
                      .toLocaleLowerCase()
                      .includes(searchParty.toLowerCase())
                  ).length <= 0 ? (
                    <div className="create-invoice-page-head-add-party-data-empty">
                      No Party Found
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="create-invoice-page-head-add-party-create">
                    <div
                      onClick={() => {
                        setCreateParty(true);
                        setSelectParty(false);
                      }}
                    >
                      + Create Party
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                ref={selectPartyRef}
                className="create-invoice-page-head-add-party-option"
                onClick={() => setSelectParty(true)}
              >
                + Add Party
              </div>
            </>
          )}
        </div>

        {partyData ? (
          <>
            {partyData.partyBothAddressSame ? (
              <>
                <div className="create-invoice-page-head-shipping-address">
                  <div className="create-invoice-page-head-address-head">
                    <span>Shipping To</span>
                  </div>
                  <div className="create-invoice-page-head-address-content">
                    {partyData.partyName ? (
                      <div className="create-invoice-page-head-address-name">
                        {partyData.partyName}
                      </div>
                    ) : (
                      ""
                    )}

                    {partyData.partyBillingAddress ||
                    partyData.partyBillingCity ||
                    partyData.partyBillingState ||
                    partyData.partyBillingPinCode ? (
                      <div className="create-invoice-page-head-address-col">
                        <span>Address</span>
                        <div>
                          {partyData.partyBillingAddress}
                          {partyData.partyBillingCity ? (
                            <>, {partyData.partyBillingCity}</>
                          ) : (
                            ""
                          )}
                          {partyData.partyBillingState ? (
                            <>, {partyData.partyBillingState}</>
                          ) : (
                            ""
                          )}
                          {partyData.partyBillingPinCode ? (
                            <> - {partyData.partyBillingPinCode}</>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {partyData.partyNumber ? (
                      <div className="create-invoice-page-head-address-col">
                        <span>Phone Number</span>
                        <div>{partyData.partyNumber}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {partyData.partyShippingAddress ||
                partyData.partyShippingCity ||
                partyData.partyShippingState ||
                partyData.partyShippingPinCode ? (
                  <div className="create-invoice-page-head-shipping-address">
                    <div className="create-invoice-page-head-address-head">
                      <span>Shipping To</span>
                    </div>
                    <div className="create-invoice-page-head-address-content">
                      <div className="create-invoice-page-head-address-name">
                        {partyData.partyName}
                      </div>

                      <div className="create-invoice-page-head-address-col">
                        <span>Address</span>
                        <div>
                          {partyData.partyShippingAddress
                            ? partyData.partyShippingAddress
                            : ""}

                          {partyData.partyShippingCity ? (
                            <>, {partyData.partyShippingCity}</>
                          ) : (
                            ""
                          )}

                          {partyData.partyShippingState ? (
                            <>, {partyData.partyShippingState}</>
                          ) : (
                            ""
                          )}

                          {partyData.partyShippingPinCode ? (
                            <>, {partyData.partyShippingPinCode}</>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {partyData.partyNumber ? (
                        <div className="create-invoice-page-head-address-col">
                          <span>Phone Number</span>
                          <div>{partyData.partyNumber}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default InvoiceAddressComponent;
