import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
// import LoadingComponent from "../../../../components/extra/LoadingComponent";
import { Link, useNavigate } from "react-router-dom";
import CreatePartyPopup from "./../CreatePartyPopup";
import TimerPopupAlertComponent from "../../../../components/extra/TimerPopupAlertComponent";
import CreateItemPopup from "./../CreateItemPopup";
import SelectPartyComponent from "./createInvoice/SelectPartyComponent";
import ShowAddressComponent from "./createInvoice/ShowAddressComponent";

const CreateSalesInoiceComponent = ({ businessData, fetchBusinessData }) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [viewItemDetails, setViewItemDetails] = useState(false);

  const [loading, setLoading] = useState(false);

  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

 
  const [partyData, setPartyData] = useState();

  const [createParty, setCreateParty] = useState("");

  const [selectItem, setSelectItem] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchItem, setSearchItem] = useState("");

  const [createItem, setCreateItem] = useState(false);

  const [AddNotes, setAddNotes] = useState(false);
  const [AddTerms, setAddTerms] = useState(false);

  const [AddExtraDiscount, setAddExtraDiscount] = useState(false);

  const [roundOff, setRoundOff] = useState(false);
  const [fullyPaid, setFullyPaid] = useState(false);

  const [editingDueDate, setEditingDueDate] = useState(null);

  const navigate = useNavigate();

  const today = new Date();

  const dueDate = new Date(today);
  dueDate.setDate(today.getDate() + 30);

  const [data, setData] = useState({
    invoiceNumber: businessData.invoiceSettingData
      ? businessData.invoiceSettingData.newSalesInvoiceNumber
      : 1,
    invoiceDate: new Date().toISOString().split("T")[0],
    invoiceEnableDueDate: false,
    invoiceDueDate: dueDate.toISOString().split("T")[0],
    invoicePartyData: "",
    invoiceItemsData: "",
    invoiceExtraDiscount: 0,
    roundOff: false,
    fullyPaid: false,
    receivedAmount: 0,
    paymentType: "Cash",
    invoiceNotes: "",
    invoiceTermsConditions: businessData.businessTermsConditions
      ? businessData.businessTermsConditions
      : "",
    invoiceSubTotalAmount: "",
    invoiceTotalAmount: "",
    selectedBankAccount: "",

    // invoiceDetailsOptions: businessData.invoiceSettingData && businessData.invoiceSettingData.invoiceDetailsOptions ?businessData.invoiceSettingData.invoiceDetailsOptions:[],
    invoiceDetailsOptions: "",

    invoicePoNo: "",
    invoiceVehicleNo: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  // --------------------------------------

  const handleDueDate = (e) => {
    const value = e.target.value;

    const invDate = new Date(data.invoiceDate);
    const newDate = new Date(invDate);
    newDate.setDate(invDate.getDate() + Number(value));

    setData({
      ...data,
      invoiceDueDate: newDate.toISOString().split("T")[0],
    });
  };

  // ====================================================================================================================

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ====================================================================================================================
  // ====================================================================================================================

  // ====================================================================================================================


  const selectTaxRef = useRef(null);
  const taxListRef = useRef(null);

  const handleTaxOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      taxListRef.current &&
      !taxListRef.current.contains(event.target) &&
      selectTaxRef.current &&
      !selectTaxRef.current.contains(event.target)
    ) {
      setEditingTax(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTaxOutside);

    return () => {
      document.removeEventListener("mousedown", handleTaxOutside);
    };
  }, []);

  // ====================================================================================================================

  const inputSelectRef1 = useRef(null);
  const inputSelectRef2 = useRef(null);

  const handleSelectFocus = (ref) => {
    // if (inputSelectRef.current) {
    //   inputSelectRef.current.select();
    // }

    if (ref.current) {
      ref.current.select();
    }
  };

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);

  const handleFocus = (ref) => {
    if (ref.current) {
      ref.current.select();
    }
  };

  // ====================================================================================================================

  const scrollRef1 = useRef(null); // Create a ref for the input
  const scrollRef2 = useRef(null); // Create a ref for the input
  const scrollRef3 = useRef(null); // Create a ref for the input
  const scrollRef4 = useRef(null); // Create a ref for the input
  const scrollRef5 = useRef(null); // Create a ref for the input

  const handleWheelScroll = (event, ref) => {
    event.preventDefault(); // Prevent the scroll wheel from changing the input value
    if (ref.current) {
      ref.current.blur(); // Remove focus from the input
    }
  };

  // ====================================================================================================================

  const handleDescriptionChange = (id, value) => {
    const updatedDescription = selectedItems.map((item) =>
      item._id === id ? { ...item, itemDescription: value } : item
    );
    setSelectedItems(updatedDescription);
  };

  const handleAddItem = (item) => {
    if (!selectedItems.some((p) => p._id === item._id)) {
      setSelectedItems([
        ...selectedItems,
        {
          ...item,
          quantity: 1,
          discount: 0,
          itemGst:
            item.itemTax === "0"
              ? 0
              : item.itemTax === "0.1"
              ? 0.1
              : item.itemTax === "0.25"
              ? 0.25
              : item.itemTax === "1.5"
              ? 1.5
              : item.itemTax === "3"
              ? 3
              : item.itemTax === "5"
              ? 5
              : item.itemTax === "6"
              ? 6
              : item.itemTax === "12"
              ? 12
              : item.itemTax === "13.8"
              ? 13.8
              : item.itemTax === "14,12"
              ? 14
              : item.itemTax === "18"
              ? 18
              : item.itemTax === "28"
              ? 28
              : item.itemTax === "28,12"
              ? 28
              : item.itemTax === "28,60"
              ? 28
              : 0,
          cess:
            item.itemTax === "14,12"
              ? 12
              : item.itemTax === "28,12"
              ? 12
              : item.itemTax === "28,60"
              ? 60
              : 0,
        },
      ]);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p._id !== itemId));
  };

  const handleIncreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity + 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleDecreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity - 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleQuantityChange = (id, value) => {
    if ((value >= 1 && value <= 10000) || value === "") {
      if (value.trim() === "") {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id ? { ...item, quantity: 0 } : item
        );
        setSelectedItems(updatedQuantity);
      } else {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id
            ? { ...item, quantity: value.replace(/^0+/, "") }
            : item
        );
        setSelectedItems(updatedQuantity);
      }
    }
  };

  const handlePriceChange = (id, value) => {
    const price = parseFloat(value);
    if (value.trim() === "") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: 0 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: price } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleDiscountPercentChange = (id, value) => {
    // const val = parseFloat(value);

    if ((value >= 0 && value <= 100) || value === "") {
      if (value.trim() === "") {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id ? { ...item, discount: 0 } : item
        );
        setSelectedItems(updatedDiscount);
      } else {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id
            ? { ...item, discount: parseFloat(value.replace(/^0+/, "")) }
            : item
        );
        setSelectedItems(updatedDiscount);
      }
    }
  };

  const handleDiscountChange = (id, value, price) => {
    const val = (parseFloat(value) * 100) / parseFloat(price);

    if (value.trim() === "") {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: 0 } : item
      );
      setSelectedItems(updatedDiscount);
    } else {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: val } : item
      );
      setSelectedItems(updatedDiscount);
    }
  };

  const handleTaxChange = (id, value) => {
    const tax = parseFloat(value);

    // if (value.trim() === "") {
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemGst: 0 } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // } else {
    //   const newValue = value.replace(/^0+/, "");
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemGst: newValue } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // }

    if (value === "14,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: 14, cess: 12, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: 28, cess: 12, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,60") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: 28, cess: 60, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: value, cess: 0, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleTotalChange = (id, value, quantity, tax, cess, discount) => {
    const total = parseFloat(value);
    const product = selectedItems.find((p) => p._id === id);

    if (value.trim() === "") {
      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: 0 } : product
      );
      setSelectedItems(updatedProducts);
    } else {
      const basePrice = total / (1 + product.itemGst / 100);

      const price =
        total /
        (quantity * (1 + tax / 100 + cess / 100) * (1 - discount / 100));

      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: price } : product
      );
      setSelectedItems(updatedProducts);
    }
  };

  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  const subTotal = selectedItems.reduce((acc, item) => {
    return (
      acc +
      calculateTaxedPrice(
        item.itemPrice,
        item.itemGst,
        item.quantity,
        item.discount,
        item.cess
      )
    );
  }, 0);

  const totalTax = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
        item.itemGst) /
        100 +
        ((item.itemPrice - (item.discount * item.itemPrice) / 100) *
          item.cess) /
          100) *
        item.quantity
    );
  }, 0);

  const totalDiscount = selectedItems.reduce((acc, item) => {
    return acc + (item.itemPrice * item.discount * item.quantity) / 100;
  }, 0);

  const totalGst = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);

  const totalCess = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);

  const taxableAmount = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (item.itemPrice * item.quantity -
        (item.itemPrice * item.discount * item.quantity) / 100)
    );
  }, 0);

  const totalAmount = roundOff
    ? Math.round(subTotal - data.invoiceExtraDiscount)
    : formatNumber(subTotal - data.invoiceExtraDiscount);

  // ====================================================================================================================
  // ====================================================================================================================

  const [editingQuantity, setEditingQuantity] = useState(null);
  const [editingDiscount, setEditingDiscount] = useState(null);
  const [editingDiscountPercent, setEditingDiscountPercent] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);
  const [editingTax, setEditingTax] = useState(null);
  const [editingTotalPrice, setEditingTotalPrice] = useState(null);

  const [editingExtraDiscount, setEditingExtraDiscount] = useState(null);
  const [editingExtraDiscountPercentage, setEditingExtraDiscountPercentage] =
    useState(null);

  const handleExtraDiscount = (e) => {
    const value = e.target.value;

    if ((value >= 0 && value <= subTotal) || value === "") {
      setData({
        ...data,
        invoiceExtraDiscount: value,
      });
    }
  };

  const handleExtraDiscountPercentage = (e) => {
    const value = e.target.value;

    if ((value >= 0 && value <= 100) || value === "") {
      const dis = (subTotal * value) / 100;

      setData({
        ...data,
        invoiceExtraDiscount: dis,
      });
    }
  };

  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================

  const handleSaveData = () => {
    setData({
      ...data,
      fullyPaid: fullyPaid,
      roundOff: roundOff,
      invoicePartyData: partyData,
      invoiceItemsData: selectedItems,
      invoiceSubTotalAmount: subTotal,
      invoiceTotalAmount: totalAmount * 1,
      receivedAmount: fullyPaid ? totalAmount * 1 : data.receivedAmount,

      invoiceDetailsOptions: Object.fromEntries(
        Object.entries(detailsOptionValue).filter(([key, value]) => value)
      ),
    });

    console.log("data is : ", data);
  };

  const { createInvoice, link } = useContext(AuthContext);

  const handleCreate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // if( data.receivedAmount > totalAmount && data.invoiceExtraDiscount > totalAmount){
    // if( data.invoiceExtraDiscount > totalAmount){
    //     alert("False")
    // }else{

    //     alert("True")
    // }
    await createInvoice(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      navigate
    );

    setLoading(false);
  };

  // console.log("data is: ", businessData);

  // =========================================================================================================================
  // =========================================================================================================================
  // =========================================================================================================================

  const [detailsOptionValue, setdetailsOptionValue] = useState({});

  // Update the value based on input changes

  const handleDetailsOptions = (e) => {
    const { name, value } = e.target;
    setdetailsOptionValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    console.log("new values are : ", detailsOptionValue);
    // setdetailsOptionValue((prevValues) => ({
    //   ...prevValues,
    //   [key]: value,
    // }));
  };

  // =========================================================================================================================
  // =========================================================================================================================
  // =========================================================================================================================
  // =========================================================================================================================

  return (
    <div>
      {createParty ? (
        <CreatePartyPopup
          open={setCreateParty}
          invoicePartyData={setPartyData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {createItem ? (
        <CreateItemPopup
          open={setCreateItem}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      <form onSubmit={handleCreate}>
        <div className="create-invoice-page">
          <div className="create-invoice-page-main">
            {/* top ----------------------------------------------------------------------------------------------------------- */}

            <div className="create-invoice-page-main-top">
              <div className="create-invoice-page-main-top-heading">
                <i
                  class="fa-solid fa-arrow-left"
                  onClick={() => navigate(-1)}
                ></i>
                Create sales invoice
              </div>
              <div className="create-invoice-page-main-top-buttons">
                {partyData && selectedItems.length > 0 ? (
                  <button onClick={handleSaveData}>Save Sale Invoice</button>
                ) : (
                  <div className="create-invoice-page-main-top-button-save">
                    Save Sale Invoice
                  </div>
                )}
              </div>
            </div>

            <div className="create-invoice-page-form">
              {/* content ----------------------------------------------------------------------------------------------------------- */}
              <div className="create-invoice-page-content">
                {/* head ----------------------------------------------------------------------------------------------------------- */}

                <div className="create-invoice-page-head">

                  <ShowAddressComponent businessData={businessData} partyData={partyData}setCreateParty={setCreateParty} setPartyData={setPartyData} handleKeyDown={handleKeyDown}/>
                  
                  
                  <div className="create-invoice-page-head-options">
                    <div className="create-invoice-page-head-options-row">
                      <div className="create-invoice-page-head-options-col">
                        <label>Sales Invoice No:</label>
                        <input
                          onKeyDown={handleKeyDown}
                          required
                          type="number"
                          onChange={handleChange}
                          name="invoiceNumber"
                          value={data.invoiceNumber}
                          ref={scrollRef1}
                          onWheel={(e) => handleWheelScroll(e, scrollRef1)}
                        />
                      </div>

                      <div className="create-invoice-page-head-options-col">
                        <label>Sales Invoice Date:</label>
                        <input
                          required
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          name="invoiceDate"
                          type="date"
                          id=""
                          value={data.invoiceDate}
                        />
                      </div>
                    </div>

                    {data.invoiceEnableDueDate ? (
                      <div className="create-invoice-page-head-options-due">
                        <div className="create-invoice-page-head-options-due-close">
                          <i
                            class="fa-solid fa-circle-xmark"
                            onClick={() =>
                              setData({
                                ...data,
                                invoiceEnableDueDate: false,
                              })
                            }
                          ></i>
                        </div>
                        <div className="create-invoice-page-head-options-row">
                          <div className="create-invoice-page-head-options-col">
                            <label>Payment Terms:</label>
                            <div className="create-invoice-page-head-options-terms">
                              {editingDueDate ? (
                                <input
                                  onKeyDown={handleKeyDown}
                                  onBlur={() => setEditingDueDate(false)}
                                  autoFocus
                                  type="number"
                                  onChange={handleDueDate}
                                  defaultValue={Math.ceil(
                                    Math.abs(
                                      new Date(data.invoiceDueDate) -
                                        new Date(data.invoiceDate)
                                    ) /
                                      (1000 * 60 * 60 * 24)
                                  )}
                                  ref={scrollRef2}
                                  onWheel={(e) =>
                                    handleWheelScroll(e, scrollRef2)
                                  }
                                />
                              ) : (
                                <input
                                  onFocus={() => setEditingDueDate(true)}
                                  type="text"
                                  value={Math.ceil(
                                    Math.abs(
                                      new Date(data.invoiceDueDate) -
                                        new Date(data.invoiceDate)
                                    ) /
                                      (1000 * 60 * 60 * 24)
                                  )}
                                />
                              )}
                              <span>days</span>
                            </div>
                          </div>

                          <div className="create-invoice-page-head-options-col">
                            <label>Due Date:</label>
                            <input
                              required
                              onKeyDown={handleKeyDown}
                              onChange={handleChange}
                              name="invoiceDueDate"
                              type="date"
                              id=""
                              value={data.invoiceDueDate}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-head-options-due-option"
                        onClick={() =>
                          setData({
                            ...data,
                            invoiceEnableDueDate: true,
                          })
                        }
                      >
                        + Add Due Date
                      </div>
                    )}

                    {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
                    <div className="create-invoice-page-head-options-row">
                      {businessData.invoiceSettingData &&
                      businessData.invoiceSettingData.invoiceDetailsOptions ? (
                        <>
                          {businessData.invoiceSettingData.invoiceDetailsOptions.map(
                            (e) => (
                              <div className="create-invoice-page-head-options-col">
                                <label>{e}:</label>
                                <input
                                  type="text"
                                  name={e}
                                  value={detailsOptionValue[e]}
                                  onChange={(e) => handleDetailsOptions(e)}

                                  // name={e}
                                  // value={customFieldValue[e]}
                                  // onChange={(e) => handleCustomFields(e)}
                                />
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {businessData.invoiceSettingData ? (
                        businessData.invoiceSettingData.invoicePoNoOptions ? (
                          <div className="create-invoice-page-head-options-col">
                            <label>P.O. No.</label>
                            <input
                              type="text"
                              onChange={handleChange}
                              name="invoicePoNo"
                            />
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {businessData.invoiceSettingData ? (
                        businessData.invoiceSettingData
                          .invoiceVehicleNumberOptions ? (
                          <div className="create-invoice-page-head-options-col">
                            <label>Vehicle No.</label>
                            <input
                              type="text"
                              onChange={handleChange}
                              name="invoiceVehicleNo"
                            />
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}

                {/* table ----------------------------------------------------------------------------------------------------------- */}
                <div className="create-invoice-page-table">
                  <div className="create-invoice-page-table-head">
                    <div className="create-invoice-page-table-sno">No</div>
                    <div className="create-invoice-page-table-items">Items</div>
                    <div className="create-invoice-page-table-hsn">HSN/SAC</div>
                    {businessData.invoiceSettingData ? (
                      businessData.invoiceSettingData.tabelQuantityOptions ? (
                        <div className="create-invoice-page-table-qty">QTY</div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {businessData.invoiceSettingData ? (
                      businessData.invoiceSettingData.tabelPriceOptions ? (
                        <div className="create-invoice-page-table-price">
                          PRICE/ITEM (₹)
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <div className="create-invoice-page-table-discount">
                      DISCOUNT
                    </div>
                    <div className="create-invoice-page-table-tax">TAX</div>
                    <div className="create-invoice-page-table-total">
                      AMOUNT (₹)
                    </div>
                    <div className="create-invoice-page-table-add">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>

                  {selectedItems.map((item, i) => (
                    <div
                      key={item._id}
                      className="create-invoice-page-table-body"
                    >
                      <div className="create-invoice-page-table-sno">
                        {i + 1}
                      </div>

                      <div className="create-invoice-page-table-items">
                        {item.itemName}

                        {businessData.invoiceSettingData ? (
                          businessData.invoiceSettingData
                            .invoiceItemDescription ? (
                            <div>
                              <input
                                type="text"
                                value={item.itemDescription}
                                onKeyDown={handleKeyDown}
                                onChange={(e) =>
                                  handleDescriptionChange(
                                    item._id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="create-invoice-page-table-hsn">
                        {item.itemCode ? item.itemCode : "-"}
                      </div>
                      {businessData.invoiceSettingData ? (
                        businessData.invoiceSettingData.tabelQuantityOptions ? (
                          <div className="create-invoice-page-table-qty">
                            {editingQuantity === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                value={item.quantity}
                                placeholder="Enter new price"
                                onChange={(e) =>
                                  handleQuantityChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingQuantity(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            ) : (
                              <input
                                onFocus={() => setEditingQuantity(item._id)}
                                type="number"
                                min={0}
                                value={item.quantity}
                              />
                            )}
                            &nbsp;
                            {item.itemMeasuringUnit
                              ? item.itemMeasuringUnit
                              : ""}
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {businessData.invoiceSettingData ? (
                        businessData.invoiceSettingData.tabelPriceOptions ? (
                          <div className="create-invoice-page-table-price">
                            {editingPrice === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={formatNumber(
                                  Number(item.itemPrice)
                                )}
                                placeholder="Enter new price"
                                onChange={(e) =>
                                  handlePriceChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingPrice(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            ) : (
                              <input
                                step="0.01"
                                onFocus={() => setEditingPrice(item._id)}
                                type="number"
                                min={0}
                                value={formatNumber(Number(item.itemPrice))}
                              />
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      <div className="create-invoice-page-table-discount">
                        <div>
                          {editingDiscountPercent === item._id ? (
                            <>
                              {" "}
                              %
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                // defaultValue={item.discount}
                                // defaultValue={formatNumber(item.discount)}
                                value={formatNumber(item.discount)}
                                onChange={(e) =>
                                  handleDiscountPercentChange(
                                    item._id,
                                    e.target.value
                                  )
                                }
                                onBlur={() => setEditingDiscountPercent(null)}
                                autoFocus
                                min={0}
                                max={100}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              %{" "}
                              <input
                                type="text"
                                onClick={() =>
                                  setEditingDiscountPercent(item._id)
                                }
                                onFocus={() =>
                                  setEditingDiscountPercent(item._id)
                                }
                                value={formatNumber(item.discount)}
                              />
                            </>
                          )}
                        </div>
                        <div>
                          {editingDiscount === item._id ? (
                            <>
                              ₹
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={formatNumber(
                                  (item.itemPrice *
                                    item.discount *
                                    item.quantity) /
                                    100
                                )}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    item._id,
                                    e.target.value,
                                    item.itemPrice,
                                    item.quantity
                                  )
                                }
                                onBlur={() => setEditingDiscount(null)}
                                autoFocus
                                min={0}
                                max={100}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              ₹{" "}
                              <input
                                type="text"
                                onClick={() => setEditingDiscount(item._id)}
                                onFocus={() => setEditingDiscount(item._id)}
                                value={formatNumber(
                                  (item.itemPrice *
                                    item.discount *
                                    item.quantity) /
                                    100
                                )}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-tax">
                        <input
                          type="text"
                          name=""
                          id=""
                          ref={selectTaxRef}
                          onClick={() => setEditingTax(item._id)}
                          value={formatNumber(
                            (((item.itemPrice -
                              (item.discount * item.itemPrice) / 100) *
                              item.itemGst) /
                              100 +
                              ((item.itemPrice -
                                (item.discount * item.itemPrice) / 100) *
                                item.cess) /
                                100) *
                              item.quantity
                          )}
                        />

                        {editingTax === item._id ? (
                          <div
                            ref={taxListRef}
                            className="create-invoice-page-table-tax-select"
                          >
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0);
                                setEditingTax(null);
                              }}
                            >
                              None
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0.1);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0.1%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0.25);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0.25%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 1.5);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 1.5%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 3);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 3%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 5);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 5%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 6);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 6%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 12);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 13.8);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 13.8%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 18);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 18%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "14,12");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 14% + cess @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 28);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "28,12");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28% + Cess @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "28,60");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28% + Cess @ 60%
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          (<>{item.itemGst}%</>
                          {item.cess ? <> + {item.cess} Cess</> : ""})
                        </div>
                      </div>

                      <div className="create-invoice-page-table-total">
                        <div>
                          {editingTotalPrice === item._id ? (
                            <>
                              ₹
                              <input
                                onKeyDown={handleKeyDown}
                                defaultValue={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                type="number"
                                placeholder="Enter total price"
                                onChange={(e) =>
                                  handleTotalChange(
                                    item._id,
                                    e.target.value,
                                    item.quantity,
                                    item.itemGst,
                                    item.cess,
                                    item.discount
                                  )
                                }
                                onBlur={() => setEditingTotalPrice(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              ₹
                              <input
                                type="number"
                                min={0}
                                value={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                onFocus={() => setEditingTotalPrice(item._id)}
                                onChange={() => setEditingTotalPrice(item._id)}
                                step="0.01"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-del">
                        <i
                          class="fa-solid fa-trash"
                          onClick={() => handleRemoveItem(item._id)}
                        ></i>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="create-invoice-item-list">
                  <div className="create-invoice-item-list-heading">
                    Invoice Items{" "}
                  </div>
                  <div className="create-invoice-item-list-data">
                    {selectedItems.map((item, i) => (
                      <div className="invoice-small-page-select-item-col">
                        <div className="invoice-small-page-select-item-col-details">
                          <div className="invoice-small-page-select-item-col-name">
                            {i + 1}) {item.itemName}
                          </div>
                          {viewItemDetails === item._id ? (
                            <div className="invoice-small-page-select-item-view">
                              {/* <div className="invoice-small-page-select-item-view-col">{item.itemDescription ? item.itemDescription : ""}</div> */}

                              {item.itemCode ? (
                                <div className="invoice-small-page-select-item-view-col">
                                  <div>Code :</div>
                                  <span>{item.itemCode}</span>
                                </div>
                              ) : (
                                ""
                              )}

                              <table className="invoice-small-page-select-item-view-table">
                                <tr>
                                  <td>Description</td>
                                  <td>
                                    <textarea
                                      type="text"
                                      value={item.itemDescription}
                                      onKeyDown={handleKeyDown}
                                      onChange={(e) =>
                                        handleDescriptionChange(
                                          item._id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>Price</td>
                                  <td>
                                    {editingPrice === item._id ? (
                                      <input
                                        onKeyDown={handleKeyDown}
                                        type="number"
                                        defaultValue={formatNumber(
                                          Number(item.itemPrice)
                                        )}
                                        placeholder="Enter new price"
                                        onChange={(e) =>
                                          handlePriceChange(
                                            item._id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={() => setEditingPrice(null)}
                                        autoFocus
                                        min={0}
                                        ref={scrollRef3}
                                        onWheel={(e) =>
                                          handleWheelScroll(e, scrollRef3)
                                        }
                                      />
                                    ) : (
                                      <input
                                        step="0.01"
                                        onFocus={() =>
                                          setEditingPrice(item._id)
                                        }
                                        type="number"
                                        min={0}
                                        value={formatNumber(
                                          Number(item.itemPrice)
                                        )}
                                      />
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Discount</td>
                                  <td>
                                    <div className="create-invoice-item-list-discount">
                                      <div>
                                        %
                                        {editingDiscountPercent === item._id ? (
                                          <>
                                            <input
                                              onKeyDown={handleKeyDown}
                                              type="number"
                                              value={formatNumber(
                                                item.discount
                                              )}
                                              onChange={(e) =>
                                                handleDiscountPercentChange(
                                                  item._id,
                                                  e.target.value
                                                )
                                              }
                                              onBlur={() =>
                                                setEditingDiscountPercent(null)
                                              }
                                              autoFocus
                                              min={0}
                                              max={100}
                                              ref={scrollRef3}
                                              onWheel={(e) =>
                                                handleWheelScroll(e, scrollRef3)
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <input
                                              type="text"
                                              onClick={() =>
                                                setEditingDiscountPercent(
                                                  item._id
                                                )
                                              }
                                              onFocus={() =>
                                                setEditingDiscountPercent(
                                                  item._id
                                                )
                                              }
                                              value={formatNumber(
                                                item.discount
                                              )}
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div>
                                        ₹
                                        {editingDiscount === item._id ? (
                                          <>
                                            <input
                                              onKeyDown={handleKeyDown}
                                              type="number"
                                              defaultValue={formatNumber(
                                                (item.itemPrice *
                                                  item.discount *
                                                  item.quantity) /
                                                  100
                                              )}
                                              onChange={(e) =>
                                                handleDiscountChange(
                                                  item._id,
                                                  e.target.value,
                                                  item.itemPrice,
                                                  item.quantity
                                                )
                                              }
                                              onBlur={() =>
                                                setEditingDiscount(null)
                                              }
                                              autoFocus
                                              min={0}
                                              max={100}
                                              ref={scrollRef3}
                                              onWheel={(e) =>
                                                handleWheelScroll(e, scrollRef3)
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <input
                                              type="text"
                                              onClick={() =>
                                                setEditingDiscount(item._id)
                                              }
                                              onFocus={() =>
                                                setEditingDiscount(item._id)
                                              }
                                              value={formatNumber(
                                                (item.itemPrice *
                                                  item.discount *
                                                  item.quantity) /
                                                  100
                                              )}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Tax</td>
                                  <td className="create-invoice-item-list-tax">
                                    <select
                                      value={item.itemTax}
                                      onChange={(e) =>
                                        handleTaxChange(
                                          item._id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="0">None</option>
                                      <option value="0">0%</option>
                                      <option value="0.1"> 0.1%</option>
                                      <option value="0.25">0.25%</option>
                                      <option value="1.5">1.5%</option>
                                      <option value="3">3%</option>
                                      <option value="5">5%</option>
                                      <option value="6">6%</option>
                                      <option value="12">12%</option>
                                      <option value="13.8">13.8%</option>
                                      <option value="18">18%</option>
                                      <option value="14,12">
                                        14% + cess @ 12%
                                      </option>
                                      <option value="28">28%</option>
                                      <option value="28,12">
                                        28% + Cess @ 12%
                                      </option>
                                      <option value="28,60">
                                        28% + Cess @ 60%
                                      </option>
                                    </select>
                                    <div>
                                      ₹{" "}
                                      {formatNumber(
                                        (((item.itemPrice -
                                          (item.discount * item.itemPrice) /
                                            100) *
                                          item.itemGst) /
                                          100 +
                                          ((item.itemPrice -
                                            (item.discount * item.itemPrice) /
                                              100) *
                                            item.cess) /
                                            100) *
                                          item.quantity
                                      )}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Total</td>
                                  <td>
                                    <div className="create-invoice-item-list-total">
                                      {editingTotalPrice === item._id ? (
                                        <>
                                          ₹
                                          <input
                                            onKeyDown={handleKeyDown}
                                            defaultValue={formatNumber(
                                              calculateTaxedPrice(
                                                item.itemPrice,
                                                item.itemGst,
                                                item.quantity,
                                                item.discount,
                                                item.cess
                                              )
                                            )}
                                            type="number"
                                            placeholder="Enter total price"
                                            onChange={(e) =>
                                              handleTotalChange(
                                                item._id,
                                                e.target.value,
                                                item.quantity,
                                                item.itemGst,
                                                item.cess,
                                                item.discount
                                              )
                                            }
                                            onBlur={() =>
                                              setEditingTotalPrice(null)
                                            }
                                            autoFocus
                                            min={0}
                                            ref={scrollRef3}
                                            onWheel={(e) =>
                                              handleWheelScroll(e, scrollRef3)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          ₹
                                          <input
                                            type="number"
                                            min={0}
                                            value={formatNumber(
                                              calculateTaxedPrice(
                                                item.itemPrice,
                                                item.itemGst,
                                                item.quantity,
                                                item.discount,
                                                item.cess
                                              )
                                            )}
                                            onFocus={() =>
                                              setEditingTotalPrice(item._id)
                                            }
                                            onChange={() =>
                                              setEditingTotalPrice(item._id)
                                            }
                                            step="0.01"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </table>

                              {/* <div className="invoice-small-page-select-item-view-col"><div>Price :</div><input type="text" /></div>
                              <div className="invoice-small-page-select-item-view-col"><div>Discount :</div><input type="text" /></div>
                              <div className="invoice-small-page-select-item-view-col"><div>Tax :</div><input type="text" /></div>
                              <div className="invoice-small-page-select-item-view-col"><div>Total :</div><input type="text" /></div> */}
                            </div>
                          ) : (
                            <div className="invoice-small-page-select-item-col-price">
                              ₹{" "}
                              {formatNumber(
                                calculateTaxedPrice(
                                  item.itemPrice,
                                  item.itemGst,
                                  item.quantity,
                                  item.discount,
                                  item.cess
                                )
                              )}
                              <span
                                onClick={() => setViewItemDetails(item._id)}
                              >
                                <i className="fa-solid fa-pen"></i> edit
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="invoice-small-page-select-item-col-buttons">
                          {selectedItems.find((e) => e._id === item._id) ? (
                            <div className="invoice-small-page-select-item-col-delete">
                              <input
                                type="number"
                                defaultValue={
                                  selectedItems.find((e) => e._id === item._id)
                                    .quantity
                                }
                                onChange={(e) =>
                                  handleQuantityChange(item._id, e.target.value)
                                }
                                min={0}
                                value={
                                  selectedItems.find((e) => e._id === item._id)
                                    .quantity
                                }
                              />

                              <i
                                onClick={() => handleRemoveItem(item._id)}
                                className="fa-solid fa-trash-can"
                              ></i>
                            </div>
                          ) : (
                            <div
                              className="invoice-small-page-select-item-col-add"
                              onClick={() => handleAddItem(item)}
                            >
                              Add
                            </div>
                          )}
                          {viewItemDetails === item._id ? (
                            <div
                              onClick={() => setViewItemDetails(false)}
                              className="invoice-small-page-select-item-col-close"
                            >
                              close
                              <i className="fa-solid fa-angle-up" />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="create-invoice-item-list-col"></div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setSelectItem(true);
                    setViewItemDetails("");
                  }}
                  className="create-invoice-page-table-add-item-option"
                >
                  + Add Items
                </div>

                {/* add item ----------------------------------------------------------------------------------------------------------- */}
                {selectItem ? (
                  <div className="create-invoice-page-table-add-item">
                    <div className="create-invoice-page-table-add-item-main">
                      <div className="create-invoice-page-table-add-item-top">
                        <div>Add Items</div>
                        <div
                          className="create-invoice-page-table-add-item-top-close"
                          onClick={() => {
                            setSelectItem(false);
                            setSearchItem("");
                            setViewItemDetails("");
                          }}
                        >
                          Close
                        </div>
                      </div>
                      <div className="create-invoice-page-table-add-item-head">
                        <div className="create-invoice-page-table-add-item-head-search">
                          <i class="fa-solid fa-magnifying-glass"></i>
                          <input
                            onKeyDown={handleKeyDown}
                            type="text"
                            placeholder="search items"
                            value={searchItem}
                            onChange={(e) => setSearchItem(e.target.value)}
                          />
                        </div>
                        <div
                          className="create-invoice-page-table-add-item-head-create"
                          onClick={() => {
                            setCreateItem(true);
                          }}
                        >
                          + Create New
                        </div>
                      </div>

                      <div className="create-invoice-page-table-add-item-table">
                        <div className="create-invoice-page-table-add-item-table-head">
                          <div className="create-invoice-page-table-add-item-table-name">
                            ITEM NAME
                          </div>
                          <div className="create-invoice-page-table-add-item-table-code">
                            ITEM CODE
                          </div>
                          <div className="create-invoice-page-table-add-item-table-sale">
                            SALE PRICE
                          </div>
                          <div className="create-invoice-page-table-add-item-table-purchase">
                            PURCHASE PRICE
                          </div>
                          <div className="create-invoice-page-table-add-item-table-stock">
                            CURRENT STOCK
                          </div>
                          <div className="create-invoice-page-table-add-item-table-quantity">
                            QUANTITY
                          </div>
                        </div>

                        <div className="create-invoice-page-table-add-item-table-body">
                          {selectedItems.map((item) => (
                            <div
                              key={item._id}
                              className="create-invoice-page-table-add-item-table-body-row"
                            >
                              <div className="create-invoice-page-table-add-item-table-name">
                                {" "}
                                <i
                                  class="fa-regular fa-square-check"
                                  onClick={() => handleRemoveItem(item._id)}
                                ></i>{" "}
                                {item.itemName}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-code">
                                {item.itemCode ? item.itemCode : "-"}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-sale">
                                {item.itemPrice ? (
                                  <>
                                    {item.itemTax === "0" ||
                                    item.itemTax === "0.1" ||
                                    item.itemTax === "0.25" ||
                                    item.itemTax === "1.5" ||
                                    item.itemTax === "3" ||
                                    item.itemTax === "5" ||
                                    item.itemTax === "6" ||
                                    item.itemTax === "12" ||
                                    item.itemTax === "13.8" ||
                                    item.itemTax === "18" ||
                                    item.itemTax === "28" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice *
                                              Number(item.itemTax)) /
                                              100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "14,12" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 14) / 100 +
                                            (item.itemPrice * 12) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "28,12" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 28) / 100 +
                                            (item.itemPrice * 12) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "28,60" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 28) / 100 +
                                            (item.itemPrice * 60) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : (
                                      <>₹ {item.itemPrice}</>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-purchase">
                                {item.itemPurchasePrice ? (
                                  <>
                                    <>
                                      {item.itemTax === "0" ||
                                      item.itemTax === "0.1" ||
                                      item.itemTax === "0.25" ||
                                      item.itemTax === "1.5" ||
                                      item.itemTax === "3" ||
                                      item.itemTax === "5" ||
                                      item.itemTax === "6" ||
                                      item.itemTax === "12" ||
                                      item.itemTax === "13.8" ||
                                      item.itemTax === "18" ||
                                      item.itemTax === "28" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice *
                                                Number(item.itemTax)) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "14,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 14) /
                                                100 +
                                              (item.itemPurchasePrice * 12) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 28) /
                                                100 +
                                              (item.itemPurchasePrice * 12) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,60" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 28) /
                                                100 +
                                              (item.itemPurchasePrice * 60) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : (
                                        <>₹ {item.itemPurchasePrice}</>
                                      )}
                                    </>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-stock">
                                <div>
                                  {" "}
                                  <div>
                                    {" "}
                                    {item.itemStock ? (
                                      <>
                                        {item.itemStock}{" "}
                                        {item.itemMeasuringUnit
                                          ? item.itemMeasuringUnit
                                          : ""}{" "}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                {item.itemStock < 0 ? (
                                  <span>Insufficient Stock</span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-quantity">
                                <div className="create-invoice-page-table-add-item-table-quantity-added">
                                  {item.quantity <= 1 ? (
                                    <i
                                      onClick={() => handleRemoveItem(item._id)}
                                      class="fa-solid fa-square-minus"
                                    ></i>
                                  ) : (
                                    <i
                                      onClick={() =>
                                        handleDecreaseQuanitiy(item)
                                      }
                                      class="fa-solid fa-square-minus"
                                    ></i>
                                  )}

                                  <input
                                    type="number"
                                    // autoFocus
                                    defaultValue={item.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        item._id,
                                        e.target.value
                                      )
                                    }
                                    min={0}
                                    value={item.quantity}
                                  />
                                  <i
                                    onClick={() => handleIncreaseQuanitiy(item)}
                                    class="fa-solid fa-square-plus"
                                  ></i>
                                  <div>
                                    {item.itemMeasuringUnit
                                      ? item.itemMeasuringUnit
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {businessData.itemData
                            .filter((e) =>
                              e.itemName
                                .toLocaleLowerCase()
                                .includes(searchItem.toLowerCase())
                            )

                            .filter(
                              (e) => !selectedItems.some((b) => e._id === b._id)
                            )
                            .reverse()
                            .map((item) => (
                              <div
                                key={item._id}
                                className="create-invoice-page-table-add-item-table-body-row"
                              >
                                <div className="create-invoice-page-table-add-item-table-name">
                                  {" "}
                                  <i
                                    class="fa-regular fa-square"
                                    onClick={() => handleAddItem(item)}
                                  ></i>{" "}
                                  {item.itemName}
                                </div>
                                <div className="create-invoice-page-table-add-item-table-code">
                                  {item.itemCode ? item.itemCode : "-"}
                                </div>
                                {/* <div className="create-invoice-page-table-add-item-table-sale">
                                {item.itemPrice ? <>₹ {item.itemPrice}</> : "-"}
                              </div> */}

                                <div className="create-invoice-page-table-add-item-table-sale">
                                  {item.itemPrice ? (
                                    <>
                                      {item.itemTax === "0" ||
                                      item.itemTax === "0.1" ||
                                      item.itemTax === "0.25" ||
                                      item.itemTax === "1.5" ||
                                      item.itemTax === "3" ||
                                      item.itemTax === "5" ||
                                      item.itemTax === "6" ||
                                      item.itemTax === "12" ||
                                      item.itemTax === "13.8" ||
                                      item.itemTax === "18" ||
                                      item.itemTax === "28" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice *
                                                Number(item.itemTax)) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "14,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice * 14) / 100 +
                                              (item.itemPrice * 12) / 100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice * 28) / 100 +
                                              (item.itemPrice * 12) / 100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,60" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice * 28) / 100 +
                                              (item.itemPrice * 60) / 100
                                          ) * 1}{" "}
                                        </>
                                      ) : (
                                        <>₹ {item.itemPrice}</>
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </div>

                                <div className="create-invoice-page-table-add-item-table-purchase">
                                  {item.itemPurchasePrice ? (
                                    <>
                                      {item.itemTax === "0" ||
                                      item.itemTax === "0.1" ||
                                      item.itemTax === "0.25" ||
                                      item.itemTax === "1.5" ||
                                      item.itemTax === "3" ||
                                      item.itemTax === "5" ||
                                      item.itemTax === "6" ||
                                      item.itemTax === "12" ||
                                      item.itemTax === "13.8" ||
                                      item.itemTax === "18" ||
                                      item.itemTax === "28" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice *
                                                Number(item.itemTax)) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "14,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 14) /
                                                100 +
                                              (item.itemPurchasePrice * 12) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 28) /
                                                100 +
                                              (item.itemPurchasePrice * 12) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,60" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 28) /
                                                100 +
                                              (item.itemPurchasePrice * 60) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : (
                                        <>₹ {item.itemPurchasePrice}</>
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                <div className="create-invoice-page-table-add-item-table-stock">
                                  <div>
                                    {" "}
                                    {item.itemStock ? (
                                      <>
                                        {item.itemStock}{" "}
                                        {item.itemMeasuringUnit
                                          ? item.itemMeasuringUnit
                                          : ""}{" "}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                <div className="create-invoice-page-table-add-item-table-quantity">
                                  <div
                                    onClick={() => handleAddItem(item)}
                                    className="create-invoice-page-table-add-item-table-quantity-add"
                                  >
                                    + Add
                                  </div>
                                </div>
                              </div>
                            ))}

                          {businessData.itemData.filter((e) =>
                            e.itemName
                              .toLocaleLowerCase()
                              .includes(searchItem.toLowerCase())
                          ).length <= 0 ? (
                            <div className="create-invoice-page-table-add-item-table-empty">
                              No Items found
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="invoice-small-page-select-item">
                        {businessData.itemData
                          .filter((e) =>
                            e.itemName
                              .toLocaleLowerCase()
                              .includes(searchItem.toLowerCase())
                          )
                          .reverse()
                          .map((item) => (
                            <div className="invoice-small-page-select-item-col">
                              <div className="invoice-small-page-select-item-col-details">
                                <div className="invoice-small-page-select-item-col-name">
                                  {item.itemName}
                                </div>
                                {viewItemDetails === item._id ? (
                                  <div className="invoice-small-page-select-item-view">
                                    <div className="invoice-small-page-select-item-view-col">
                                      {item.itemDescription
                                        ? item.itemDescription
                                        : ""}
                                    </div>

                                    {item.itemCode ? (
                                      <div className="invoice-small-page-select-item-view-col">
                                        <div>Code :</div>
                                        <span>{item.itemCode}</span>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <div className="invoice-small-page-select-item-view-col">
                                      <div>Sales Price :</div>
                                      <span>
                                        {" "}
                                        {item.itemPrice ? (
                                          <>
                                            {item.itemTax === "0" ||
                                            item.itemTax === "0.1" ||
                                            item.itemTax === "0.25" ||
                                            item.itemTax === "1.5" ||
                                            item.itemTax === "3" ||
                                            item.itemTax === "5" ||
                                            item.itemTax === "6" ||
                                            item.itemTax === "12" ||
                                            item.itemTax === "13.8" ||
                                            item.itemTax === "18" ||
                                            item.itemTax === "28" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPrice +
                                                    (item.itemPrice *
                                                      Number(item.itemTax)) /
                                                      100
                                                ) * 1}{" "}
                                              </>
                                            ) : item.itemTax === "14,12" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPrice +
                                                    (item.itemPrice * 14) /
                                                      100 +
                                                    (item.itemPrice * 12) / 100
                                                ) * 1}{" "}
                                              </>
                                            ) : item.itemTax === "28,12" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPrice +
                                                    (item.itemPrice * 28) /
                                                      100 +
                                                    (item.itemPrice * 12) / 100
                                                ) * 1}{" "}
                                              </>
                                            ) : item.itemTax === "28,60" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPrice +
                                                    (item.itemPrice * 28) /
                                                      100 +
                                                    (item.itemPrice * 60) / 100
                                                ) * 1}{" "}
                                              </>
                                            ) : (
                                              <>₹ {item.itemPrice}</>
                                            )}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </span>
                                    </div>

                                    <div className="invoice-small-page-select-item-view-col">
                                      <div>Purchase Price :</div>
                                      <span>
                                        {item.itemPurchasePrice ? (
                                          <>
                                            {item.itemTax === "0" ||
                                            item.itemTax === "0.1" ||
                                            item.itemTax === "0.25" ||
                                            item.itemTax === "1.5" ||
                                            item.itemTax === "3" ||
                                            item.itemTax === "5" ||
                                            item.itemTax === "6" ||
                                            item.itemTax === "12" ||
                                            item.itemTax === "13.8" ||
                                            item.itemTax === "18" ||
                                            item.itemTax === "28" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPurchasePrice +
                                                    (item.itemPurchasePrice *
                                                      Number(item.itemTax)) /
                                                      100
                                                ) * 1}{" "}
                                              </>
                                            ) : item.itemTax === "14,12" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPurchasePrice +
                                                    (item.itemPurchasePrice *
                                                      14) /
                                                      100 +
                                                    (item.itemPurchasePrice *
                                                      12) /
                                                      100
                                                ) * 1}{" "}
                                              </>
                                            ) : item.itemTax === "28,12" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPurchasePrice +
                                                    (item.itemPurchasePrice *
                                                      28) /
                                                      100 +
                                                    (item.itemPurchasePrice *
                                                      12) /
                                                      100
                                                ) * 1}{" "}
                                              </>
                                            ) : item.itemTax === "28,60" ? (
                                              <>
                                                ₹{" "}
                                                {formatNumber(
                                                  item.itemPurchasePrice +
                                                    (item.itemPurchasePrice *
                                                      28) /
                                                      100 +
                                                    (item.itemPurchasePrice *
                                                      60) /
                                                      100
                                                ) * 1}{" "}
                                              </>
                                            ) : (
                                              <>₹ {item.itemPurchasePrice}</>
                                            )}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </span>
                                    </div>

                                    <div className="invoice-small-page-select-item-view-col">
                                      <div>Tax :</div>
                                      <span>
                                        {item.itemTax ? (
                                          <>
                                            {item.itemTax === "14,12"
                                              ? "14% + Cess 12"
                                              : item.itemTax === "28,12"
                                              ? "28% + Cess 12"
                                              : item.itemTax === "28,60"
                                              ? "28% + Cess 60"
                                              : item.itemTax}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="invoice-small-page-select-item-col-price">
                                    {item.itemPrice ? (
                                      <>
                                        {item.itemTax === "0" ||
                                        item.itemTax === "0.1" ||
                                        item.itemTax === "0.25" ||
                                        item.itemTax === "1.5" ||
                                        item.itemTax === "3" ||
                                        item.itemTax === "5" ||
                                        item.itemTax === "6" ||
                                        item.itemTax === "12" ||
                                        item.itemTax === "13.8" ||
                                        item.itemTax === "18" ||
                                        item.itemTax === "28" ? (
                                          <>
                                            ₹{" "}
                                            {formatNumber(
                                              item.itemPrice +
                                                (item.itemPrice *
                                                  Number(item.itemTax)) /
                                                  100
                                            ) * 1}{" "}
                                          </>
                                        ) : item.itemTax === "14,12" ? (
                                          <>
                                            ₹{" "}
                                            {formatNumber(
                                              item.itemPrice +
                                                (item.itemPrice * 14) / 100 +
                                                (item.itemPrice * 12) / 100
                                            ) * 1}{" "}
                                          </>
                                        ) : item.itemTax === "28,12" ? (
                                          <>
                                            ₹{" "}
                                            {formatNumber(
                                              item.itemPrice +
                                                (item.itemPrice * 28) / 100 +
                                                (item.itemPrice * 12) / 100
                                            ) * 1}{" "}
                                          </>
                                        ) : item.itemTax === "28,60" ? (
                                          <>
                                            ₹{" "}
                                            {formatNumber(
                                              item.itemPrice +
                                                (item.itemPrice * 28) / 100 +
                                                (item.itemPrice * 60) / 100
                                            ) * 1}{" "}
                                          </>
                                        ) : (
                                          <>₹ {item.itemPrice}</>
                                        )}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                    <span
                                      onClick={() =>
                                        setViewItemDetails(item._id)
                                      }
                                    >
                                      view{" "}
                                      <i className="fa-solid fa-angle-down"></i>
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="invoice-small-page-select-item-col-buttons">
                                {selectedItems.find(
                                  (e) => e._id === item._id
                                ) ? (
                                  <div className="invoice-small-page-select-item-col-delete">
                                    <input
                                      type="number"
                                      defaultValue={
                                        selectedItems.find(
                                          (e) => e._id === item._id
                                        ).quantity
                                      }
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          item._id,
                                          e.target.value
                                        )
                                      }
                                      min={0}
                                      value={
                                        selectedItems.find(
                                          (e) => e._id === item._id
                                        ).quantity
                                      }
                                    />

                                    <i
                                      onClick={() => handleRemoveItem(item._id)}
                                      className="fa-solid fa-trash-can"
                                    ></i>
                                  </div>
                                ) : (
                                  <div
                                    className="invoice-small-page-select-item-col-add"
                                    onClick={() => handleAddItem(item)}
                                  >
                                    Add
                                  </div>
                                )}
                                {viewItemDetails === item._id ? (
                                  <div
                                    onClick={() => setViewItemDetails(false)}
                                    className="invoice-small-page-select-item-col-close"
                                  >
                                    close
                                    <i className="fa-solid fa-angle-up" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}

                        {businessData.itemData.filter((e) =>
                          e.itemName
                            .toLocaleLowerCase()
                            .includes(searchItem.toLowerCase())
                        ).length > 0 ? (
                          ""
                        ) : (
                          <div className="invoice-small-page-select-item-empty">
                            No items
                          </div>
                        )}
                      </div>

                      <div className="create-invoice-page-table-add-item-button">
                        <div
                          onClick={() => {
                            setSelectItem(false);
                            setSearchItem("");
                            setViewItemDetails("");
                          }}
                        >
                          Done
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="create-invoice-page-table-sub-total">
                  <div>SUBTOTAL</div>
                  <div>₹ {formatNumber(totalDiscount)}</div>
                  <div>₹ {formatNumber(totalTax)}</div>
                  <div>₹ {formatNumber(subTotal)}</div>
                  {/* {(100000000).toLocaleString('en-IN')} */}
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}

                <div className="create-invoice-page-end">
                  <div className="create-invoice-page-end-col-1">
                    {AddNotes ? (
                      <div className="create-invoice-page-end-form">
                        <div className="create-invoice-page-end-head">
                          <div>Notes</div>
                          <span>
                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddNotes(false);
                                setData({
                                  ...data,
                                  invoiceNotes: "",
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                        <textarea
                          name="invoiceNotes"
                          value={data.invoiceNotes}
                          onChange={handleChange}
                          placeholder="Enter your notes"
                        ></textarea>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-end-link"
                        onClick={() => {
                          setAddNotes(true);
                        }}
                      >
                        + Add Notes
                      </div>
                    )}

                    {AddTerms || data.invoiceTermsConditions ? (
                      <div className="create-invoice-page-end-form">
                        <div className="create-invoice-page-end-head">
                          <div>Terms and Conditions</div>
                          <span>
                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddTerms(false);
                                setData({
                                  ...data,
                                  invoiceTermsConditions: "",
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                        <textarea
                          name="invoiceTermsConditions"
                          value={data.invoiceTermsConditions}
                          onChange={handleChange}
                          placeholder="Enter your terms and conditions"
                        ></textarea>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-end-link"
                        onClick={() => {
                          setAddTerms(true);
                        }}
                      >
                        + Terms and Conditions
                      </div>
                    )}

                    {data.paymentType === "Bank Transfer" ||
                    data.paymentType === "UPI" ||
                    data.paymentType === "Card" ||
                    data.paymentType === "Cheque" ||
                    data.paymentType === "Netbanking"
                      ? businessData.bankData &&
                        businessData.bankData
                          .filter((e) => data.selectedBankAccount === e._id)
                          .map((e) => (
                            <div className="create-invoice-page-end-bank">
                              <div className="create-invoice-page-end-bank-head">
                                <div>Bank Details</div>
                              </div>
                              <div className="create-invoice-page-end-bank-col">
                                <div>Account Number:</div>
                                <span>{e.accountNumber}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-col">
                                <div>IFSC Code:</div>
                                <span>{e.IFSCcode}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-col">
                                <div>Bank & Branch Name:</div>
                                <span>{e.bankName}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-col">
                                <div>Account Holder's Name:</div>
                                <span>{e.accountHolderName}</span>
                              </div>

                              <div className="create-invoice-page-end-bank-head">
                                <div>UPI ID</div>
                              </div>
                              {e.upiId ? (
                                <th>{e.upiId}</th>
                              ) : (
                                <>No UPI ID Added</>
                              )}
                            </div>
                          ))
                      : ""}
                  </div>

                  <div className="create-invoice-page-end-amount">
                    {/* <div className="create-invoice-page-end-link">+ Add Additional Charges</div> */}

                    <div className="create-invoice-page-end-amount-row">
                      <div>Taxable Amount</div>
                      <div>₹ {formatNumber(taxableAmount) * 1}</div>
                    </div>
                    {totalGst ? (
                      <>
                        <div className="create-invoice-page-end-amount-row">
                          <div>SGST</div>
                          <div>₹ {formatNumber(totalGst) / 2}</div>
                        </div>

                        <div className="create-invoice-page-end-amount-row">
                          <div>CGST</div>
                          <div>₹ {formatNumber(totalGst) / 2}</div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {totalCess ? (
                      <div className="create-invoice-page-end-amount-row">
                        <div>CESS</div>
                        <div>₹ {formatNumber(totalCess) * 1}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="create-invoice-page-end-amount-discount">
                      {AddExtraDiscount ? (
                        <div className="create-invoice-page-end-amount-discount-row">
                          <div>Discount After Tax</div>
                          {/* <select name="" id="">
                            <option value="Discount After Tax">
                              Discount After Tax
                            </option>
                          </select> */}
                          <div className="create-invoice-page-end-amount-discount-col">
                            <div className="create-invoice-page-end-amount-discount-input">
                              %
                              <input
                                onBlur={() =>
                                  setEditingExtraDiscountPercentage(false)
                                }
                                type="number"
                                value={
                                  formatNumber(
                                    (data.invoiceExtraDiscount * 100) / subTotal
                                  ) * 1
                                }
                                onChange={handleExtraDiscountPercentage}
                                max={100}
                                ref={inputSelectRef1}
                                onFocus={() =>
                                  handleSelectFocus(inputSelectRef1)
                                }
                                onWheel={(e) => {
                                  handleWheelScroll(e, inputSelectRef1);
                                }}
                              />
                              {/* {editingExtraDiscountPercentage ? (
                                <input
                                  onBlur={() =>
                                    setEditingExtraDiscountPercentage(false)
                                  }
                                  type="number"
                                  value={
                                    formatNumber(
                                      (data.invoiceExtraDiscount * 100) /
                                        subTotal
                                    ) * 1
                                  }
                                  onChange={handleExtraDiscountPercentage}
                                  max={100}
                                  ref={inputSelectRef}
                                  onFocus={handleSelectFocus}
                                />
                              ) : (
                                <input
                                  max={100}
                                  onFocus={() =>
                                    setEditingExtraDiscountPercentage(true)
                                  }
                                  type="number"
                                  value={formatNumber(
                                    (data.invoiceExtraDiscount * 100) / subTotal
                                  )}
                                />
                              )} */}
                            </div>

                            <div className="create-invoice-page-end-amount-discount-input">
                              ₹
                              <input
                                onBlur={() => setEditingExtraDiscount(null)}
                                value={
                                  formatNumber(data.invoiceExtraDiscount * 1) *
                                  1
                                }
                                type="number"
                                name="editingExtraDiscount"
                                onChange={handleExtraDiscount}
                                ref={inputSelectRef2}
                                onFocus={() =>
                                  handleSelectFocus(inputSelectRef2)
                                }
                                onWheel={(e) => {
                                  handleWheelScroll(e, inputSelectRef2);
                                }}
                              />
                              {/* {editingExtraDiscount ? (
                                <input
                                  onBlur={() => setEditingExtraDiscount(null)}
                                  value={
                                    formatNumber(
                                      data.invoiceExtraDiscount * 1
                                    ) * 1
                                  }
                                  type="number"
                                  name="editingExtraDiscount"
                                  onChange={handleExtraDiscount}
                                  max={totalAmount}
                                />
                              ) : (
                                <input
                                  max={totalAmount}
                                  type="number"
                                  name="editingExtraDiscount"
                                  value={formatNumber(
                                    Number(data.invoiceExtraDiscount)
                                  )}
                                  onFocus={() => setEditingExtraDiscount(true)}
                                />
                              )} */}
                            </div>

                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddExtraDiscount(false);
                                setData({
                                  ...data,
                                  invoiceExtraDiscount: 0,
                                });
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="create-invoice-page-end-link"
                          onClick={() => setAddExtraDiscount(true)}
                        >
                          + Add Discount
                        </div>
                      )}
                    </div>

                    <div className="create-invoice-page-end-hr"></div>
                    {roundOff ? (
                      <div
                        onClick={() => setRoundOff(false)}
                        className="create-invoice-page-end-option"
                      >
                        <i class="fa-regular fa-square-check"></i>
                        Auto Round Off
                      </div>
                    ) : (
                      <div
                        onClick={() => setRoundOff(true)}
                        className="create-invoice-page-end-option"
                      >
                        <i class="fa-regular fa-square"></i>
                        Auto Round Off
                      </div>
                    )}

                    <div className="create-invoice-page-end-total">
                      <div>Total Amount</div>
                      <div>₹ {totalAmount * 1}</div>
                    </div>

                    <div className="create-invoice-page-end-hr"></div>

                    <div className="create-invoice-page-end-received">
                      <div className="create-invoice-page-end-received-option">
                        {fullyPaid ? (
                          <div onClick={() => setFullyPaid(false)}>
                            Mark as fully paid
                            <i class="fa-regular fa-square-check"></i>
                          </div>
                        ) : (
                          <div onClick={() => setFullyPaid(true)}>
                            Mark as fully paid
                            <i class="fa-regular fa-square"></i>
                          </div>
                        )}
                      </div>

                      <div className="create-invoice-page-end-received-row">
                        Amount Received
                        <div className="create-invoice-page-end-received-col">
                          <div className="create-invoice-page-end-received-input">
                            ₹
                            {fullyPaid ? (
                              <input
                                type="number"
                                value={totalAmount}
                                disabled
                              />
                            ) : (
                              <input
                                type="number"
                                name="receivedAmount"
                                value={data.receivedAmount}
                                max={totalAmount}
                                min={0}
                                onChange={handleChange}
                                ref={scrollRef4}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef4)
                                }
                              />
                            )}
                          </div>

                          <select
                            name="paymentType"
                            onChange={handleChange}
                            value={data.paymentType}
                          >
                            <option value="Cash">Cash</option>
                            <option value="UPI">UPI</option>
                            <option value="Card">Card</option>
                            <option value="Netbanking">Netbanking</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Cheque">Cheque</option>
                          </select>
                        </div>
                      </div>

                      {data.paymentType === "Bank Transfer" ||
                      data.paymentType === "UPI" ||
                      data.paymentType === "Card" ||
                      data.paymentType === "Cheque" ||
                      data.paymentType === "Netbanking" ? (
                        <div className="create-invoice-page-end-received-row">
                          Payment Received in
                          <select
                            onChange={handleChange}
                            name="selectedBankAccount"
                            value={data.selectedBankAccount}
                          >
                            <option value="" selected disabled>
                              Select
                            </option>
                            {businessData.bankData &&
                              businessData.bankData
                                .filter((e) => e.activateAccount === true)
                                .map((e) => (
                                  <option value={e._id}>{e.bankName}</option>
                                ))}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="create-invoice-page-end-hr"></div>

                    <div className="create-invoice-page-end-balance">
                      <div>Balance Amount</div>
                      {fullyPaid ? (
                        <div>0.0</div>
                      ) : (
                        <div>₹ {totalAmount - Number(data.receivedAmount)}</div>
                      )}
                    </div>

                    <div className="create-invoice-page-end-hr"></div>

                    {businessData.companySignatureType === "Box" ? (
                      <div className="create-invoice-page-end-signature">
                        <div className="create-invoice-page-end-signature-box"></div>
                        <div className="create-invoice-page-end-signature-heading">
                          Authorized signatory for{" "}
                          <b>{businessData.businessName}</b>
                        </div>
                      </div>
                    ) : businessData.companySignatureType === "Image" ? (
                      businessData.companySignature ? (
                        <div className="create-invoice-page-end-signature-image">
                          <img
                            src={`${link}/${businessData.companySignature}`}
                          />
                          <div className="create-invoice-page-end-signature-heading">
                            Authorized signatory for{" "}
                            <b>{businessData.businessName}</b>
                          </div>
                        </div>
                      ) : (
                        <div className="create-invoice-page-end-signature">
                          <div className="create-invoice-page-end-signature-heading">
                            Authorized signatory for{" "}
                            <b>{businessData.businessName}</b>
                          </div>
                          <Link
                            to="/app/setting/business"
                            target="_blank"
                            className="create-invoice-page-end-signature-option"
                          >
                            + Add Signature
                          </Link>
                        </div>
                      )
                    ) : (
                      <div className="create-invoice-page-end-signature">
                        <div className="create-invoice-page-end-signature-heading">
                          Authorized signatory for{" "}
                          <b>{businessData.businessName}</b>
                        </div>
                        <Link
                          to="/app/setting/business"
                          target="_blank"
                          className="create-invoice-page-end-signature-option"
                        >
                          + Add Signature
                        </Link>
                      </div>
                    )}
                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}
              </div>
            </div>

            {/* ----------------------------------------------------------------------------------------------------------- */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateSalesInoiceComponent;
