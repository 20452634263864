import React, { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const SettingBillBookThemeComponent = ({ data, businessData, settingEditOption }) => {


  
  const { link } = useContext(AuthContext);
  
  
  
  const mainColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--invoice")
    .trim();

  // Function to set RGBA color with opacity
  const rgbaColor = (opacity) => {
    const hex = mainColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  
  return (
    <div>
        
        <div className="invoice-bill-book-theme">
       <div className="invoice-gst-theme">

<div className="invoice-gst-theme-content">
<div className="invoice-gst-theme-head">

    <div  id="theme" className={settingEditOption === "theme"?"invoice-gst-theme-head-company invoice-excel-theme-active" : "invoice-gst-theme-head-company"}>

    {businessData.companyLogo ? (
    <div className="invoice-gst-theme-head-company-image">
      <img
                src={`${link}/${businessData.companyLogo}`}
                alt="profile"
              />
    </div>
            ) : (
              "" 
            )}
    
    <div className="invoice-gst-theme-head-company-content">
        <div className="invoice-gst-theme-head-company-name">{businessData && businessData.businessName}</div>

         {
                data.invoiceAddress?
              businessData.invoiceSettingData.invoiceAddress?
              <div className="invoice-gst-theme-head-company-text">
                
                 {
                businessData.companyBillingAddress?
                <>{businessData.companyBillingAddress},</>
              :""
              }
              {
                businessData.companyCity?
                <> {businessData.companyCity},</>
              :""
              }
              {
                businessData.companyState?
                <> {businessData.companyState},</>
              :""
              }
              {
                businessData.companyPincode?
                <> {businessData.companyPincode}</>
              :""
              }
              
              </div>
              :""
              :""
              }

              
{
     data.invoicePhoneNumber?
                   
        <div className="invoice-gst-theme-head-company-text"><b>Mobile: </b>{businessData.companyPhoneNumber ? businessData.companyPhoneNumber :"-------"}</div>
           
        :""
        }
        
        
{
    data.invoiceEmail?
        <div className="invoice-gst-theme-head-company-text"><b>Email: </b>{businessData.companyEmail?businessData.companyEmail:"--------"}</div>        
  :""
}
        
        
    {
    data.invoiceGSTIN?
        <div className="invoice-gst-theme-head-company-text"><b>GSTIN: </b>{businessData.companyGSTIN ?businessData.companyGSTIN :"-------------"}</div>
        :""
      }

    </div>

    </div>


</div>




<div className="invoice-gst-theme-head-address">
    
    <div className="invoice-gst-theme-head-address-content">
        <div className="invoice-gst-theme-head-address-heading">BILL TO</div>
        <div className="invoice-gst-theme-head-address-name">Party Name</div>
        <div className="invoice-gst-theme-head-address-text">Billing Address, City, State - 00000</div>
        <div className="invoice-gst-theme-head-address-text"><b>Mobile : </b>0987656056</div>
        <div className="invoice-gst-theme-head-address-text"><b>GSTIN : </b>987654567890</div>

    </div>

    
    <div id="invoice" className={settingEditOption === "invoice" ?"invoice-gst-theme-head-options invoice-excel-theme-active" : "invoice-gst-theme-head-options"}>
        
        <div className="invoice-gst-theme-head-row">
            
            <div className="invoice-gst-theme-head-col">
                <label>Invoice No.</label>
                <div>3</div>
            </div>

            <div className="invoice-gst-theme-head-col">
                <label>Invoice Date</label>
                <div>30/11/2024</div>
            </div>

            {/* <div className="invoice-gst-theme-head-col">
                <label>Due Date</label>
                <div>30/11/2024</div>
            </div> */}

        </div>
{
  data.invoiceDetailsOptions.length>0 || data.invoicePoNoOptions || data.invoiceVehicleNumberOptions
  ?
        <div className="invoice-gst-theme-head-row">
            
             
        {
                data.invoicePoNoOptions?
            <div className="invoice-gst-theme-head-col">
                <label>P.O. No.</label>
                <div>------</div>
            </div>
                :""
            }

     
{data.invoiceVehicleNumberOptions?
            <div className="invoice-gst-theme-head-col">
                <label>Vehicle No.</label>
                <div>------</div>
            </div>
                :""}


{
                            data.invoiceDetailsOptions?
                            data.invoiceDetailsOptions.map( e => (
            <div className="invoice-gst-theme-head-col">
                <label>{e}</label>
                <div>------</div>
            </div>
            
          ))
          :""
          }

        </div>
        :""
        }

    </div>


    
</div>

<div id="table" className={settingEditOption === "table" ?"invoice-gst-theme-table invoice-excel-theme-active" : "invoice-gst-theme-table"}>
    
<div className="invoice-gst-theme-table-head" style={{ backgroundColor: rgbaColor(0.2) }}>
    <div className='invoice-gst-theme-table-no'>No.</div>
    <div className='invoice-gst-theme-table-item'>items</div>
    <div className='invoice-gst-theme-table-hsn'>HSN</div>
    {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>qty.</div>:""}
    {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>rate</div>:""}
    <div className='invoice-gst-theme-table-tax'>tax</div>
    <div className='invoice-gst-theme-table-amount'>Amount</div>
</div>

<div className="invoice-gst-theme-table-data">

    <div className="invoice-gst-theme-table-body">
        <div className='invoice-gst-theme-table-no'>1</div>
        <div className='invoice-gst-theme-table-item'>Product name 1 {data.invoiceItemDescription?<div>Item Description.</div>:""}</div>
        <div className='invoice-gst-theme-table-hsn'>code1</div>
        {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>2 </div>:""}
        {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>500</div>:""}
        <div className='invoice-gst-theme-table-tax'>5%</div>
        <div className='invoice-gst-theme-table-amount'>1050</div>
    </div>

    <div className="invoice-gst-theme-table-body">
        <div className='invoice-gst-theme-table-no'>2</div>
        <div className='invoice-gst-theme-table-item'>Product name 2 </div>
        <div className='invoice-gst-theme-table-hsn'>--</div>
        {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>1 </div>:""}
        {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>0</div>:""}
        <div className='invoice-gst-theme-table-tax'>0%</div>
        <div className='invoice-gst-theme-table-amount'>0</div>
    </div>

    <div className="invoice-gst-theme-table-body">
        <div className='invoice-gst-theme-table-no'>3</div>
        <div className='invoice-gst-theme-table-item'>Service name {data.invoiceItemDescription?<div>Item Description.</div>:""}</div>
        <div className='invoice-gst-theme-table-hsn'>code3</div>  
        {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>1</div>:""}
        {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>1000</div>:""}
        <div className='invoice-gst-theme-table-tax'>18%</div>
        <div className='invoice-gst-theme-table-amount'>1180</div>
    </div>

</div>


{/* <div className="invoice-gst-theme-table-body">
        <div className='invoice-gst-theme-table-no'></div>
        <div className='invoice-gst-theme-table-item'><div><div>Discount</div></div></div>
        <div className='invoice-gst-theme-table-hsn'>-</div>
        {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>-</div>:""}
        {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>-</div>:""}
        <div className='invoice-gst-theme-table-tax'>-</div>
        <div className='invoice-gst-theme-table-amount'>-1256543</div>
    </div> */}



    <div className="invoice-gst-theme-table-end">

<div className="invoice-gst-theme-table-head" style={{ backgroundColor: rgbaColor(0.2) }}>
    <div className='invoice-gst-theme-table-no'></div>
    <div className='invoice-gst-theme-table-item'>Total</div>
    <div className='invoice-gst-theme-table-hsn'></div>
    {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'></div>:""}
    {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'></div>:""}
    <div className='invoice-gst-theme-table-tax'></div>
    <div className='invoice-gst-theme-table-amount'>₹ 2230</div>
</div>


    <div className="invoice-gst-theme-table-body">
        <div className='invoice-gst-theme-table-no'></div>
        <div className='invoice-gst-theme-table-item'>RECEIVED AMOUNT</div>
        <div className='invoice-gst-theme-table-hsn'>-</div>
        {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>-</div>:""}
        {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>-</div>:""}
        <div className='invoice-gst-theme-table-tax'>-</div>
        <div className='invoice-gst-theme-table-amount'>2000</div>
    </div>

    <div className="invoice-gst-theme-table-body">
        <div className='invoice-gst-theme-table-no'></div>
        <div className='invoice-gst-theme-table-item'>BALANCE AMOUNT</div>
        <div className='invoice-gst-theme-table-hsn'>-</div>
        {data.tabelQuantityOptions?<div className='invoice-gst-theme-table-qty'>-</div>:""}
        {data.tabelPriceOptions?<div className='invoice-gst-theme-table-rate'>-</div>:""}
        <div className='invoice-gst-theme-table-tax'>-</div>
        <div className='invoice-gst-theme-table-amount'>230</div>
    </div>


</div>
</div>


</div>



<div  id="miscellaneous" className={settingEditOption === "miscellaneous"?"invoice-gst-theme-end invoice-excel-theme-active" :"invoice-gst-theme-end"}>

    <div className="invoice-gst-theme-end-amount">
        <div className="invoice-gst-theme-end-heading">Total Amount (in words)</div>
        <div className="invoice-gst-theme-end-text">two thousand, two hundred thirty rupees</div>
    </div>

    <div className="invoice-gst-theme-end-row">

        <div className="invoice-gst-theme-end-bank">
            <div className="invoice-gst-theme-end-heading">Bank Details</div>
            
            <div className="invoice-gst-theme-end-bank-row">
                <div>Name:</div>
                <span>Account Name</span>
            </div>

            <div className="invoice-gst-theme-end-bank-row">
                <div>IFSC Code:</div>
                <span>BANKCODE</span>
            </div>

            <div className="invoice-gst-theme-end-bank-row">
                <div>Account No:</div>
                <span>1234567890</span>
            </div>

            <div className="invoice-gst-theme-end-bank-row">
                <div>Bank:</div>
                <span>Bank Name</span>
            </div>

        </div>



        {
 data.invoiceTermsConditions? 
        <div className="invoice-gst-theme-end-tc">
            <div className="invoice-gst-theme-end-heading">Terms and Conditions</div>
            <div className="invoice-gst-theme-end-text">
                <pre>
                {data.invoiceTermsConditions}
                </pre>
            </div>

        </div>
        :""}

{
    
    data.invoiceSignatureType === "Box"?

        <div className="invoice-gst-theme-end-signature">

            <div className="invoice-gst-theme-end-signature-box"></div>
            <div className="invoice-gst-theme-end-signature-text">
            Authorized Signatory For
            <div>{businessData && businessData.businessName}</div>
            </div>

        </div>


:
  data.invoiceSignatureType === "Image"?
  businessData.companySignature ?
        <div className="invoice-gst-theme-end-signature">

            <div className="invoice-gst-theme-end-signature-img">
            <img src={`${link}/${businessData.companySignature }`} alt="comapny signature"/>
            </div>
            <div className="invoice-gst-theme-end-signature-text">
            Authorized Signatory For
            <div>{businessData && businessData.businessName}</div>
            </div>

        </div>
:""
:""
}

        

    </div>


</div>




</div>
</div>
    </div>
  );
};

export default SettingBillBookThemeComponent;
