import React, { useRef } from "react";

const InvoiceOptionsComponent = ({
  businessData,
  handleKeyDown,
  handleChange,
  data,
  setData,
  editingDueDate,
  setEditingDueDate,
  detailsOptionValue,
setdetailsOptionValue,
dueDate
}) => {


  // set due date value ================================================================================================
  
  const handleDueDate = (e) => {
    const value = e.target.value;
    
    const invDate = new Date(data.invoiceDate);
    const newDate = new Date(invDate);
    newDate.setDate(invDate.getDate() + Number(value));
    
    setData({
      ...data,
      invoiceDueDate: newDate.toISOString().split("T")[0],
    });
  };
  
  
  //  ================================================================================================
  
  const handleDetailsOptions = (e) => {
    const { name, value } = e.target;
    setdetailsOptionValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    
    console.log("new values are : ", detailsOptionValue);
    // setdetailsOptionValue((prevValues) => ({
    //   ...prevValues,
    //   [key]: value,
    // }));
  };
  
  
  
  
  
  
  //  ================================================================================================
  //  ================================================================================================
  //  ================================================================================================
  //  ================================================================================================

  
  const scrollRef1 = useRef(null); // Create a ref for the input
  const scrollRef2 = useRef(null); // Create a ref for the input
  const scrollRef3 = useRef(null); // Create a ref for the input
  const scrollRef4 = useRef(null); // Create a ref for the input
  const scrollRef5 = useRef(null); // Create a ref for the input

  const handleWheelScroll = (event, ref) => {
    event.preventDefault(); // Prevent the scroll wheel from changing the input value
    if (ref.current) {
      ref.current.blur(); // Remove focus from the input
    }
  };
  
  
  
  return (
    <div>
      <div className="create-invoice-page-head-options">
        <div className="create-invoice-page-head-options-row">
          <div className="create-invoice-page-head-options-col">
            <label>Invoice No:</label>
            <input
              onKeyDown={handleKeyDown}
              required
              type="number"
              onChange={handleChange}
              name="invoiceNumber"
              value={data.invoiceNumber}
              ref={scrollRef1}
              onWheel={(e) => handleWheelScroll(e, scrollRef1)}
            />
          </div>

          <div className="create-invoice-page-head-options-col">
            <label>Invoice Date:</label>
            <input
              required
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              name="invoiceDate"
              type="date"
              id=""
              value={data.invoiceDate}
            />
          </div>
        </div>

        {
          dueDate==="Yes"?
        
        data.invoiceEnableDueDate ? (
          <div className="create-invoice-page-head-options-due">
            <div className="create-invoice-page-head-options-due-close">
              <i
                class="fa-solid fa-circle-xmark"
                onClick={() =>
                  setData({
                    ...data,
                    invoiceEnableDueDate: false,
                  })
                }
              ></i>
            </div>
            <div className="create-invoice-page-head-options-row">
              <div className="create-invoice-page-head-options-col">
                <label>Payment Terms:</label>
                <div className="create-invoice-page-head-options-terms">
                  {editingDueDate ? (
                    <input
                      onKeyDown={handleKeyDown}
                      onBlur={() => setEditingDueDate(false)}
                      autoFocus
                      type="number"
                      onChange={handleDueDate}
                      defaultValue={Math.ceil(
                        Math.abs(
                          new Date(data.invoiceDueDate) -
                            new Date(data.invoiceDate)
                        ) /
                          (1000 * 60 * 60 * 24)
                      )}
                      ref={scrollRef2}
                      onWheel={(e) => handleWheelScroll(e, scrollRef2)}
                    />
                  ) : (
                    <input
                      onFocus={() => setEditingDueDate(true)}
                      type="text"
                      value={Math.ceil(
                        Math.abs(
                          new Date(data.invoiceDueDate) -
                            new Date(data.invoiceDate)
                        ) /
                          (1000 * 60 * 60 * 24)
                      )}
                    />
                  )}
                  <span>days</span>
                </div>
              </div>

              <div className="create-invoice-page-head-options-col">
                <label>Due Date:</label>
                <input
                  required
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  name="invoiceDueDate"
                  type="date"
                  id=""
                  value={data.invoiceDueDate}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="create-invoice-page-head-options-due-option"
            onClick={() =>
              setData({
                ...data,
                invoiceEnableDueDate: true,
              })
            }
          >
            + Add Due Date
          </div>
        )
        :""
        }

        <div className="create-invoice-page-head-options-row">
          {businessData&&businessData.invoiceSettingData &&
          businessData.invoiceSettingData.invoiceDetailsOptions ? (
            <>
              {businessData.invoiceSettingData.invoiceDetailsOptions.map(
                (e) => (
                  <div className="create-invoice-page-head-options-col">
                    <label>{e}:</label>
                    <input
                      type="text"
                      name={e}
                      value={detailsOptionValue[e]}
                      onChange={(e) => handleDetailsOptions(e)}
                    />
                  </div>
                )
              )}
            </>
          ) : (
            ""
          )}

          {businessData&&businessData.invoiceSettingData ? (
            businessData&&businessData.invoiceSettingData.invoicePoNoOptions ? (
              <div className="create-invoice-page-head-options-col">
                <label>P.O. No.</label>
                <input type="text" onChange={handleChange} name="invoicePoNo" />
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {businessData&&businessData.invoiceSettingData ? (
            businessData&&businessData.invoiceSettingData.invoiceVehicleNumberOptions ? (
              <div className="create-invoice-page-head-options-col">
                <label>Vehicle No.</label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="invoiceVehicleNo"
                />
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceOptionsComponent;
