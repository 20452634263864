import React, { useEffect, useRef, useState } from "react";
import InvoiceSelectItemsPopup from "./InvoiceSelectItemsPopup";

const InvoiceItemsComponent = ({
  handleKeyDown,
  selectedItems,
  setSelectedItems,
  businessData,
  fetchBusinessData,
  formatNumber,
  calculateTaxedPrice,
  subTotal,
  totalTax,
  totalDiscount,
  handleWheelScroll,
  setCreateItem,
}) => {
  // useStates  ================================================================================================================

  const [selectItem, setSelectItem] = useState(false);

  const [viewItemDetails, setViewItemDetails] = useState(false);

  const [editingQuantity, setEditingQuantity] = useState(null);
  const [editingDiscount, setEditingDiscount] = useState(null);
  const [editingDiscountPercent, setEditingDiscountPercent] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);
  const [editingTax, setEditingTax] = useState(null);
  const [editingTotalPrice, setEditingTotalPrice] = useState(null);

  // handle table items and calculations  ================================================================================================================

  const handleAddItem = (item) => {
    if (!selectedItems.some((p) => p._id === item._id)) {
      setSelectedItems([
        ...selectedItems,
        {
          ...item,
          quantity: 1,
          discount: 0,
          itemGst:
            item.itemTax === "0"
              ? 0
              : item.itemTax === "0.1"
              ? 0.1
              : item.itemTax === "0.25"
              ? 0.25
              : item.itemTax === "1.5"
              ? 1.5
              : item.itemTax === "3"
              ? 3
              : item.itemTax === "5"
              ? 5
              : item.itemTax === "6"
              ? 6
              : item.itemTax === "12"
              ? 12
              : item.itemTax === "13.8"
              ? 13.8
              : item.itemTax === "14,12"
              ? 14
              : item.itemTax === "18"
              ? 18
              : item.itemTax === "28"
              ? 28
              : item.itemTax === "28,12"
              ? 28
              : item.itemTax === "28,60"
              ? 28
              : 0,
          cess:
            item.itemTax === "14,12"
              ? 12
              : item.itemTax === "28,12"
              ? 12
              : item.itemTax === "28,60"
              ? 60
              : 0,
        },
      ]);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p._id !== itemId));
  };

  const handleQuantityChange = (id, value) => {
    if ((value >= 1 && value <= 10000) || value === "") {
      if (value.trim() === "") {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id ? { ...item, quantity: 0 } : item
        );
        setSelectedItems(updatedQuantity);
      } else {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id
            ? { ...item, quantity: value.replace(/^0+/, "") }
            : item
        );
        setSelectedItems(updatedQuantity);
      }
    }
  };

  const handleIncreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: Number(e.quantity) + 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleDecreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity - 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleDescriptionChange = (id, value) => {
    const updatedDescription = selectedItems.map((item) =>
      item._id === id ? { ...item, itemDescription: value } : item
    );
    setSelectedItems(updatedDescription);
  };

  const handlePriceChange = (id, value) => {
    const price = parseFloat(value);
    if (value.trim() === "") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: 0 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: price } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleDiscountPercentChange = (id, value) => {
   
    if ((value >= 0 && value <= 100) || value === "") {
      if (value.trim() === "") {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id ? { ...item, discount: 0 } : item
        );
        setSelectedItems(updatedDiscount);
      } else {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id
            ? { ...item, discount: parseFloat(value.replace(/^0+/, "")) }
            : item
        );
        setSelectedItems(updatedDiscount);
      }
    }
  };

  const handleDiscountChange = (id, value, price) => {
    const val = (parseFloat(value) * 100) / parseFloat(price);

    if (value.trim() === "") {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: 0 } : item
      );
      setSelectedItems(updatedDiscount);
    } else {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: val } : item
      );
      setSelectedItems(updatedDiscount);
    }
  };

  const handleTaxChange = (id, value) => {
    const tax = parseFloat(value);

    // if (value.trim() === "") {
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemGst: 0 } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // } else {
    //   const newValue = value.replace(/^0+/, "");
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemGst: newValue } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // }

    if (value === "14,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: 14, cess: 12, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: 28, cess: 12, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,60") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: 28, cess: 60, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id
          ? { ...item, itemGst: value, cess: 0, itemTax: value }
          : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleTotalChange = (id, value, quantity, tax, cess, discount) => {
    const total = parseFloat(value);
    const product = selectedItems.find((p) => p._id === id);

    if (value.trim() === "") {
      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: 0 } : product
      );
      setSelectedItems(updatedProducts);
    } else {
      const basePrice = total / (1 + product.itemGst / 100);

      const price =
        total /
        (quantity * (1 + tax / 100 + cess / 100) * (1 - discount / 100));

      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: price } : product
      );
      setSelectedItems(updatedProducts);
    }
  };

  // handle tax option ====================================================================================================================

  const selectTaxRef = useRef(null);
  const taxListRef = useRef(null);

  const handleTaxOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      taxListRef.current &&
      !taxListRef.current.contains(event.target) &&
      selectTaxRef.current &&
      !selectTaxRef.current.contains(event.target)
    ) {
      setEditingTax(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTaxOutside);

    return () => {
      document.removeEventListener("mousedown", handleTaxOutside);
    };
  }, []);

  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  const scrollRef3 = useRef(null); // Create a ref for the input

  return (
    <div>
      {selectItem ? (
        <InvoiceSelectItemsPopup
          open={setSelectItem}
          handleKeyDown={handleKeyDown}
          businessData={businessData}
          fetchBusinessData={fetchBusinessData}
          formatNumber={formatNumber}
          selectedItems={selectedItems}
          handleAddItem={handleAddItem}
          handleRemoveItem={handleRemoveItem}
          handleQuantityChange={handleQuantityChange}
          handleIncreaseQuanitiy={handleIncreaseQuanitiy}
          handleDecreaseQuanitiy={handleDecreaseQuanitiy}
          setCreateItem={setCreateItem}
        />
      ) : (
        ""
      )}

      {/* small display==================================================================================== */}

      <div className="create-invoice-item-list">
        <div className="create-invoice-item-list-heading">Invoice Items </div>
        <div className="create-invoice-item-list-data">
          {selectedItems.map((item, i) => (
            <div className="invoice-small-page-select-item-col">
              <div className="invoice-small-page-select-item-col-details">
                <div className="invoice-small-page-select-item-col-name">
                  {i + 1}) {item.itemName}
                </div>
                {viewItemDetails === item._id ? (
                  <div className="invoice-small-page-select-item-view">
                    

                    <table className="invoice-small-page-select-item-view-table">

                    {item.itemCode ? (
                      <tr>
                        <td>Code :</td>
                        <td>{item.itemCode}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                      

                      {
                        businessData && businessData.invoiceSettingData?
                        businessData.invoiceSettingData.invoiceItemDescription ?
                      <tr>
                        <td>Description</td>
                        <td>
                          <textarea
                            type="text"
                            value={item.itemDescription}
                            onKeyDown={handleKeyDown}
                            onChange={(e) =>
                              handleDescriptionChange(item._id, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                      :""
                      :""
                      }

{
                        businessData && businessData.invoiceSettingData?
                        businessData.invoiceSettingData.tabelPriceOptions ?
                      <tr>
                        <td>Price</td>
                        <td>
                          {editingPrice === item._id ? (
                            <input
                              onKeyDown={handleKeyDown}
                              type="number"
                              defaultValue={formatNumber(
                                Number(item.itemPrice)
                              )}
                              placeholder="Enter new price"
                              onChange={(e) =>
                                handlePriceChange(item._id, e.target.value)
                              }
                              onBlur={() => setEditingPrice(null)}
                              autoFocus
                              min={0}
                              ref={scrollRef3}
                              onWheel={(e) => handleWheelScroll(e, scrollRef3)}
                            />
                          ) : (
                            <input
                              step="0.01"
                              onFocus={() => setEditingPrice(item._id)}
                              type="number"
                              min={0}
                              value={formatNumber(Number(item.itemPrice))}
                            />
                          )}
                        </td>
                      </tr>
                      :""
                      :""
                      }

                      <tr>
                        <td>Discount</td>
                        <td>
                          <div className="create-invoice-item-list-discount">
                            <div>
                              %
                              {editingDiscountPercent === item._id ? (
                                <>
                                  <input
                                    onKeyDown={handleKeyDown}
                                    type="number"
                                    value={formatNumber(item.discount)}
                                    onChange={(e) =>
                                      handleDiscountPercentChange(
                                        item._id,
                                        e.target.value
                                      )
                                    }
                                    onBlur={() =>
                                      setEditingDiscountPercent(null)
                                    }
                                    autoFocus
                                    min={0}
                                    max={100}
                                    ref={scrollRef3}
                                    onWheel={(e) =>
                                      handleWheelScroll(e, scrollRef3)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    onClick={() =>
                                      setEditingDiscountPercent(item._id)
                                    }
                                    onFocus={() =>
                                      setEditingDiscountPercent(item._id)
                                    }
                                    value={formatNumber(item.discount)}
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              ₹
                              {editingDiscount === item._id ? (
                                <>
                                  <input
                                    onKeyDown={handleKeyDown}
                                    type="number"
                                    defaultValue={formatNumber(
                                      (item.itemPrice *
                                        item.discount *
                                        item.quantity) /
                                        100
                                    )}
                                    onChange={(e) =>
                                      handleDiscountChange(
                                        item._id,
                                        e.target.value,
                                        item.itemPrice,
                                        item.quantity
                                      )
                                    }
                                    onBlur={() => setEditingDiscount(null)}
                                    autoFocus
                                    min={0}
                                    max={100}
                                    ref={scrollRef3}
                                    onWheel={(e) =>
                                      handleWheelScroll(e, scrollRef3)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    onClick={() => setEditingDiscount(item._id)}
                                    onFocus={() => setEditingDiscount(item._id)}
                                    value={formatNumber(
                                      (item.itemPrice *
                                        item.discount *
                                        item.quantity) /
                                        100
                                    )}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Tax</td>
                        <td className="create-invoice-item-list-tax">
                          <select
                            value={item.itemTax}
                            onChange={(e) =>
                              handleTaxChange(item._id, e.target.value)
                            }
                          >
                            <option value="0">None</option>
                            <option value="0">0%</option>
                            <option value="0.1"> 0.1%</option>
                            <option value="0.25">0.25%</option>
                            <option value="1.5">1.5%</option>
                            <option value="3">3%</option>
                            <option value="5">5%</option>
                            <option value="6">6%</option>
                            <option value="12">12%</option>
                            <option value="13.8">13.8%</option>
                            <option value="18">18%</option>
                            <option value="14,12">14% + cess @ 12%</option>
                            <option value="28">28%</option>
                            <option value="28,12">28% + Cess @ 12%</option>
                            <option value="28,60">28% + Cess @ 60%</option>
                          </select>
                          <div>
                            ₹{" "}
                            {formatNumber(
                              (((item.itemPrice -
                                (item.discount * item.itemPrice) / 100) *
                                item.itemGst) /
                                100 +
                                ((item.itemPrice -
                                  (item.discount * item.itemPrice) / 100) *
                                  item.cess) /
                                  100) *
                                item.quantity
                            )}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Total</td>
                        <td>
                          <div className="create-invoice-item-list-total">
                            {editingTotalPrice === item._id ? (
                              <>
                                ₹
                                <input
                                  onKeyDown={handleKeyDown}
                                  defaultValue={formatNumber(
                                    calculateTaxedPrice(
                                      item.itemPrice,
                                      item.itemGst,
                                      item.quantity,
                                      item.discount,
                                      item.cess
                                    )
                                  )}
                                  type="number"
                                  placeholder="Enter total price"
                                  onChange={(e) =>
                                    handleTotalChange(
                                      item._id,
                                      e.target.value,
                                      item.quantity,
                                      item.itemGst,
                                      item.cess,
                                      item.discount
                                    )
                                  }
                                  onBlur={() => setEditingTotalPrice(null)}
                                  autoFocus
                                  min={0}
                                  ref={scrollRef3}
                                  onWheel={(e) =>
                                    handleWheelScroll(e, scrollRef3)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                ₹
                                <input
                                  type="number"
                                  min={0}
                                  value={formatNumber(
                                    calculateTaxedPrice(
                                      item.itemPrice,
                                      item.itemGst,
                                      item.quantity,
                                      item.discount,
                                      item.cess
                                    )
                                  )}
                                  onFocus={() => setEditingTotalPrice(item._id)}
                                  onChange={() =>
                                    setEditingTotalPrice(item._id)
                                  }
                                  step="0.01"
                                />
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <div className="invoice-small-page-select-item-col-price">
                    ₹{" "}
                    {formatNumber(
                      calculateTaxedPrice(
                        item.itemPrice,
                        item.itemGst,
                        item.quantity,
                        item.discount,
                        item.cess
                      )
                    )}
                    <span onClick={() => setViewItemDetails(item._id)}>
                      <i className="fa-solid fa-pen"></i> edit
                    </span>
                  </div>
                )}
              </div>
              <div className="invoice-small-page-select-item-col-buttons">
                {selectedItems.find((e) => e._id === item._id) ? (
                  <div className="invoice-small-page-select-item-col-delete">

                    
{
                        businessData && businessData.invoiceSettingData?
                        businessData.invoiceSettingData.tabelQuantityOptions ?
                      <input
                      type="number"
                      defaultValue={
                        selectedItems.find((e) => e._id === item._id).quantity
                      }
                      onChange={(e) =>
                        handleQuantityChange(item._id, e.target.value)
                      }
                      min={0}
                      value={
                        selectedItems.find((e) => e._id === item._id).quantity
                      }
                    />
                    :""
                    :""
                    }

                    <i
                      onClick={() => handleRemoveItem(item._id)}
                      className="fa-solid fa-trash-can"
                    ></i>
                  </div>
                ) : (
                  <div
                    className="invoice-small-page-select-item-col-add"
                    onClick={() => handleAddItem(item)}
                  >
                    Add
                  </div>
                )}
                {viewItemDetails === item._id ? (
                  <div
                    onClick={() => setViewItemDetails(false)}
                    className="invoice-small-page-select-item-col-close"
                  >
                    close
                    <i className="fa-solid fa-angle-up" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
          <div className="create-invoice-item-list-col"></div>
        </div>
      </div>

      {/* web display==================================================================================== */}
      {/* table ----------------------------------------------------------------------------------------------------------- */}
      <div className="create-invoice-page-table">
        <div className="create-invoice-page-table-head">
          <div className="create-invoice-page-table-sno">No</div>
          <div className="create-invoice-page-table-items">Items</div>
          <div className="create-invoice-page-table-hsn">HSN/SAC</div>
          {businessData && businessData.invoiceSettingData ? (
            businessData.invoiceSettingData.tabelQuantityOptions ? (
              <div className="create-invoice-page-table-qty">QTY</div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {businessData && businessData.invoiceSettingData ? (
            businessData.invoiceSettingData.tabelPriceOptions ? (
              <div className="create-invoice-page-table-price">
                PRICE/ITEM (₹)
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div className="create-invoice-page-table-discount">DISCOUNT</div>
          <div className="create-invoice-page-table-tax">TAX</div>
          <div className="create-invoice-page-table-total">AMOUNT (₹)</div>
          <div className="create-invoice-page-table-add">
            <i class="fa-solid fa-plus"></i>
          </div>
        </div>

        {selectedItems.map((item, i) => (
          <div key={item._id} className="create-invoice-page-table-body">
            <div className="create-invoice-page-table-sno">{i + 1}</div>

            <div className="create-invoice-page-table-items">
              {item.itemName}

              {businessData && businessData.invoiceSettingData ? (
                businessData.invoiceSettingData.invoiceItemDescription ? (
                  <div>
                    <input
                      type="text"
                      value={item.itemDescription}
                      onKeyDown={handleKeyDown}
                      onChange={(e) =>
                        handleDescriptionChange(item._id, e.target.value)
                      }
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            <div className="create-invoice-page-table-hsn">
              {item.itemCode ? item.itemCode : "-"}
            </div>
            {businessData && businessData.invoiceSettingData ? (
              businessData.invoiceSettingData.tabelQuantityOptions ? (
                <div className="create-invoice-page-table-qty">
                  {editingQuantity === item._id ? (
                    <input
                      onKeyDown={handleKeyDown}
                      type="number"
                      value={item.quantity}
                      placeholder="Enter new price"
                      onChange={(e) =>
                        handleQuantityChange(item._id, e.target.value)
                      }
                      onBlur={() => setEditingQuantity(null)}
                      autoFocus
                      min={0}
                      ref={scrollRef3}
                      onWheel={(e) => handleWheelScroll(e, scrollRef3)}
                    />
                  ) : (
                    <input
                      onFocus={() => setEditingQuantity(item._id)}
                      type="number"
                      min={0}
                      value={item.quantity}
                    />
                  )}
                  &nbsp;
                  {item.itemMeasuringUnit ? item.itemMeasuringUnit : ""}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {businessData && businessData.invoiceSettingData ? (
              businessData.invoiceSettingData.tabelPriceOptions ? (
                <div className="create-invoice-page-table-price">
                  {editingPrice === item._id ? (
                    <input
                      onKeyDown={handleKeyDown}
                      type="number"
                      defaultValue={formatNumber(Number(item.itemPrice))}
                      placeholder="Enter new price"
                      onChange={(e) =>
                        handlePriceChange(item._id, e.target.value)
                      }
                      onBlur={() => setEditingPrice(null)}
                      autoFocus
                      min={0}
                      ref={scrollRef3}
                      onWheel={(e) => handleWheelScroll(e, scrollRef3)}
                    />
                  ) : (
                    <input
                      step="0.01"
                      onFocus={() => setEditingPrice(item._id)}
                      type="number"
                      min={0}
                      value={formatNumber(Number(item.itemPrice))}
                    />
                  )}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            <div className="create-invoice-page-table-discount">
              <div>
                {editingDiscountPercent === item._id ? (
                  <>
                    {" "}
                    %
                    <input
                      onKeyDown={handleKeyDown}
                      type="number"
                      value={formatNumber(item.discount)}
                      onChange={(e) =>
                        handleDiscountPercentChange(item._id, e.target.value)
                      }
                      onBlur={() => setEditingDiscountPercent(null)}
                      autoFocus
                      min={0}
                      max={100}
                      ref={scrollRef3}
                      onWheel={(e) => handleWheelScroll(e, scrollRef3)}
                    />
                  </>
                ) : (
                  <>
                    %{" "}
                    <input
                      type="text"
                      onClick={() => setEditingDiscountPercent(item._id)}
                      onFocus={() => setEditingDiscountPercent(item._id)}
                      value={formatNumber(item.discount)}
                    />
                  </>
                )}
              </div>
              <div>
                {editingDiscount === item._id ? (
                  <>
                    ₹
                    <input
                      onKeyDown={handleKeyDown}
                      type="number"
                      defaultValue={formatNumber(
                        (item.itemPrice * item.discount * item.quantity) / 100
                      )}
                      onChange={(e) =>
                        handleDiscountChange(
                          item._id,
                          e.target.value,
                          item.itemPrice,
                          item.quantity
                        )
                      }
                      onBlur={() => setEditingDiscount(null)}
                      autoFocus
                      min={0}
                      max={100}
                      ref={scrollRef3}
                      onWheel={(e) => handleWheelScroll(e, scrollRef3)}
                    />
                  </>
                ) : (
                  <>
                    ₹{" "}
                    <input
                      type="text"
                      onClick={() => setEditingDiscount(item._id)}
                      onFocus={() => setEditingDiscount(item._id)}
                      value={formatNumber(
                        (item.itemPrice * item.discount * item.quantity) / 100
                      )}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="create-invoice-page-table-tax">
              <input
                type="text"
                name=""
                id=""
                ref={selectTaxRef}
                onClick={() => setEditingTax(item._id)}
                value={formatNumber(
                  (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
                    item.itemGst) /
                    100 +
                    ((item.itemPrice - (item.discount * item.itemPrice) / 100) *
                      item.cess) /
                      100) *
                    item.quantity
                )}
              />

              {editingTax === item._id ? (
                <div
                  ref={taxListRef}
                  className="create-invoice-page-table-tax-select"
                >
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 0);
                      setEditingTax(null);
                    }}
                  >
                    None
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 0);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 0%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 0.1);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 0.1%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 0.25);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 0.25%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 1.5);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 1.5%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 3);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 3%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 5);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 5%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 6);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 6%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 12);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 12%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 13.8);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 13.8%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 18);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 18%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, "14,12");
                      setEditingTax(null);
                    }}
                  >
                    GST @ 14% + cess @ 12%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, 28);
                      setEditingTax(null);
                    }}
                  >
                    GST @ 28%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, "28,12");
                      setEditingTax(null);
                    }}
                  >
                    GST @ 28% + Cess @ 12%
                  </div>
                  <div
                    className="create-invoice-page-table-tax-option"
                    onClick={(e) => {
                      handleTaxChange(item._id, "28,60");
                      setEditingTax(null);
                    }}
                  >
                    GST @ 28% + Cess @ 60%
                  </div>
                </div>
              ) : (
                ""
              )}
              <div>
                (<>{item.itemGst}%</>
                {item.cess ? <> + {item.cess} Cess</> : ""})
              </div>
            </div>

            <div className="create-invoice-page-table-total">
              <div>
                {editingTotalPrice === item._id ? (
                  <>
                    ₹
                    <input
                      onKeyDown={handleKeyDown}
                      defaultValue={formatNumber(
                        calculateTaxedPrice(
                          item.itemPrice,
                          item.itemGst,
                          item.quantity,
                          item.discount,
                          item.cess
                        )
                      )}
                      type="number"
                      placeholder="Enter total price"
                      onChange={(e) =>
                        handleTotalChange(
                          item._id,
                          e.target.value,
                          item.quantity,
                          item.itemGst,
                          item.cess,
                          item.discount
                        )
                      }
                      onBlur={() => setEditingTotalPrice(null)}
                      autoFocus
                      min={0}
                      ref={scrollRef3}
                      onWheel={(e) => handleWheelScroll(e, scrollRef3)}
                    />
                  </>
                ) : (
                  <>
                    ₹
                    <input
                      type="number"
                      min={0}
                      value={formatNumber(
                        calculateTaxedPrice(
                          item.itemPrice,
                          item.itemGst,
                          item.quantity,
                          item.discount,
                          item.cess
                        )
                      )}
                      onFocus={() => setEditingTotalPrice(item._id)}
                      onChange={() => setEditingTotalPrice(item._id)}
                      step="0.01"
                    />
                  </>
                )}
              </div>
            </div>

            <div className="create-invoice-page-table-del">
              <i
                class="fa-solid fa-trash"
                onClick={() => handleRemoveItem(item._id)}
              ></i>
            </div>
          </div>
        ))}
      </div>

      <div
        onClick={() => {
          setSelectItem(true);
        }}
        className="create-invoice-page-table-add-item-option"
      >
        + Add Items
      </div>

      <div className="create-invoice-page-table-sub-total">
        <div>SUBTOTAL</div>
        <div>₹ {formatNumber(totalDiscount)}</div>
        <div>₹ {formatNumber(totalTax)}</div>
        <div>₹ {formatNumber(subTotal)}</div>
      </div>
    </div>
  );
};

export default InvoiceItemsComponent;
