import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateExpenseItemPopup from "./CreateExpenseItemPopup";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import { AuthContext } from "../../../context/AuthContext";
import PopUpAlertComponent from "../../../components/extra/PopUpAlertComponent";
import UpdateExpenseItemPopup from "./UpdateExpenseItemPopup";

const CreateExpenseComponent = ({ businessData, fetchBusinessData }) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [editItem, setEditItem] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [itemData, setItemData] = useState(null);

  const [popUpAlert, setPopUpAlert] = useState(false);

  const [updateItem, setUpdateItem] = useState(false);

  const [AddExtraDiscount, setAddExtraDiscount] = useState(false);

  const [expenseTotalAmount, setExpenseTotalAmount] = useState(0)
  

  const navigate = useNavigate();

  const [data, setData] = useState({

    withGst: false,
    expensePartyData: "",
    expenseCategory: "",
    expenseNumber: businessData && businessData.invoiceSettingData.newExpenseNumber ? businessData.invoiceSettingData.newExpenseNumber : 1,
    expenseDate: new Date().toISOString().split("T")[0],
    paymentType: "Cash",
    selectedBankAccount: "",
    expenseNote: "",
    expenseItemsData: [],
    expenseDiscount: 0,
    expenseTotalAmount: 0,
    
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });

    console.log("data is :", data.expensePartyData);
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ==========================================================================================================================================
  // ==========================================================================================================================================
  // ==========================================================================================================================================

  const [selectItem, setSelectItem] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const [createItem, setCreateItem] = useState(false);

  // ====================================================================================================================

  const itemOptionRef = useRef(null);
  const itemOptionListRef = useRef(null);

  const handleOptionOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      itemOptionListRef.current &&
      !itemOptionListRef.current.contains(event.target) &&
      itemOptionRef.current &&
      !itemOptionRef.current.contains(event.target)
    ) {
      setEditItem(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOptionOutside);

    return () => {
      document.removeEventListener("mousedown", handleOptionOutside);
    };
  }, []);

  // =====================================================================================

  // ====================================================================================================================

  // ====================================================================================================================

  const handleDescriptionChange = (id, value) => {
    const updatedDescription = selectedItems.map((item) =>
      item._id === id ? { ...item, itemDescription: value } : item
    );
    setSelectedItems(updatedDescription);
  };

  // ====================================================================================================================

  const handleAddItem = (item) => {
    if (!selectedItems.some((p) => p._id === item._id)) {
      setSelectedItems([
        ...selectedItems,
        {
          ...item,
          quantity: 1,
          discount: 0,
          itemGst:
            item.itemTax === "0"
              ? 0
              : item.itemTax === "0.1"
              ? 0.1
              : item.itemTax === "0.25"
              ? 0.25
              : item.itemTax === "1.5"
              ? 1.5
              : item.itemTax === "3"
              ? 3
              : item.itemTax === "5"
              ? 5
              : item.itemTax === "6"
              ? 6
              : item.itemTax === "12"
              ? 12
              : item.itemTax === "13.8"
              ? 13.8
              : item.itemTax === "14,12"
              ? 14
              : item.itemTax === "18"
              ? 18
              : item.itemTax === "28"
              ? 28
              : item.itemTax === "28,12"
              ? 28
              : item.itemTax === "28,60"
              ? 28
              : 0,
          cess:
            item.itemTax === "14,12"
              ? 12
              : item.itemTax === "28,12"
              ? 12
              : item.itemTax === "28,60"
              ? 60
              : 0,
        },
      ]);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p._id !== itemId));
  };

  const handleIncreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity + 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleDecreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity - 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleQuantityChange = (id, value) => {
    if ((value >= 1 && value <= 10000) || value === "") {
      if (value.trim() === "") {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id ? { ...item, quantity: 0 } : item
        );
        setSelectedItems(updatedQuantity);
      } else {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id
            ? { ...item, quantity: value.replace(/^0+/, "") }
            : item
        );
        setSelectedItems(updatedQuantity);
      }
    }
  };

  const handlePriceChange = (id, value) => {
    const price = parseFloat(value);
    if (value.trim() === "") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPurchasePrice: 0 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPurchasePrice: price } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleDiscountPercentChange = (id, value) => {
    // const val = parseFloat(value);

    if ((value >= 0 && value <= 100) || value === "") {
      if (value.trim() === "") {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id ? { ...item, discount: 0 } : item
        );
        setSelectedItems(updatedDiscount);
      } else {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id
            ? { ...item, discount: parseFloat(value.replace(/^0+/, "")) }
            : item
        );
        setSelectedItems(updatedDiscount);
      }
    }
  };

  const handleDiscountChange = (id, value, price) => {
    const val = (parseFloat(value) * 100) / parseFloat(price);

    if (value.trim() === "") {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: 0 } : item
      );
      setSelectedItems(updatedDiscount);
    } else {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: val } : item
      );
      setSelectedItems(updatedDiscount);
    }
  };

  const handleTaxChange = (id, value) => {
    const tax = parseFloat(value);

    // if (value.trim() === "") {
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemGst: 0 } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // } else {
    //   const newValue = value.replace(/^0+/, "");
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemGst: newValue } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // }

    if (value === "14,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: 14, cess: 12 } : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: 28, cess: 12 } : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,60") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: 28, cess: 60 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: value, cess: 0 } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleTotalChange = (id, value, quantity, tax, cess, discount) => {
    const total = parseFloat(value);
    const product = selectedItems.find((p) => p._id === id);

    if (value.trim() === "") {
      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPurchasePrice: 0 } : product
      );
      setSelectedItems(updatedProducts);
    } else {
      const basePrice = total / (1 + product.itemGst / 100);

      const price =
        total /
        (quantity * (1 + tax / 100 + cess / 100) * (1 - discount / 100));

      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPurchasePrice: price } : product
      );
      setSelectedItems(updatedProducts);
    }
  };

  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  const subTotal = selectedItems.reduce((acc, item) => {
    return (
      acc +
      calculateTaxedPrice(
        item.itemPurchasePrice,
        item.itemGst,
        item.quantity,
        item.discount,
        item.cess
      )
    );
  }, 0);

  const totalTax = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice -
        (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100 +
        ((item.itemPurchasePrice -
          (item.discount * item.itemPurchasePrice) / 100) *
          item.cess) /
          100) *
        item.quantity
    );
  }, 0);

  const totalDiscount = selectedItems.reduce((acc, item) => {
    return acc + (item.itemPurchasePrice * item.discount * item.quantity) / 100;
  }, 0);

  const totalGst = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice -
        (item.discount * item.itemPurchasePrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);

  const totalCess = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPurchasePrice -
        (item.discount * item.itemPurchasePrice) / 100) *
        item.cess) /
        100) *
        item.quantity
    );
  }, 0);

  const taxableAmount = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (item.itemPurchasePrice * item.quantity -
        (item.itemPurchasePrice * item.discount * item.quantity) / 100)
    );
  }, 0);

  
  
  const totalAmount = formatNumber(subTotal - data.expenseDiscount);

  // ====================================================================================================================
  // ====================================================================================================================

  const [editingQuantity, setEditingQuantity] = useState(null);
  const [editingDiscount, setEditingDiscount] = useState(null);
  const [editingDiscountPercent, setEditingDiscountPercent] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);
  const [editingTax, setEditingTax] = useState(null);
  const [editingTotalPrice, setEditingTotalPrice] = useState(null);

  // =========================================================================================

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const selectTaxRef = useRef(null);
  const taxListRef = useRef(null);

  const handleTaxOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      taxListRef.current &&
      !taxListRef.current.contains(event.target) &&
      selectTaxRef.current &&
      !selectTaxRef.current.contains(event.target)
    ) {
      setEditingTax(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTaxOutside);

    return () => {
      document.removeEventListener("mousedown", handleTaxOutside);
    };
  }, []);

  const inputSelectRef1 = useRef(null);
  const inputSelectRef2 = useRef(null);

  const handleSelectFocus = (ref) => {
    if (ref.current) {
      ref.current.select();
    }
  };

  // ==========================================================================================================================================
  // ==========================================================================================================================================
  // ==========================================================================================================================================

  const scrollRef1 = useRef(null); // Create a ref for the input
  const scrollRef2 = useRef(null); // Create a ref for the input
  const scrollRef3 = useRef(null); // Create a ref for the input
  const scrollRef4 = useRef(null); // Create a ref for the input
  const scrollRef5 = useRef(null); // Create a ref for the input

  const handleWheelScroll = (event, ref) => {
    event.preventDefault(); // Prevent the scroll wheel from changing the input value
    if (ref.current) {
      ref.current.blur(); // Remove focus from the input
    }
  };

  // ==========================================================================================================================================
  // ==========================================================================================================================================

  const handleExtraDiscount = (e) => {
    const value = e.target.value;

    if ((value >= 0 && value <= subTotal) || value === "") {
      setData({
        ...data,
        expenseDiscount: value,
      });
    }
  };

  const handleExtraDiscountPercentage = (e) => {
    const value = e.target.value;

    if ((value >= 0 && value <= 100) || value === "") {
      const dis = (subTotal * value) / 100;

      setData({
        ...data,
        expenseDiscount: dis,
      });
    }
  };

  // ==========================================================================================================================================
  // ==========================================================================================================================================

  // ==========================================================================================================================================
  // delete expense ===========================================================================================================================
  // ==========================================================================================================================================

  const { deleteSingleExpenseItem } = useContext(AuthContext);

  const handleDelete = async (e) => {
    await deleteSingleExpenseItem(
      userId,
      businessId,
      itemId,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus
    );
    fetchBusinessData && fetchBusinessData();
  };

  // ==========================================================================================================================================
  // ==========================================================================================================================================
  // ==========================================================================================================================================

  
  const handleSaveData = () => {
    setData({
      ...data,

      expensePartyData : data.expensePartyData ? businessData && businessData.partyData.find( e => e._id === data.expensePartyData ) : "",

      expenseItemsData : selectedItems? selectedItems :"",

      expenseTotalAmount : data.withGst === true ? totalAmount : selectedItems.length>0 ? totalAmount : expenseTotalAmount,
      
      
    });
    // alert(true)

    // console.log("data is : ", data);
  };
  


  const itemIncludeTax = selectedItems.filter(e => e.itemGst ).length

  
  const { createExpense, link } = useContext(AuthContext);

  const handleCreate = async (e) => {

    e.preventDefault();

    if( itemIncludeTax > 0 && data.withGst === false ){
      setTimerPopupAlert(true)
      setTimerAlertMessage("Party is required for expense with GST")
      setTimerAlertStatus("False")

    }else{

    await createExpense(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      navigate
    );
  }

  };
  
  
  // ==========================================================================================================================================
  // ==========================================================================================================================================

  return (
    
    <div>
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      {createItem ? (
        <CreateExpenseItemPopup
          open={setCreateItem}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete item data?`}
        />
      ) : (
        ""
      )}

      {updateItem ? (
        <UpdateExpenseItemPopup
          itemData={itemData}
          itemId={itemId}
          open={setUpdateItem}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {/* add item ----------------------------------------------------------------------------------------------------------- */}
      {selectItem ? (
        <div className="create-invoice-page-table-add-item">
          <div className="create-expense-page-table-add-item-main">
            <div className="create-invoice-page-table-add-item-top">
              Add Items
            </div>
            <div className="create-invoice-page-table-add-item-head">
              <div className="create-invoice-page-table-add-item-head-search">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input
                  onKeyDown={handleKeyDown}
                  type="text"
                  placeholder="search items"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
              </div>
              <div
                className="create-invoice-page-table-add-item-head-create"
                onClick={() => {
                  setCreateItem(true);
                }}
              >
                + Create New Item
              </div>
            </div>

            <div className="create-invoice-page-table-add-item-table">
              <div className="create-expense-page-table-add-item-table-head">
                <div className="create-expense-page-table-add-item-table-name">
                  Item Name
                </div>
                <div className="create-expense-page-table-add-item-table-code">
                  HSN/SAC
                </div>
                <div className="create-expense-page-table-add-item-table-stock">
                  Price
                </div>
              </div>

              <div className="create-invoice-page-table-add-item-table-body">
                {selectedItems.map((item) => (
                  <div
                    key={item._id}
                    className="create-expense-page-table-add-item-table-body-row"
                  >
                    <div className="create-expense-page-table-add-item-table-name">
                      {" "}
                      <i
                        class="fa-regular fa-square-check"
                        onClick={() => handleRemoveItem(item._id)}
                      ></i>{" "}
                      {item.itemName}
                    </div>

                    <div className="create-expense-page-table-add-item-table-code">
                      {item.itemHsnCode ? item.itemHsnCode : "-"}
                    </div>

                    <div className="create-expense-page-table-add-item-table-purchase">
                      {item.itemPurchasePrice ? (
                        <>
                          <>
                            {item.itemTax === "0" ||
                            item.itemTax === "0.1" ||
                            item.itemTax === "0.25" ||
                            item.itemTax === "1.5" ||
                            item.itemTax === "3" ||
                            item.itemTax === "5" ||
                            item.itemTax === "6" ||
                            item.itemTax === "12" ||
                            item.itemTax === "13.8" ||
                            item.itemTax === "18" ||
                            item.itemTax === "28" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPurchasePrice +
                                    (item.itemPurchasePrice *
                                      Number(item.itemTax)) /
                                      100
                                ) * 1}{" "}
                              </>
                            ) : item.itemTax === "14,12" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPurchasePrice +
                                    (item.itemPurchasePrice * 14) / 100 +
                                    (item.itemPurchasePrice * 12) / 100
                                ) * 1}{" "}
                              </>
                            ) : item.itemTax === "28,12" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPurchasePrice +
                                    (item.itemPurchasePrice * 28) / 100 +
                                    (item.itemPurchasePrice * 12) / 100
                                ) * 1}{" "}
                              </>
                            ) : item.itemTax === "28,60" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPurchasePrice +
                                    (item.itemPurchasePrice * 28) / 100 +
                                    (item.itemPurchasePrice * 60) / 100
                                ) * 1}{" "}
                              </>
                            ) : (
                              <>₹ {item.itemPurchasePrice}</>
                            )}
                          </>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>

                    <div className="create-expense-page-table-add-item-table-quantity">
                      <div className="create-invoice-page-table-add-item-table-quantity-added">
                        {item.quantity <= 1 ? (
                          <i
                            onClick={() => handleRemoveItem(item._id)}
                            class="fa-solid fa-square-minus"
                          ></i>
                        ) : (
                          <i
                            onClick={() => handleDecreaseQuanitiy(item)}
                            class="fa-solid fa-square-minus"
                          ></i>
                        )}

                        <input
                          type="number"
                          autoFocus
                          defaultValue={item.quantity}
                          onChange={(e) =>
                            handleQuantityChange(item._id, e.target.value)
                          }
                          min={0}
                          value={item.quantity}
                        />
                        <i
                          onClick={() => handleIncreaseQuanitiy(item)}
                          class="fa-solid fa-square-plus"
                        ></i>
                        <div>
                          {item.itemMeasuringUnit
                            ? item.itemMeasuringUnit
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {businessData.expenseItemData
                  .filter((e) =>
                    e.itemName
                      .toLocaleLowerCase()
                      .includes(searchItem.toLowerCase())
                  )

                  .filter((e) => !selectedItems.some((b) => e._id === b._id))
                  .reverse()
                  .map((item) => (
                    <div
                      key={item._id}
                      className="create-expense-page-table-add-item-table-body-row"
                    >
                      <div className="create-expense-page-table-add-item-table-name">
                        {" "}
                        <i
                          class="fa-regular fa-square"
                          onClick={() => handleAddItem(item)}
                        ></i>{" "}
                        {item.itemName}
                      </div>
                      <div className="create-expense-page-table-add-item-table-code">
                        {item.itemHsnCode ? item.itemHsnCode : "-"}
                      </div>

                      <div className="create-expense-page-table-add-item-table-purchase">
                        {item.itemPurchasePrice ? (
                          <>
                            <>
                              {item.itemTax === "0" ||
                              item.itemTax === "0.1" ||
                              item.itemTax === "0.25" ||
                              item.itemTax === "1.5" ||
                              item.itemTax === "3" ||
                              item.itemTax === "5" ||
                              item.itemTax === "6" ||
                              item.itemTax === "12" ||
                              item.itemTax === "13.8" ||
                              item.itemTax === "18" ||
                              item.itemTax === "28" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice *
                                        Number(item.itemTax)) /
                                        100
                                  ) * 1}{" "}
                                </>
                              ) : item.itemTax === "14,12" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice * 14) / 100 +
                                      (item.itemPurchasePrice * 12) / 100
                                  ) * 1}{" "}
                                </>
                              ) : item.itemTax === "28,12" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice * 28) / 100 +
                                      (item.itemPurchasePrice * 12) / 100
                                  ) * 1}{" "}
                                </>
                              ) : item.itemTax === "28,60" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice * 28) / 100 +
                                      (item.itemPurchasePrice * 60) / 100
                                  ) * 1}{" "}
                                </>
                              ) : (
                                <>₹ {item.itemPurchasePrice}</>
                              )}
                            </>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>

                      <div className="create-expense-page-table-add-item-table-add">
                        <div
                          onClick={() => handleAddItem(item)}
                          className="create-expense-page-table-add-item-table-add-button"
                        >
                          + Add
                        </div>

                        <div
                          className="create-expense-page-table-add-item-table-option"
                          ref={itemOptionRef}
                        >
                          <i
                            class="fa-solid fa-ellipsis-vertical"
                            onClick={() => setEditItem(item._id)}
                          ></i>
                          {editItem === item._id ? (
                            <div
                              className="create-expense-page-table-add-item-table-option-list"
                              ref={itemOptionListRef}
                            >
                              <div
                                onClick={() => {
                                  setItemId(item._id);
                                  setItemData(item);
                                  setUpdateItem(true);
                                }}
                              >
                                edit
                              </div>
                              <div
                                onClick={() => {
                                  setItemId(item._id);
                                  setPopUpAlert(true);
                                }}
                              >
                                delete
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                {businessData.expenseItemData.filter((e) =>
                  e.itemName
                    .toLocaleLowerCase()
                    .includes(searchItem.toLowerCase())
                ).length <= 0 ? (
                  <div className="create-invoice-page-table-add-item-table-empty">
                    No Expense Items
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="create-invoice-page-table-add-item-button">
              <div
                onClick={() => {
                  setSelectItem(false);
                  setSearchItem("");
                }}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form onSubmit={handleCreate} className="create-expense-page">
        <div className="create-expense-page-main">
          <div className="create-expense-page-main-top">
            <div className="create-expense-page-main-top-heading">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => navigate(-1)}
              ></i>
              Create Expense
            </div>
            <div className="create-expense-page-main-top-button">
              {/* <div className="create-expense-page-main-top-button-cancel">
                Cancel
              </div> */}
              <button
                className="create-expense-page-main-top-button-save"
                onClick={handleSaveData}
              >
                Save
              </button>
            </div>
          </div>

          <div className="create-expense-page-content">
            <div className="create-expense-page-content-section-1">
              <div>
                <div className="create-expense-page-content-section-1-gst">
                  <div>Expense With GST</div>
                  <input
                    type="checkbox"
                    name="withGst"
                    value={data.withGst}
                    onChange={(e) => {
                      setData({ ...data, withGst: e.target.checked });
                    }}
                  />
                </div>
                {data.withGst === true ? (
                  <div className="create-expense-page-content-section-1-col">
                    <label>Select Party</label>
                    <select
                      name="expensePartyData"
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        Select party
                      </option>
                      {businessData &&
                        businessData.partyData.map((e) => (
                          <option value={e._id}>{e.partyName}</option>
                        ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}

                <div className="create-expense-page-content-section-1-col">
                  <label>Expense Category</label>
                  <input
                  required
                    name="expenseCategory"
                    value={data.expenseCategory}
                    onChange={handleChange}
                    list="expense-category"
                  />
                  <datalist id="expense-category">
                    <option value="Bank Fee and Charges">
                      Bank Fee and Charges
                    </option>
                    <option value="Employee Salaries & Advances">
                      Employee Salaries & Advances
                    </option>
                    <option value="Printing and Stationery">
                      Printing and Stationery
                    </option>
                    <option value="Raw Material">Raw Material</option>
                    <option value="Rent Expense">Rent Expense</option>
                    <option value="Repair & Maintenance">
                      Repair & Maintenance
                    </option>
                    <option value="Telephone & Internet Expense">
                      Telephone & Internet Expense
                    </option>
                    <option value="Transportation & Travel Expense">
                      Transportation & Travel Expense
                    </option>
                  </datalist>
                </div>
              </div>
              {/* --------------------------------------------------------------- */}

              <div>
                <div className="create-expense-page-content-section-1-row">
                  <div className="create-expense-page-content-section-1-col">
                    <label>Expense Number</label>
                    <input
                    required
                      name="expenseNumber"
                      value={data.expenseNumber}
                      onChange={handleChange}
                      type="number"
                    />
                  </div>

                  <div className="create-expense-page-content-section-1-col">
                    <label>Date</label>
                    <input
                    required
                      name="expenseDate"
                      value={data.expenseDate}
                      onChange={handleChange}
                      type="date"
                    />
                  </div>

                  <div className="create-expense-page-content-section-1-col">
                    <label>Payment Mode</label>

                    <select
                      name="paymentType"
                      value={data.paymentType}
                      onChange={handleChange}
                    >
                      <option value="Cash" id="">
                        Cash
                      </option>
                      <option value="UPI" id="">
                        UPI
                      </option>
                      <option value="Card" id="">
                        Card
                      </option>
                      <option value="Netbanking" id="">
                        Netbanking
                      </option>
                      <option value="Bank Transfer" id="">
                        Bank Transfer
                      </option>
                      <option value="Cheque" id="">
                        Cheque
                      </option>
                    </select>
                  </div>

{
  
  data.paymentType === "Bank Transfer" ||
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Cheque" ||
  data.paymentType === "Netbanking" ?


                  <div className="create-expense-page-content-section-1-col">
                    <label>Payment Made From</label>

                    <select
                      name="selectedBankAccount"
                      value={data.selectedBankAccount}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        {" "}
                        select{" "}
                      </option>

                      {
                        businessData && businessData.bankData.map( e => (
                          <option value={e._id}>{e.bankName}</option>
                        ))
                      }
                      
                    </select>
                  </div>
:""}


                </div>

                <div className="create-expense-page-content-section-1-col">
                  <label>Note</label>
                  <textarea
                    name="expenseNote"
                    value={data.expenseNote}
                    onChange={handleChange}
                    placeholder="Enter Notes"
                  />
                </div>
              </div>
            </div>

            {data.withGst ? (
              <div className="create-expense-page-content-section-3">
                <div className="create-invoice-page-table">
                  <div className="create-invoice-page-table-head">
                    <div className="create-invoice-page-table-sno">No</div>
                    <div className="create-invoice-page-table-items">Items</div>
                    <div className="create-invoice-page-table-hsn">HSN/SAC</div>

                    <div className="create-invoice-page-table-qty">QTY</div>

                    <div className="create-invoice-page-table-price">
                      PRICE/ITEM (₹)
                    </div>

                    <div className="create-invoice-page-table-discount">
                      DISCOUNT
                    </div>
                    <div className="create-invoice-page-table-tax">TAX</div>
                    <div className="create-invoice-page-table-total">
                      AMOUNT (₹)
                    </div>
                    <div className="create-invoice-page-table-add">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>

                  {selectedItems.map((item, i) => (
                    <div
                      key={item._id}
                      className="create-invoice-page-table-body"
                    >
                      <div className="create-invoice-page-table-sno">
                        {i + 1}
                      </div>

                      <div className="create-invoice-page-table-items">
                        {item.itemName}

                      </div>

                      <div className="create-invoice-page-table-hsn">
                        {item.itemCode ? item.itemCode : "-"}
                      </div>
                      
                      <div className="create-invoice-page-table-qty">
                            {editingQuantity === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                value={item.quantity}
                                placeholder="Enter new price"
                                onChange={(e) =>
                                  handleQuantityChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingQuantity(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            ) : (
                              <input
                                onFocus={() => setEditingQuantity(item._id)}
                                type="number"
                                min={0}
                                value={item.quantity}
                              />
                            )}
                            &nbsp;
                            {item.itemMeasuringUnit
                              ? item.itemMeasuringUnit
                              : ""}
                          </div>

                     
                     <div className="create-invoice-page-table-price">
                            {editingPrice === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={formatNumber(
                                  Number(item.itemPurchasePrice)
                                )}
                                placeholder="Enter new price"
                                onChange={(e) =>
                                  handlePriceChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingPrice(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            ) : (
                              <input
                                step="0.01"
                                onFocus={() => setEditingPrice(item._id)}
                                type="number"
                                min={0}
                                value={formatNumber(
                                  Number(item.itemPurchasePrice)
                                )}
                              />
                            )}
                          </div>
                          

                      <div className="create-invoice-page-table-discount">
                        <div>
                          {editingDiscountPercent === item._id ? (
                            <>
                              {" "}
                              %
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                value={formatNumber(item.discount)}
                                onChange={(e) =>
                                  handleDiscountPercentChange(
                                    item._id,
                                    e.target.value
                                  )
                                }
                                onBlur={() => setEditingDiscountPercent(null)}
                                autoFocus
                                min={0}
                                max={100}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              %{" "}
                              <input
                                type="text"
                                onClick={() =>
                                  setEditingDiscountPercent(item._id)
                                }
                                onFocus={() =>
                                  setEditingDiscountPercent(item._id)
                                }
                                value={formatNumber(item.discount)}
                              />
                            </>
                          )}
                        </div>
                        <div>
                          {editingDiscount === item._id ? (
                            <>
                              ₹
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={formatNumber(
                                  (item.itemPurchasePrice *
                                    item.discount *
                                    item.quantity) /
                                    100
                                )}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    item._id,
                                    e.target.value,
                                    item.itemPurchasePrice,
                                    item.quantity
                                  )
                                }
                                onBlur={() => setEditingDiscount(null)}
                                autoFocus
                                min={0}
                                max={100}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              ₹{" "}
                              <input
                                type="text"
                                onClick={() => setEditingDiscount(item._id)}
                                onFocus={() => setEditingDiscount(item._id)}
                                value={formatNumber(
                                  (item.itemPurchasePrice *
                                    item.discount *
                                    item.quantity) /
                                    100
                                )}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-tax">
                        <input
                          type="text"
                          name=""
                          id=""
                          ref={selectTaxRef}
                          onClick={() => setEditingTax(item._id)}
                          value={formatNumber(
                            (((item.itemPurchasePrice -
                              (item.discount * item.itemPurchasePrice) / 100) *
                              item.itemGst) /
                              100 +
                              ((item.itemPurchasePrice -
                                (item.discount * item.itemPurchasePrice) /
                                  100) *
                                item.cess) /
                                100) *
                              item.quantity
                          )}
                        />

                        {editingTax === item._id ? (
                          <div
                            ref={taxListRef}
                            className="create-invoice-page-table-tax-select"
                          >
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0);
                                setEditingTax(null);
                              }}
                            >
                              None
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0.1);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0.1%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0.25);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0.25%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 1.5);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 1.5%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 3);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 3%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 5);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 5%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 6);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 6%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 12);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 13.8);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 13.8%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 18);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 18%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "14,12");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 14% + cess @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 28);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "28,12");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28% + Cess @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "28,60");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28% + Cess @ 60%
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          (<>{item.itemGst}%</>
                          {item.cess ? <> + {item.cess} Cess</> : ""})
                        </div>
                      </div>

                      <div className="create-invoice-page-table-total">
                        <div>
                          {editingTotalPrice === item._id ? (
                            <>
                              ₹
                              <input
                                onKeyDown={handleKeyDown}
                                defaultValue={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPurchasePrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                type="number"
                                placeholder="Enter total price"
                                onChange={(e) =>
                                  handleTotalChange(
                                    item._id,
                                    e.target.value,
                                    item.quantity,
                                    item.itemGst,
                                    item.cess,
                                    item.discount
                                  )
                                }
                                onBlur={() => setEditingTotalPrice(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              ₹
                              <input
                                type="number"
                                min={0}
                                value={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPurchasePrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                onFocus={() => setEditingTotalPrice(item._id)}
                                onChange={() => setEditingTotalPrice(item._id)}
                                step="0.01"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-del">
                        <i
                          class="fa-solid fa-trash"
                          onClick={() => handleRemoveItem(item._id)}
                        ></i>
                      </div>

                      
                    </div>
                  ))}
                </div>

                <div
                  onClick={() => setSelectItem(true)}
                  className="create-invoice-page-table-add-item-option"
                >
                  + Add Item
                </div>

                <div className="create-invoice-page-table-sub-total">
                  <div>SUBTOTAL</div>

                  <div>₹ {formatNumber(totalDiscount)}</div>
                  <div>₹ {formatNumber(totalTax)}</div>
                  <div>₹ {formatNumber(subTotal)}</div>
                </div>

                <div className="create-invoice-page-end">
                  <div className="create-invoice-page-end-col-1"></div>

                  <div className="create-invoice-page-end-amount">
                    {/* <div className="create-invoice-page-end-link">+ Add Additional Charges</div> */}

                    <div className="create-invoice-page-end-amount-row">
                      <div>Taxable Amount</div>
                      <div>₹ {formatNumber(taxableAmount) * 1}</div>
                    </div>
                    {totalGst ? (
                      <>
                        <div className="create-invoice-page-end-amount-row">
                          <div>SGST</div>
                          <div>₹ {formatNumber(totalGst) / 2}</div>
                        </div>

                        <div className="create-invoice-page-end-amount-row">
                          <div>CGST</div>
                          <div>₹ {formatNumber(totalGst) / 2}</div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {totalCess ? (
                      <div className="create-invoice-page-end-amount-row">
                        <div>CESS</div>
                        <div>₹ {formatNumber(totalCess) * 1}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="create-invoice-page-end-amount-discount">
                      {AddExtraDiscount ? (
                        <div className="create-invoice-page-end-amount-discount-row">
                          <div>Discount </div>

                          <div className="create-invoice-page-end-amount-discount-col">
                            <div className="create-invoice-page-end-amount-discount-input">
                              %
                              <input
                                type="number"
                                value={
                                  formatNumber(
                                    (data.expenseDiscount * 100) / subTotal
                                  ) * 1
                                }
                                onChange={handleExtraDiscountPercentage}
                                max={100}
                                ref={inputSelectRef1}
                                onFocus={() =>
                                  handleSelectFocus(inputSelectRef1)
                                }
                                onWheel={(e) =>
                                  handleWheelScroll(e, inputSelectRef1)
                                }
                              />
                            </div>

                            <div className="create-invoice-page-end-amount-discount-input">
                              ₹
                              <input
                                value={
                                  formatNumber(data.expenseDiscount * 1) *
                                  1
                                }
                                type="number"
                                name="editingExtraDiscount"
                                onChange={handleExtraDiscount}
                                ref={inputSelectRef2}
                                onFocus={() =>
                                  handleSelectFocus(inputSelectRef2)
                                }
                                onWheel={(e) =>
                                  handleWheelScroll(e, inputSelectRef2)
                                }
                              />
                            </div>

                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddExtraDiscount(false);
                                setData({
                                  ...data,
                                  expenseDiscount: 0,
                                });
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="create-invoice-page-end-link"
                          onClick={() => setAddExtraDiscount(true)}
                        >
                          + Add Discount
                        </div>
                      )}
                    </div>

                    <div className="create-invoice-page-end-hr"></div>

                    <div className="create-invoice-page-end-total">
                      <div>Total Amount</div>
                      <div>₹ {totalAmount}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="create-expense-page-content-section-2">

                {
                  selectedItems.length>0?
                
                <div className="create-expense-page-content-table">
                  <div className="create-expense-page-content-table-head">

                    <div>NO</div>
                    <div>ITEMS</div>
                    <div>QTY</div>
                    <div>PRICE/ITEM</div>
                    <div>AMOUNT</div>
                    <div></div>
                  </div>

                  <div className="create-expense-page-content-table-data-body">
                  <div className="create-expense-page-content-table-body">


 {selectedItems.map((item, i) => (
                    < >
                      <div>
                        {i + 1}
                      </div>

                      <div>{item.itemName}</div>

                      
                      <div>
                            {editingQuantity === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                value={item.quantity}
                                placeholder="Enter new price"
                                onChange={(e) =>
                                  handleQuantityChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingQuantity(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            ) : (
                              <input
                                onFocus={() => setEditingQuantity(item._id)}
                                type="number"
                                min={0}
                                value={item.quantity}
                              />
                            )}
                          </div>


                     <div>
                            {editingPrice === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={formatNumber(
                                  Number(item.itemPurchasePrice)
                                )}
                                placeholder="Enter new price"
                                onChange={(e) =>
                                  handlePriceChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingPrice(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            ) : (
                              <input
                                step="0.01"
                                onFocus={() => setEditingPrice(item._id)}
                                type="number"
                                min={0}
                                value={formatNumber(
                                  Number(item.itemPurchasePrice)
                                )}
                              />
                            )}
                          </div>
                          


                      <div>
                        <div>
                          {editingTotalPrice === item._id ? (
                            <>
                              <input
                                onKeyDown={handleKeyDown}
                                defaultValue={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPurchasePrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                type="number"
                                placeholder="Enter total price"
                                onChange={(e) =>
                                  handleTotalChange(
                                    item._id,
                                    e.target.value,
                                    item.quantity,
                                    item.itemGst,
                                    item.cess,
                                    item.discount
                                  )
                                }
                                onBlur={() => setEditingTotalPrice(null)}
                                autoFocus
                                min={0}
                                ref={scrollRef3}
                                onWheel={(e) =>
                                  handleWheelScroll(e, scrollRef3)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <input
                                type="number"
                                min={0}
                                value={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPurchasePrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                onFocus={() => setEditingTotalPrice(item._id)}
                                onChange={() => setEditingTotalPrice(item._id)}
                                step="0.01"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div>
                        <i
                          class="fa-solid fa-trash"
                          onClick={() => handleRemoveItem(item._id)}
                        ></i>
                      </div>
                    </>
                  ))}

                    
                    
                  </div>
                  </div>
                </div>

                :""}

                <div
                  className="create-expense-page-content-section-2-add"
                  onClick={() => setSelectItem(true)}
                >
                  + Add Item
                </div>

                <div className="create-expense-page-total-amount">
                  <label>Total Expense Amount</label>

{
  selectedItems.length>0?
  <div>₹ {formatNumber(subTotal)}</div>

  :
                  
                  
                  <input
                    value={expenseTotalAmount}
                    onChange={ e => setExpenseTotalAmount(e.target.value)}
                    required
                    type="number"
                    placeholder="₹ 0"
                  />

                  }

                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateExpenseComponent;
