import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const CreateStaffTransaction = ({
  open,
  staffData,
  staffId,
  businessData,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // ================================================================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  const today = new Date();
  const currentMonth =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1).toString().padStart(2, "0");

  // useStates  ================================================================================================================

  const [copmleteSalary, setCopmleteSalary] = useState(false);

  const [data, setData] = useState({
    staffId : staffId,
    staffData : staffData,
    expenseNumber: businessData && businessData.invoiceSettingData.newExpenseNumber ? businessData.invoiceSettingData.newExpenseNumber : 1,
    transactionType: "Salary",
    paymentMonth: currentMonth,
    transactionAmount: 0,
    transactionDate: new Date().toISOString().split("T")[0],
    paymentType: "Cash",
    selectedBankAccount: "",
    transactionRemark: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  //   --------------------------------------------------------------------------------------------------------------

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };
  
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const generateDatesFromTo = (monthDate) => {
      const year = Number(monthDate.split("-")[0]);
      const month = Number(monthDate.split("-")[1]) - 1;

      // console.log("month is :", month);
      // console.log("year is :", year);

      const startDate = new Date(
        year,
        month,
        Number(staffData && staffData.staffSalaryCycle)
      );

      const endDate = new Date(
        year,
        month + 1,
        Number(staffData && staffData.staffSalaryCycle) - 1
      );

      // console.log("start is :", startDate);

      const days = [];
      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        if (date <= new Date()) {
          days.push(date.toDateString());
        }
      }
      return days;
    };

    setDates(generateDatesFromTo(data.paymentMonth).reverse()); // Set dates from the 10th to 10th range
  }, [data.paymentMonth]);

  // console.log("dates are:", dates);

  // ============================================================================================

  let firstDate = new Date(dates[dates.length - 1]);

  let setLastDate = new Date(dates[dates.length - 1]);
  setLastDate.setMonth(setLastDate.getMonth() + 1);

  let lastDate = new Date(setLastDate);

  //  total days =======================================

  // Get the difference in milliseconds
  const differenceInMilliseconds = lastDate - firstDate;

  // Convert milliseconds to days (1000ms = 1 second, 60 seconds = 1 minute, 60 minutes = 1 hour, 24 hours = 1 day)
  const totalDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // ====================================================================================================================

  const filteredMonthData = staffData.attendanceData.filter(
    (e) =>
      new Date(e.date) >= new Date(firstDate) &&
      new Date(e.date) <= new Date(lastDate)
  );

  const totalPresent = filteredMonthData.filter(
    (e) => e.attendanceStatus === "present"
  ).length;
  const totalHD = filteredMonthData.filter(
    (e) => e.attendanceStatus === "half-day"
  ).length;
  const totalPL = filteredMonthData.filter(
    (e) => e.attendanceStatus === "paid-leave"
  ).length;
  const totalWO = filteredMonthData.filter(
    (e) => e.attendanceStatus === "week-off"
  ).length;

  // ====================================================================================================================
  // ====================================================================================================================

  const dailySalary = staffData.staffSalary / totalDays;

  const payDays = totalPresent + totalPL + totalWO + totalHD / 2;

  const totalEarning = dailySalary * payDays;

  
  
  // ====================================================================================================================
  
  const previousTransaction = businessData.staffTransactionData.filter(e => e.staffId === staffId).filter(e => e.paymentMonth === data.paymentMonth).filter(e=> e.transactionType === "Salary").reduce( (sum, e) => sum + e.transactionAmount ,0)
  console.log("totalEarning are :", previousTransaction);

  const balanceSalary = totalEarning - previousTransaction;
  
  
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleSaveData = () =>{
    setData({
      ...data,
      transactionAmount : copmleteSalary === true ? balanceSalary : data.transactionAmount,
    })
  }
  
  
  const { createStaffTransaction } = useContext(AuthContext);

  const handleCreate = async (e) => {
    e.preventDefault();

    if (data.transactionType === "Salary" && data.transactionAmount > balanceSalary){
      setTimerPopupAlert(true);
      setTimerAlertMessage("Amount is more than pending salary");
      setTimerAlertStatus("False");
      
    }
    else{
      await createStaffTransaction(
        userId,
        businessId,
        data,
        setTimerPopupAlert,
        setTimerAlertMessage,
        setTimerAlertStatus,
        open
      );

      fetchBusinessData && fetchBusinessData();

    }
    


  };

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  
  
  return (
    <div>
      {/* <form className="create-party-popup"> */}
      <form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Make Payment</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">
            <div className="create-party-popup-form-row">
              <div className="create-party-popup-form-col">
                <label>
                  Transaction Type <span>*</span>
                </label>
                <select
                  required
                  name="transactionType"
                  value={data.transactionType}
                  onChange={handleChange}
                >
                  <option value="Salary">Salary</option>
                  <option value="Bonus">Bonus</option>
                  <option value="Advance">Advance payment</option>
                  <option value="Loan">Loan</option>
                </select>
              </div>

              {data.transactionType === "Salary" ||
              data.transactionType === "Bonus" ||
              data.transactionType === "Advance" ? (
                <div className="create-party-popup-form-col">
                  <label>
                    {data.transactionType} Month<span>*</span>
                  </label>
                  <input
                    type="month"
                    required
                    onChange={handleChange}
                    name="paymentMonth"
                    placeholder=""
                    value={data.paymentMonth}
                    max={currentMonth}
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="create-party-popup-form-col">
                <div className="create-staff-amount-heading">
                  <label>Amount</label>{" "}
                  {
                    data.transactionType === "Salary"?
                    
                  copmleteSalary?
                  
                  <span className="create-staff-amount-heading-active" onClick={ () => setCopmleteSalary(false)}>
                    {" "}
                    <i className="fa-regular fa-square-check"></i>complete salary
                  </span>
                  :
                  <span onClick={ () => setCopmleteSalary(true)}>
                    {" "}
                    <i className="fa-regular fa-square"></i>complete salary
                  </span>
                  :""
                  }

                </div>

                { data.transactionType === "Salary" && copmleteSalary ? (
                  <input disabled value={formatNumber(balanceSalary)} placeholder="₹ 0" />
                ) : (
                  <input
                    required
                    type="number"
                    onChange={handleChange}
                    name="transactionAmount"
                    value={data.transactionAmount}
                    placeholder="₹ 0"
                    min={0}
                  />
                )}
                {data.transactionType === "Salary"?
                <div className="create-staff-pending-amount">pending salary <b> ₹ {formatNumber(balanceSalary)}</b></div>
                :""}
              </div>

              <div className="create-party-popup-form-col">
                <label>
                  Date<span>*</span>
                </label>
                <input
                  type="date"
                  required
                  onChange={handleChange}
                  name="transactionDate"
                  placeholder=""
                  value={data.transactionDate}
                />
              </div>

              <div className="create-party-popup-form-col">
                <label>Payment Type</label>

                <select
                  required
                  onChange={handleChange}
                  name="paymentType"
                  value={data.paymentType}
                  id=""
                >
                  <option value="Cash">Cash</option>
                  <option value="UPI">UPI</option>
                  <option value="Card">Card</option>
                  <option value="Netbanking">Netbanking</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cheque">Cheque</option>
                </select>
              </div>
{
  data.paymentType === "UPI" ||
  data.paymentType === "Card" ||
  data.paymentType === "Netbanking" ||
  data.paymentType === "Bank Transfer" ||
  data.paymentType === "Cheque" ?

              <div className="create-party-popup-form-col">
                <label>
                  Payment from<span>*</span>
                </label>

                <select
                  onChange={handleChange}
                  name="selectedBankAccount"
                  value={data.selectedBankAccount}
                >
                  <option value="" selected disables>
                    select
                  </option>

                  {
                  businessData && businessData.bankData.map( e => (
                  <option value={e._id}>{e.bankName}</option>
                  )
                  )
                  }


                  
                </select>
              </div>

              :""}
            </div>

            <div className="create-party-popup-form-col">
              <label>Remark (optional)</label>
              <input
                onChange={handleChange}
                name="transactionRemark"
                value={data.transactionRemark}
                placeholder=""
              />
            </div>
          </div>

          <div className="create-party-popup-buttons">
            <div
              className="create-party-popup-button-cancel"
              onClick={() => open(false)}
            >
              Cancel
            </div>
            <button className="create-party-popup-button-save" onClick={handleSaveData}>Save</button>
            {/* <button className="create-party-popup-button-save">Save</button> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateStaffTransaction;
