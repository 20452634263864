import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const UpdateAccountPopup = ({
  open,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
  bankId,
  bankData,
  fetchBusinessData,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  console.log("bank id is: ", bankId)
  
  // useStates  ================================================================================================================

  const [data, setData] = useState({
    asOfDate: bankData.asOfDate? bankData.asOfDate : new Date().toISOString().split("T")[0],
    accountName: bankData.accountName? bankData.accountName : "",
    openingBalance: bankData.openingBalance? bankData.openingBalance : 0,
    addBankDetails: bankData.addBankDetails? bankData.addBankDetails : true,
    accountNumber: bankData.accountNumber? bankData.accountNumber : "",
    IFSCcode: bankData.IFSCcode? bankData.IFSCcode : "",
    bankName: bankData.bankName? bankData.bankName : "",
    accountHolderName: bankData.accountHolderName? bankData.accountHolderName : "",
    upiId: bankData.upiId? bankData.upiId : "",
    activateAccount: bankData.activateAccount !== undefined ? bankData.activateAccount : true,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  //   --------------------------------------------------------------------------------------------------------------

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const { updateBank } = useContext(AuthContext);

  const handleCreate = async (e) => {
    e.preventDefault();
    await updateBank(
      userId,
      businessId,
      bankId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open,
      fetchBusinessData,
    );
    
  };

  return (
    <div>
      {/* <form className="create-party-popup"> */}
      <form onSubmit={handleCreate} className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Update Bank Account</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">
            {/* <div className="create-party-popup-form-col">
              <label>
                Account Name <span>*</span>
              </label>
              <input
                type="text"
                required
                onChange={handleChange}
                name="accountName"
                value={data.accountName}
                placeholder="ex: Union Bank"
              />
            </div> */}

              {/* <div className="create-party-popup-form-row">
              <div className="create-party-popup-form-col">
                <label>Opening Balance</label>
                <input
                  type="number"
                  onChange={handleChange}
                  name="openingBalance"
                  value={data.openingBalance}
                  placeholder="ex: ₹ 1000000"
                  min={0}
                />
              </div>

              <div className="create-party-popup-form-col">
                <label>As of Date</label>
                <input
                  type="date"
                  onChange={handleChange}
                  name="asOfDate"
                  value={data.asOfDate}
                />
              </div>
              </div> */}

            {data.addBankDetails ? (
              <>
                {/* <div className="create-party-popup-form-head">
                  <div>Bank Details</div>
                  <span
                    onClick={() => {
                      setData({ ...data, addBankDetails: false });
                    }}
                  >
                    Remove
                  </span>
                </div> */}

                <div className="create-party-popup-form-row">
                  <div className="create-party-popup-form-col">
                    <label>
                      Bank Account Number<span>*</span>
                    </label>
                    <input
                      type="number"
                      required
                      onChange={handleChange}
                      name="accountNumber"
                      value={data.accountNumber}
                        placeholder="ex: 1234567890"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>
                      IFSC Code<span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="IFSCcode"
                      value={data.IFSCcode}
                        placeholder="ex: UNION00001"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>
                      Bank & Branch Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="bankName"
                      value={data.bankName}
                        placeholder="ex: UNION, Old Delhi"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>
                      Account Holder Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="accountHolderName"
                      value={data.accountHolderName}
                        placeholder="ex: User Name"
                    />
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>UPI ID</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="upiId"
                      value={data.upiId}
                        placeholder="ex: user123@union"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="create-party-popup-form-link"
                onClick={() => {
                  setData({ ...data, addBankDetails: true });
                }}
              >
                + Add Bank Details
              </div>
            )}
          </div>

          <div className="create-party-popup-end">
{
  data.activateAccount === true?
<div className="create-party-popup-end-option" onClick={ () => setData( { ...data, activateAccount : false }) }><i class="fa-regular fa-eye-slash"></i> Deactive Account ?</div>
:
<div className="create-party-popup-end-option create-party-popup-end-option-red" onClick={ () => setData( { ...data, activateAccount : true }) }><i class="fa-regular fa-eye"></i> Active Account ?</div>
}

          

            
          <div className="create-party-popup-buttons">
            
            
            <div
              className="create-party-popup-button-cancel"
              onClick={() => open(false)}
            >
              Cancel
            </div>
            <button className="create-party-popup-button-save">Update</button>
            
            
          </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default UpdateAccountPopup;
