import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import { useNavigate } from "react-router-dom";

const AppTransactionPage = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // ============================================================================================

  const navigate = useNavigate();

  // useState ============================================================================================
  const [search, setsearch] = useState("");

  const [businessData, setBusinessData] = useState(null);

  //  ============================================================================================
  const { fetchBusiness } = useContext(AuthContext);
  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setBusinessData);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  if (!businessData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  //   const salesInvoiceData = businessData.salesInvoiceData.map( e => {
  //     return { ...e, invoiceType : "sales Invoice"}
  // });
  // const quotationData = businessData.quotationData.map( e => {
  //     return { ...e, invoiceType : "quotation"}
  // });
  // const proformaData = businessData.proformaData.map( e => {
  //     return { ...e, invoiceType : "proforma"}
  // });
  // const deliveryChallanData = businessData.deliveryChallanData.map( e => {
  //     return { ...e, invoiceType : "delivery Challan"}
  // });
  // const salesReturnData = businessData.salesReturnData.map( e => {
  //     return { ...e, invoiceType : "sales Return"}
  // });
  // const creditNoteData = businessData.creditNoteData.map( e => {
  //     return { ...e, invoiceType : "credit Note"}
  // });
  // const purchaseInvoiceData = businessData.purchaseInvoiceData.map( e => {
  //     return { ...e, invoiceType : "purchase Invoice"}
  // });
  // const purchaseReturnData = businessData.purchaseReturnData.map( e => {
  //     return { ...e, invoiceType : "purchase Return"}
  // });
  // const debitNoteData = businessData.debitNoteData.map( e => {
  //     return { ...e, invoiceType : "debit Note"}
  // });
  // const purchaseOrderData = businessData.purchaseOrderData.map( e => {
  //     return { ...e, invoiceType : "purchase Order"}
  // });

  // const combinedData = [
  //     ...salesInvoiceData,
  //     ...quotationData,
  //     ...proformaData,
  //     ...deliveryChallanData,
  //     ...salesReturnData,
  //     ...creditNoteData,
  //     ...purchaseInvoiceData,
  //     ...purchaseReturnData,
  //     ...debitNoteData,
  //     ...purchaseOrderData,
  // ]

  // combinedData.sort((a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate));

  const salesInvoiceData = businessData.salesInvoiceData.map((e) => {
    return { ...e, transactionType: "sales Invoice" };
  });
  const quotationData = businessData.quotationData.map((e) => {
    return { ...e, transactionType: "quotation" };
  });
  const proformaData = businessData.proformaData.map((e) => {
    return { ...e, transactionType: "proforma" };
  });
  const deliveryChallanData = businessData.deliveryChallanData.map((e) => {
    return { ...e, transactionType: "delivery Challan" };
  });
  const salesReturnData = businessData.salesReturnData.map((e) => {
    return { ...e, transactionType: "sales Return" };
  });
  const creditNoteData = businessData.creditNoteData.map((e) => {
    return { ...e, transactionType: "credit Note" };
  });
  const purchaseInvoiceData = businessData.purchaseInvoiceData.map((e) => {
    return { ...e, transactionType: "purchase Invoice" };
  });
  const purchaseReturnData = businessData.purchaseReturnData.map((e) => {
    return { ...e, transactionType: "purchase Return" };
  });
  const debitNoteData = businessData.debitNoteData.map((e) => {
    return { ...e, transactionType: "debit Note" };
  });
  const purchaseOrderData = businessData.purchaseOrderData.map((e) => {
    return { ...e, transactionType: "purchase Order" };
  });

  const staffTransactionData = businessData.staffTransactionData.map((e) => {
    return {
      ...e,
      invoiceDate: e.transactionDate,
      transactionType: `Staff ${e.transactionType}`,
      invoiceNumber: e.expenseNumber,
      staffName: e.staffData.staffName,
      receivedAmount: e.transactionAmount,
    };
  });

  const expenseData = businessData.expenseData.map((e) => {
    return {
      ...e,
      invoiceDate: e.expenseDate,
      transactionType: e.expenseCategory,
      invoiceNumber: e.expenseNumber,
      receivedAmount: e.expenseTotalAmount,
      transactionDate: e.expenseDate,
    };
  });

  const adjustBalanceData = businessData.adjustBalanceData;

  const transferBalanceData = businessData.transferBalanceData;

  // console.log("adjustBalanceData is : ", adjustBalanceData)

  const combinedData = [
    ...expenseData,
    ...staffTransactionData,
    ...transferBalanceData,
    ...adjustBalanceData,
    ...salesInvoiceData,
    ...quotationData,
    ...proformaData,
    ...deliveryChallanData,
    ...salesReturnData,
    ...creditNoteData,
    ...purchaseInvoiceData,
    ...purchaseReturnData,
    ...debitNoteData,
    ...purchaseOrderData,
  ];

  combinedData.sort(
    (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate)
  );

  // ==================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  return (
    <div>
      <div>
        <div className="app-transaction-page">
          <div className="app-transaction-page-main">
            <div className="app-transaction-page-top">
              <div className="app-transaction-page-heading">
                <i
                  class="fa-solid fa-arrow-left"
                  onClick={() => navigate(-1)}
                ></i>{" "}
                All Transactions
              </div>
            </div>

            <div className="app-transaction-page-table">
              <div className="app-transaction-page-table-head">
                <div>Date</div>
                <div>Type</div>
                <div>Transaction Number</div>
                <div>Name</div>
                <div>Amount</div>
              </div>
              <div className="app-transaction-page-table-body">
                {combinedData
                  .filter(
                    (e) =>
                      (e.transactionType === "purchase Invoice" ||
                        e.transactionType === "sales Return" ||
                        e.transactionType === "credit Note" ||
                        e.transactionType === "sales Invoice" ||
                        e.transactionType === "purchase Return" ||
                        e.transactionType === "debit Note" ||
                        e.transactionDate) &&
                      e.receivedAmount
                  )
                  .filter((e) =>
                    search
                      ? e.invoicePartyData.partyName
                          .toLocaleLowerCase()
                          .includes(search.toLowerCase())
                      : e
                  )
                  .map((e) => (
                    <>
                      <div>{new Date(e.invoiceDate).toDateString()}</div>
                      <div>{e.transactionType && e.transactionType}</div>
                      <div>{e.invoiceNumber && e.invoiceNumber}</div>
                      <div>
                        {" "}
                        {e.invoicePartyData
                          ? e.invoicePartyData.partyName
                          : e.staffName
                          ? e.staffName
                          : e.withGst === true
                          ? e.expensePartyData
                            ? e.expensePartyData.partyName
                            : "--"
                          : "-"}
                      </div>

                      {/* <div>{e.invoicePartyData&&e.invoicePartyData.partyName}</div> */}
                      <div>
                        ₹ {e.receivedAmount && formatNumber(e.receivedAmount)}
                      </div>
                    </>
                  ))}
              </div>
            </div>

            {combinedData
              .filter(
                (e) =>
                  (e.transactionType === "purchase Invoice" ||
                    e.transactionType === "sales Return" ||
                    e.transactionType === "credit Note" ||
                    e.transactionType === "sales Invoice" ||
                    e.transactionType === "purchase Return" ||
                    e.transactionType === "debit Note" ||
                    e.transactionDate) &&
                  e.receivedAmount
              )
              .filter((e) =>
                search
                  ? e.invoicePartyData.partyName
                      .toLocaleLowerCase()
                      .includes(search.toLowerCase())
                  : e
              ).length <= 0 ? (
              <div className="app-transaction-page-blank">No Transactions</div>
            ) : (
              ""
            )}

            <div className="app-dashboard-small-list-content">
              {combinedData
                .filter(
                  (e) =>
                    (e.transactionType === "purchase Invoice" ||
                      e.transactionType === "sales Return" ||
                      e.transactionType === "credit Note" ||
                      e.transactionType === "sales Invoice" ||
                      e.transactionType === "purchase Return" ||
                      e.transactionType === "debit Note" ||
                      e.transactionDate) &&
                    e.receivedAmount
                )
                .map((e) => (
                  <div className="app-dashboard-small-list-col">
                    <div className="app-dashboard-small-list-row">
                      <div className="app-dashboard-small-list-name">
                        {e.invoicePartyData
                          ? e.invoicePartyData.partyName
                          : e.staffName
                          ? e.staffName
                          : e.withGst === true
                          ? e.expensePartyData
                            ? e.expensePartyData.partyName
                            : "--"
                          : "-"}
                      </div>
                      <div className="app-dashboard-small-list-date">
                        {new Date(e.invoiceDate).toDateString()}
                      </div>
                    </div>

                    <div className="app-dashboard-small-list-row">
                      <div className="app-dashboard-small-list-invoice">
                        {e.transactionType && e.transactionType} #
                        {e.invoiceNumber && e.invoiceNumber}
                      </div>
                      <div className="app-dashboard-small-list-amount">
                        ₹ {e.receivedAmount && formatNumber(e.receivedAmount)}
                      </div>
                    </div>
                  </div>
                ))}

              {combinedData.filter(
                (e) =>
                  (e.transactionType === "purchase Invoice" ||
                    e.transactionType === "sales Return" ||
                    e.transactionType === "credit Note" ||
                    e.transactionType === "sales Invoice" ||
                    e.transactionType === "purchase Return" ||
                    e.transactionType === "debit Note" ||
                    e.transactionDate) &&
                  e.receivedAmount
              ).length > 0 ? (
                <div className="app-transaction-page-table-link"></div>
              ) : (
                <div className="app-dashboard-small-list-empty">
                  No Transactions
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppTransactionPage;
