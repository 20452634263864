import React, { useContext, useEffect, useState } from 'react'
import AppCashBankComponent from '../../components/pageComponents/AppCashBankComponent'
import { AuthContext } from '../../../context/AuthContext';
import LoadingComponent from '../../../components/extra/LoadingComponent';

const AppCashBankPage = () => {

   // get from storage ============================================================================================

   const userId = localStorage.getItem("invoician-user-id");
   const businessId = localStorage.getItem("business-id");
 
   // useState ============================================================================================
 
   const [businessData, setBusinessData] = useState(null);
 
   //  ============================================================================================
   const { fetchBusiness } = useContext(AuthContext);
   const fetchBusinessData = (e) =>
     fetchBusiness(userId, businessId, setBusinessData);
 
   useEffect(() => {
     fetchBusinessData();
   }, []);
 
   if (!businessData)
     return (
       <div>
         <LoadingComponent />
       </div>
     );
 
   const salesInvoiceData = businessData.salesInvoiceData.map((e) => {
     return { ...e, transactionType: "sales Invoice" };
   });
   const quotationData = businessData.quotationData.map((e) => {
     return { ...e, transactionType: "quotation" };
   });
   const proformaData = businessData.proformaData.map((e) => {
     return { ...e, transactionType: "proforma" };
   });
   const deliveryChallanData = businessData.deliveryChallanData.map((e) => {
     return { ...e, transactionType: "delivery Challan" };
   });
   const salesReturnData = businessData.salesReturnData.map((e) => {
     return { ...e, transactionType: "sales Return" };
   });
   const creditNoteData = businessData.creditNoteData.map((e) => {
     return { ...e, transactionType: "credit Note" };
   });
   const purchaseInvoiceData = businessData.purchaseInvoiceData.map((e) => {
     return { ...e, transactionType: "purchase Invoice" };
   });
   const purchaseReturnData = businessData.purchaseReturnData.map((e) => {
     return { ...e, transactionType: "purchase Return" };
   });
   const debitNoteData = businessData.debitNoteData.map((e) => {
     return { ...e, transactionType: "debit Note" };
   });
   const purchaseOrderData = businessData.purchaseOrderData.map((e) => {
     return { ...e, transactionType: "purchase Order" };
    });

       
   const staffTransactionData = businessData.staffTransactionData.map((e) => {
    return { ...e, 
      name : e.staffData.staffName,
      invoiceDate: e.transactionDate,
      transactionType: `Staff ${e.transactionType}`,
      invoiceNumber: e.expenseNumber,
      partyData: e.staffData,
      receivedAmount: e.transactionAmount,

     };
   });
       
   const expenseData = businessData.expenseData.map((e) => {
    return { ...e, 
      name : e.expensePartyData ? e.expensePartyData.partyName : "-",
      invoiceDate: e.expenseDate,
      transactionType: e.expenseCategory,
      invoiceNumber: e.expenseNumber,
      receivedAmount: e.expenseTotalAmount,
      transactionDate: e.expenseDate,

     };
   });
    
    const adjustBalanceData = businessData.adjustBalanceData;
    
    const transferBalanceData = businessData.transferBalanceData;

    // console.log("adjustBalanceData is : ", adjustBalanceData)

   const combinedData = [
    ...expenseData,
    ...staffTransactionData,
     ...transferBalanceData,
     ...adjustBalanceData,
     ...salesInvoiceData,
     ...quotationData,
     ...proformaData,
     ...deliveryChallanData,
     ...salesReturnData,
     ...creditNoteData,
     ...purchaseInvoiceData,
     ...purchaseReturnData,
     ...debitNoteData,
     ...purchaseOrderData,
   ];
 
   combinedData.sort(
     (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate)
   );
 
   // -----------------------------------------------------------------------------
   // -----------------------------------------------------------------------------
   // -----------------------------------------------------------------------------
 
  return (
    <div>

      <AppCashBankComponent businessData={businessData} combinedData={combinedData}  fetchBusinessData={fetchBusinessData} />
      
    </div>
  )
}

export default AppCashBankPage