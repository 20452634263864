import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../../components/extra/LoadingComponent";
import { AuthContext } from "../../../../context/AuthContext";
import PopUpAlertComponent from "../../../../components/extra/PopUpAlertComponent";
import ViewInvoiceExcelTheme from "../../../pages/main/view/ViewInvoiceExcelTheme";
import ViewInvoiceGstTheme from "../../../pages/main/view/ViewInvoiceGstTheme";
import ViewBillBookTheme from "../../../pages/main/view/ViewBillBookTheme";
import DownloadingComponent from "../../../../components/extra/DownloadingComponent";
import html2pdf  from "html2pdf.js";
import ViewInvoiceStylishTheme from "../../../pages/main/view/ViewInvoiceStylishTheme";
import UpdateSalesReturnPageComponent from "../update/UpdateSalesReturnPageComponent";
import UpdateCreditNotePageComponent from "../update/UpdateCreditNotePageComponent";

const ViewDataCreditReturnComponent = ({
  open,
  invoiceId,
  businessData,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // usestates ============================================================================================

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [updateInvoice, setUpdateInvoice] = useState(false);

  //  ============================================================================================

  const data = businessData.creditNoteData.find(
    (e) => e._id === invoiceId
  );

  //  ============================================================================================
  //  ============================================================================================

   //   ======================================================================================================================================================

   const divRef = useRef(null);
   const [invoiceHeight, setInvoiceHeight] = useState(1000);
 
   const handleInvoiceHeight = (e) => {
     if (divRef.current) {
       setInvoiceHeight(divRef.current.offsetHeight);
     }
   };
 
  
  //  ============================================================================================
  //  ============================================================================================
  
  const generatePDF = async () => {
    const element = document.getElementById("content-to-pdf");
    setDownloading(true);
    const options = {
      margin: 0,
      filename: `${
        data.invoicePartyData && data.invoicePartyData.partyName
      } Invoice-${data.invoiceNumber}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        unit: "px",
        format: [800, invoiceHeight + 25],
        orientation: "portrait",
      },
      
    };
    await html2pdf().from(element).set(options).save();
    setDownloading(false);
  };
  
  
  useEffect(() => {

    handleInvoiceHeight();
  }, [generatePDF]);
  
  //  ============================================================================================

  const { deleteSingleCreditNote } = useContext(AuthContext);
  const handleDelete = async (e) => {
    setLoading(true);
    await deleteSingleCreditNote(
      userId,
      businessId,
      invoiceId,
      open,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus
    );
    fetchBusinessData();
    setLoading(false);
  };

  if (!data)
    return (
      <div>
        <LoadingComponent />
      </div>
    );
  return (
    <div>

{downloading ? <DownloadingComponent /> : ""}
        
      {deleteAlert ? (
        <PopUpAlertComponent
          open={setDeleteAlert}
          ok={handleDelete}
          heading={`You want to delete invoice data?`}
        />
      ) : (
        ""
      )}

      {updateInvoice ? (
        <UpdateCreditNotePageComponent
          open={setUpdateInvoice}
          id={invoiceId}
          invoiceData={data}
          fetchBusinessData={fetchBusinessData}
          businessData={businessData}
          handleInvoiceHeight={handleInvoiceHeight}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="view-invoice-data">
        <div className="view-invoice-data-main">
          <div className="view-invoice-data-top">
            <div className="view-invoice-data-top-heading">
              <i
                onClick={() => open(false)}
                className="fa-solid fa-arrow-left"
              ></i>
              Credit Note {data.invoiceNumber?<>#{data.invoiceNumber}</>:""}
            </div>
            <div className="view-invoice-data-top-options">
              <div className="view-invoice-data-top-button-2" onClick={generatePDF}>
                <i class="fa-solid fa-download"></i> <span>Download</span>
              </div>
              <div
                className="view-invoice-data-top-button-main"
                onClick={() => setUpdateInvoice(true)}
              >
                <i class="fa-solid fa-pen"></i> <span>Edit</span>
              </div>
              <div
                className="view-invoice-data-top-button-alert"
                onClick={() => setDeleteAlert(true)}
              >
                <i class="fa-solid fa-trash-can"></i> <span>Delete</span>
              </div>
            </div>
          </div>
          <div className="view-invoice-data-content">
            <div className="view-invoice-data-content-main">

                
      {/* ------------------------------------------------------------------------------------------------ */}
      {/* download invoice start------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------ */}

      <div ref={divRef} id="content-to-pdf">

{businessData &&
businessData.invoiceSettingData.invoiceTheme === "Stylish" ? (
  <ViewInvoiceStylishTheme
    data={data}
    businessData={businessData}
    heading="credit Note"
  />
) : businessData &&
  businessData.invoiceSettingData.invoiceTheme === "Excel" ? (
  <ViewInvoiceExcelTheme
    data={data}
    businessData={businessData}
    heading="credit Note"
  />
) : businessData &&
  businessData.invoiceSettingData.invoiceTheme === "AdvanceGst" ? (
  <ViewInvoiceGstTheme
    data={data}
    businessData={businessData}
    heading="credit Note"
  />
) : businessData &&
  businessData.invoiceSettingData.invoiceTheme === "BillBook" ? (
  <ViewBillBookTheme
    heading="credit Note"
    data={data}
    businessData={businessData}
  />
) : (
  ""
)}
</div>

{/* ------------------------------------------------------------------------------------------------ */}
{/* download invoice end------------------------------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------------------------------ */}

                
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDataCreditReturnComponent;
