import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PopUpAlertComponent from "../../../components/extra/PopUpAlertComponent";
import UpdateStaffTransaction from "./UpdateStaffTransaction";

const ViewStaffTransaction = ({
  open,
  transactionId,
  businessData,
  staffId,
  staffData,
  fetchBusinessData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  const [editAttendanceOption, setEditAttendanceOption] = useState(null);

  // useStates  ================================================================================================================

  const [popUpAlert, setPopUpAlert] = useState(false);

  const [updateTransaction, setUpdateTransaction] = useState(false);

  // ================================================================================================================
  const transactionData = businessData.staffTransactionData.find(
    (e) => e._id === transactionId
  );
  // ================================================================================================================

  // ================================================================================================================

  const monthName = (e) => {
    // const monthNames = [
    //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
    //   'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    // ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = Number(e.split("-")[1]) - 1;

    const year = Number(e.split("-")[0]);

    return `${monthNames[month]} ${year}`;
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ================================================================================================================
  // ================================================================================================================

  const { deleteSingleStaffTransaction } = useContext(AuthContext);

  const handleDelete = async (e) => {
    await deleteSingleStaffTransaction(
      userId,
      businessId,
      transactionId,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      fetchBusinessData,
      open
    );
  };

  return (
    <div>
      {popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete transaction data?`}
        />
      ) : (
        ""
      )}

      {updateTransaction ? (
        <UpdateStaffTransaction
          open={setUpdateTransaction}
          businessData={businessData}
          staffId={staffId}
          staffData={staffData}
          transactionId={transactionId}
          transactionData={transactionData}
          fetchBusinessData={fetchBusinessData}
          setTimerPopupAlert = {setTimerPopupAlert}
          setTimerAlertMessage = {setTimerAlertMessage}
          setTimerAlertStatus = {setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="create-party-popup">
        <div className="create-party-popup-main">
          <div className="create-party-popup-top">
            <div>Transaction</div>
            <i onClick={() => open(false)} class="fa-solid fa-xmark"></i>
          </div>

          <div className="create-party-popup-form">
            <div className="create-party-popup-form-row">
              <div className="create-party-popup-form-col">
                <label>Transaction Type</label>
                <div>{transactionData && transactionData.transactionType}</div>
              </div>

              {transactionData.transactionType === "Salary" ||
              transactionData.transactionType === "Bonus" ||
              transactionData.transactionType === "Advance" ? (
                <div className="create-party-popup-form-col">
                  <label>
                    {transactionData && transactionData.transactionType} Month
                  </label>
                  <div>
                    {transactionData && monthName(transactionData.paymentMonth)}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="create-party-popup-form-col">
                <label>Amount</label>{" "}
                <div>
                  ₹{" "}
                  {transactionData &&
                    formatNumber(transactionData.transactionAmount)}
                </div>
                {/* {data.transactionType === "Salary"?
              <div className="create-staff-pending-amount">pending salary <b> ₹ {formatNumber(balanceSalary)}</b></div>
              :""} */}
              </div>

              <div className="create-party-popup-form-col">
                <label>Date</label>
                <div>
                  {transactionData &&
                    new Date(transactionData.transactionDate).toDateString()}
                </div>
              </div>

              <div className="create-party-popup-form-col">
                <label>Payment Type</label>
                <div>{transactionData && transactionData.paymentType}</div>
              </div>
              {transactionData.paymentType === "UPI" ||
              transactionData.paymentType === "Card" ||
              transactionData.paymentType === "Netbanking" ||
              transactionData.paymentType === "Bank Transfer" ||
              transactionData.paymentType === "Cheque" ? (
                <div className="create-party-popup-form-col">
                  <label>Payment from</label>

                  {transactionData.selectedBankAccount
                    ? businessData &&
                      businessData.bankData
                        .filter((e) => e._id === transactionData.selectedBankAccount)
                        .map((e) => <div>{e.bankName}</div>)
                    : "-"}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="create-party-popup-form-col">
              <label>Remark</label>
              <div>
                {transactionData.transactionRemark
                  ? transactionData.transactionRemark
                  : "-"}
              </div>
            </div>
          </div>

          <div className="create-party-popup-buttons">
            <div
              onClick={() => setPopUpAlert(true)}
              className="create-party-popup-button-delete"
            >
              delete
            </div>
            <button onClick={ () => { setUpdateTransaction(true) }} className="create-party-popup-button-save">edit</button>
            {/* <button className="create-party-popup-button-save">Save</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStaffTransaction;
