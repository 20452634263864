import React, { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import SettingUpiComponent from "./SettingUpiComponent";

const SettingExcelThemeComponent = ({ data, businessData, settingEditOption }) => {


  
  const { link } = useContext(AuthContext);
  
  
  
  const mainColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--invoice")
    .trim();

  // Function to set RGBA color with opacity
  const rgbaColor = (opacity) => {
    const hex = mainColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  
  return (
    <div>
      <div className="">

      <div className="invoice-excel-theme-heading"></div>
      
      <div className="invoice-excel-theme">
        <div className="invoice-excel-theme-main">

          <div id="theme" className={settingEditOption === "theme"?"invoice-excel-theme-head  invoice-excel-theme-active":"invoice-excel-theme-head"}>
            <div className="invoice-excel-theme-head-company">
              <div className="invoice-excel-theme-head-company-name">{businessData && businessData.businessName}</div>
              
              {
                data.invoiceAddress?
              businessData.invoiceSettingData.invoiceAddress?
              <div className="invoice-excel-theme-head-company-Address">
                 {
                businessData.companyBillingAddress?
                <>{businessData.companyBillingAddress},</>
              :""
              }
              {
                businessData.companyCity?
                <> {businessData.companyCity},</>
              :""
              }
              {
                businessData.companyState?
                <> {businessData.companyState},</>
              :""
              }
              {
                businessData.companyPincode?
                <> {businessData.companyPincode}</>
              :""
              }
              </div>
            :""
            :""
            }



{
     data.invoicePhoneNumber?
              <div className="invoice-excel-theme-head-company-number">
               <b>Mobile : </b> {businessData.companyPhoneNumber ? businessData.companyPhoneNumber :"----"}                
              </div>   
              :""
              }

{
    data.invoiceEmail?
  <div className="invoice-excel-theme-head-company-email"><b>Email : </b>{businessData.companyEmail?businessData.companyEmail:"--------"}</div>
  
  :""

}

{
    data.invoiceGSTIN?
              <div className="invoice-excel-theme-head-company-gst"><b>GSTIN : </b>{businessData.companyGSTIN?businessData.companyGSTIN :"------------"}</div>
             
              :""
              }
            </div>
            <div className="invoice-excel-theme-head-text">INVOICE</div>
          </div>

          <div className="invoice-excel-theme-options">
            
            <div className="invoice-excel-theme-options-col-1">
              <div className="invoice-excel-theme-options-tabel">
                
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Invoice#</div>
                  <div>9658</div>
                </div>

                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Invoice Date</div>
                  {/* <div>{data.invoiceDate ? new Date(data.invoiceDate).toDateString() : "__________"}</div> */}
                  <div>2024-12-03</div>
                </div>

                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Due Date</div>
                  <div>2024-12-03</div>
                </div>

              </div>

            </div>

            <div id="invoice" className={settingEditOption === "invoice" ?"invoice-excel-theme-options-col-2 invoice-excel-theme-active" : "invoice-excel-theme-options-col-2"}>
              <div className="invoice-excel-theme-options-tabel">

             
             {
                data.invoicePoNoOptions?
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>P.O. No.</div>
                  <div>9856</div>
                </div>
                :""
            }
                
{data.invoiceVehicleNumberOptions?
                <div className="invoice-excel-theme-options-tabel-row">
                  <div>Vehicle No.</div>
                  <div>XXXXXXXXXX</div>
                </div>
                :""}
                

                {
                            data.invoiceDetailsOptions?
                            data.invoiceDetailsOptions.map( e => (
                          <div className="invoice-excel-theme-options-tabel-row">
                            <div>{e}</div>
                            <div>-</div>
                          </div>

                            ))
                          :""
                          }
                


              </div>

            </div>
            

          </div>

          <div className="invoice-excel-theme-options">
            
            <div className="invoice-excel-theme-address">
             <div className="invoice-excel-theme-address-head" style={{ backgroundColor: rgbaColor(0.2) }}>Bill To</div>
              <div className="invoice-excel-theme-address-content">
                <div className="invoice-excel-theme-address-content-name">Party Name</div>                 
                <div  className="invoice-excel-theme-address-content-text">Billing Address, City, State - 00000</div>
                <div className="invoice-excel-theme-address-content-text"><b>Number : </b>1234567890</div>
                <div className="invoice-excel-theme-address-content-text"><b>GSTIN : </b>987654567890</div>
              </div>
            </div>
            
            <div className="invoice-excel-theme-address">
             <div className="invoice-excel-theme-address-head" style={{ backgroundColor: rgbaColor(0.2) }}>Ship To</div>
              <div className="invoice-excel-theme-address-content">
                <div className="invoice-excel-theme-address-content-name">Party Name</div>                 
                <div  className="invoice-excel-theme-address-content-text">Shipping Address, City, State - 99999</div>
                <div className="invoice-excel-theme-address-content-text"><b>Number : </b>9876543210</div>
                <div className="invoice-excel-theme-address-content-text"><b>GSTIN : </b>45675456897865</div>
              </div>
            </div>
            

          

            
          </div>


          <div id="table" className={settingEditOption === "table" ?"invoice-excel-theme-table invoice-excel-theme-active" : "invoice-excel-theme-table"}>

            <table >
                <tr >
                  <td>#</td>
                  <td>Items</td>
                  <td>HSN/SAC</td>
                  {data.tabelQuantityOptions?<td>Qty.</td>:""}
                  {data.tabelPriceOptions?<td>Rate</td>:""}
                  <td>Tax</td>
                  <td>Amount</td>
                </tr>

                <tr>
                    <td>1</td>
                    <td>Service {data.invoiceItemDescription?<span><div>service description</div></span>:""}</td>
                    <td>AB123</td>
                    {data.tabelQuantityOptions?<td>1</td>:""}
                    {data.tabelPriceOptions?<td>1000</td>:""}
                    <td>5%</td>
                    <td>1050</td>
                </tr>

                <tr>
                    <td>1</td>
                    <td>Product - 1</td>
                    <td>123AB</td>
                    {data.tabelQuantityOptions?<td>1</td>:""}
                    {data.tabelPriceOptions?<td>500</td>:""}
                    <td>18%</td>
                    <td>1180</td>
                </tr>

                <tr>
                    <td>1</td>
                    <td>Product - 1 {data.invoiceItemDescription?<span><div>product description</div></span>:""}</td>
                    <td>9876</td>
                    {data.tabelQuantityOptions?<td>1</td>:""}
                    {data.tabelPriceOptions?<td>0</td>:""}
                    <td>5%</td>
                    <td>0</td>
                </tr>


               


                <tr >
                  <td></td>
                <td>Sub Total</td>
                <td></td>
                {data.tabelQuantityOptions?<td></td>:""}
                {data.tabelPriceOptions?<td></td>:""}
                <td></td>
                <td>₹ 2000</td>
                </tr>
                
                
              
            </table>
            
          </div>


          <div id="miscellaneous" className={settingEditOption === "miscellaneous"?"invoice-excel-theme-end invoice-excel-theme-active" : "invoice-excel-theme-end"}>

          <div className="invoice-excel-theme-end-col-1">
            
          

{
 data.invoiceTermsConditions? 
  <>
          <div className="invoice-excel-theme-end-heading">Terms and Conditions</div>
          <div className="invoice-excel-theme-end-text"><pre>{data.invoiceTermsConditions}</pre></div>
  </>
  :""
}
          
          <SettingUpiComponent/>

          </div>

          <div className="invoice-excel-theme-end-col-2">

          <div className="invoice-excel-theme-end-col-2-amount"  style={{ backgroundColor: rgbaColor(0.2) }}>
            
            <div className="invoice-excel-theme-end-col-row">
              <div>Taxable Value</div>
              <div> ₹ 2000</div>
            </div>
            <div className="invoice-excel-theme-end-col-row">
              <div>SGST</div>
              <div>₹ 115</div>
            </div>

            <div className="invoice-excel-theme-end-col-row">
              <div>CGST</div>
              <div>₹ 115</div>
            </div>
            
  <div className="invoice-excel-theme-end-col-row">
              <div>Discount</div>
              <div>₹ 30</div>
            </div>


            <div className="invoice-excel-theme-end-col-row-total">
              <div>Total Amount</div>
              <div>₹ 2230</div>
            </div>


  <div className="invoice-excel-theme-end-col-row">
              <div>Paid Amount</div>
              <div>₹ 2000</div>
            </div>

            <div className="invoice-excel-theme-end-col-row">
              <div>Balance</div>
              <div>₹ 200
              </div>
            </div>
          </div>



          <div className="invoice-excel-theme-signature">
{
    
  data.invoiceSignatureType === "Box"?
  <>
          <div className="invoice-excel-theme-signature-empty"></div>
          <div className="invoice-excel-theme-signature-text">
            <span>Authorized Signatory For</span>
            <div>{businessData.businessName}</div>
          </div>

  </>
  :
  data.invoiceSignatureType === "Image"?
  businessData.companySignature ?
  <>
          <img src={`${link}/${businessData.companySignature }`} alt="comapny signature"/>
          <div className="invoice-excel-theme-signature-text">
            <span>Authorized Signatory For</span>
            <div>{businessData.businessName}</div>
          </div>
  </>
  :""
  :""
}
          </div>



                      




          </div>
          </div>








        </div>
      </div>
      </div>
    </div>
  );
};

export default SettingExcelThemeComponent;
