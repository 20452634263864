import React, { useState } from "react";

const InvoiceSelectItemsPopup = ({
  open,
  handleKeyDown,
  businessData,
  formatNumber,
  selectedItems,
  handleAddItem,
  handleRemoveItem,
  handleQuantityChange,
  handleIncreaseQuanitiy,
  handleDecreaseQuanitiy,
  setCreateItem,
  selectType,
}) => {
  // useStates  ================================================================================================================

  const [searchItem, setSearchItem] = useState("");

  const [viewItemDetails, setViewItemDetails] = useState(false);


  
  
  

  return (
    <div>
      

        

      <div className="create-invoice-page-table-add-item">
        <div className="create-invoice-page-table-add-item-main">
          <div className="create-invoice-page-table-add-item-top">
            <div>Add Items</div>
            <div
              className="create-invoice-page-table-add-item-top-close"
              onClick={() => {
                open(false);
                setSearchItem("");
                setViewItemDetails("");
              }}
            >
              Close
            </div>
          </div>

          <div className="create-invoice-page-table-add-item-head">
            <div className="create-invoice-page-table-add-item-head-search">
              <i class="fa-solid fa-magnifying-glass"></i>
              <input
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="search items"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
            <div
              className="create-invoice-page-table-add-item-head-create"
              onClick={() => {
                setCreateItem(true);
              }}
            >
              + Create New
            </div>
          </div>

          <div className="create-invoice-page-table-add-item-table">
            <div className="create-invoice-page-table-add-item-table-head">
              <div className="create-invoice-page-table-add-item-table-name">
                ITEM NAME
              </div>
              <div className="create-invoice-page-table-add-item-table-code">
                ITEM CODE
              </div>
              <div className="create-invoice-page-table-add-item-table-sale">
                SALE PRICE
              </div>
              <div className="create-invoice-page-table-add-item-table-purchase">
                PURCHASE PRICE
              </div>

              <div className="create-invoice-page-table-add-item-table-quantity">
                QUANTITY
              </div>
            </div>

            <div className="create-invoice-page-table-add-item-table-body">

            {selectedItems.map((item) => (
                            <div
                              key={item._id}
                              className="create-invoice-page-table-add-item-table-body-row"
                            >
                              <div className="create-invoice-page-table-add-item-table-name">
                                {" "}
                                <i
                                  class="fa-regular fa-square-check"
                                  onClick={() => handleRemoveItem(item._id)}
                                ></i>{" "}
                                {item.itemName}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-code">
                                {item.itemCode ? item.itemCode : "-"}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-sale">
                                {item.itemPrice ? (
                                  <>
                                    {item.itemTax === "0" ||
                                    item.itemTax === "0.1" ||
                                    item.itemTax === "0.25" ||
                                    item.itemTax === "1.5" ||
                                    item.itemTax === "3" ||
                                    item.itemTax === "5" ||
                                    item.itemTax === "6" ||
                                    item.itemTax === "12" ||
                                    item.itemTax === "13.8" ||
                                    item.itemTax === "18" ||
                                    item.itemTax === "28" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice *
                                              Number(item.itemTax)) /
                                              100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "14,12" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 14) / 100 +
                                            (item.itemPrice * 12) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "28,12" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 28) / 100 +
                                            (item.itemPrice * 12) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "28,60" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 28) / 100 +
                                            (item.itemPrice * 60) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : (
                                      <>₹ {item.itemPrice}</>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-purchase">
                                {item.itemPurchasePrice ? (
                                  <>
                                    <>
                                      {item.itemTax === "0" ||
                                      item.itemTax === "0.1" ||
                                      item.itemTax === "0.25" ||
                                      item.itemTax === "1.5" ||
                                      item.itemTax === "3" ||
                                      item.itemTax === "5" ||
                                      item.itemTax === "6" ||
                                      item.itemTax === "12" ||
                                      item.itemTax === "13.8" ||
                                      item.itemTax === "18" ||
                                      item.itemTax === "28" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice *
                                                Number(item.itemTax)) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "14,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 14) /
                                                100 +
                                              (item.itemPurchasePrice * 12) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 28) /
                                                100 +
                                              (item.itemPurchasePrice * 12) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,60" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPurchasePrice +
                                              (item.itemPurchasePrice * 28) /
                                                100 +
                                              (item.itemPurchasePrice * 60) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : (
                                        <>₹ {item.itemPurchasePrice}</>
                                      )}
                                    </>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                             
                              <div className="create-invoice-page-table-add-item-table-quantity">
                                <div className="create-invoice-page-table-add-item-table-quantity-added">
                                  {item.quantity <= 1 ? (
                                    <i
                                      onClick={() => handleRemoveItem(item._id)}
                                      class="fa-solid fa-square-minus"
                                    ></i>
                                  ) : (
                                    <i
                                      onClick={() =>
                                        handleDecreaseQuanitiy(item)
                                      }
                                      class="fa-solid fa-square-minus"
                                    ></i>
                                  )}

                                  <input
                                    type="number"
                                    defaultValue={item.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        item._id,
                                        e.target.value
                                      )
                                    }
                                    min={0}
                                    value={item.quantity}
                                  />
                                  <i
                                    onClick={() => handleIncreaseQuanitiy(item)}
                                    class="fa-solid fa-square-plus"
                                  ></i>
                                  <div>
                                    {item.itemMeasuringUnit
                                      ? item.itemMeasuringUnit
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

              {businessData.itemData
                .filter((e) =>
                  e.itemName
                    .toLocaleLowerCase()
                    .includes(searchItem.toLowerCase())
                )

                .filter(
                  (e) => !selectedItems.some((b) => e._id === b._id)
                )
                .reverse()
                .map((item) => (
                  <div
                    key={item._id}
                    className="create-invoice-page-table-add-item-table-body-row"
                  >
                    <div className="create-invoice-page-table-add-item-table-name">
                      {" "}
                      <i
                        class="fa-regular fa-square"
                        onClick={() => handleAddItem(item)}
                      ></i>{" "}
                      {item.itemName}
                    </div>
                    <div className="create-invoice-page-table-add-item-table-code">
                      {item.itemCode ? item.itemCode : "-"}
                    </div>

                    <div className="create-invoice-page-table-add-item-table-sale">
                      {item.itemPrice ? (
                        <>
                          {item.itemTax === "0" ||
                          item.itemTax === "0.1" ||
                          item.itemTax === "0.25" ||
                          item.itemTax === "1.5" ||
                          item.itemTax === "3" ||
                          item.itemTax === "5" ||
                          item.itemTax === "6" ||
                          item.itemTax === "12" ||
                          item.itemTax === "13.8" ||
                          item.itemTax === "18" ||
                          item.itemTax === "28" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPrice +
                                  (item.itemPrice * Number(item.itemTax)) / 100
                              ) * 1}{" "}
                            </>
                          ) : item.itemTax === "14,12" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPrice +
                                  (item.itemPrice * 14) / 100 +
                                  (item.itemPrice * 12) / 100
                              ) * 1}{" "}
                            </>
                          ) : item.itemTax === "28,12" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPrice +
                                  (item.itemPrice * 28) / 100 +
                                  (item.itemPrice * 12) / 100
                              ) * 1}{" "}
                            </>
                          ) : item.itemTax === "28,60" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPrice +
                                  (item.itemPrice * 28) / 100 +
                                  (item.itemPrice * 60) / 100
                              ) * 1}{" "}
                            </>
                          ) : (
                            <>₹ {item.itemPrice}</>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>

                    <div className="create-invoice-page-table-add-item-table-purchase">
                      {item.itemPurchasePrice ? (
                        <>
                          {item.itemTax === "0" ||
                          item.itemTax === "0.1" ||
                          item.itemTax === "0.25" ||
                          item.itemTax === "1.5" ||
                          item.itemTax === "3" ||
                          item.itemTax === "5" ||
                          item.itemTax === "6" ||
                          item.itemTax === "12" ||
                          item.itemTax === "13.8" ||
                          item.itemTax === "18" ||
                          item.itemTax === "28" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPurchasePrice +
                                  (item.itemPurchasePrice *
                                    Number(item.itemTax)) /
                                    100
                              ) * 1}{" "}
                            </>
                          ) : item.itemTax === "14,12" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPurchasePrice +
                                  (item.itemPurchasePrice * 14) / 100 +
                                  (item.itemPurchasePrice * 12) / 100
                              ) * 1}{" "}
                            </>
                          ) : item.itemTax === "28,12" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPurchasePrice +
                                  (item.itemPurchasePrice * 28) / 100 +
                                  (item.itemPurchasePrice * 12) / 100
                              ) * 1}{" "}
                            </>
                          ) : item.itemTax === "28,60" ? (
                            <>
                              ₹{" "}
                              {formatNumber(
                                item.itemPurchasePrice +
                                  (item.itemPurchasePrice * 28) / 100 +
                                  (item.itemPurchasePrice * 60) / 100
                              ) * 1}{" "}
                            </>
                          ) : (
                            <>₹ {item.itemPurchasePrice}</>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                    {/* <div className="create-invoice-page-table-add-item-table-stock">
                      <div>
                        {" "}
                        {item.itemStock ? (
                          <>
                            {item.itemStock}{" "}
                            {item.itemMeasuringUnit
                              ? item.itemMeasuringUnit
                              : ""}{" "}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div> */}


                    <div className="create-invoice-page-table-add-item-table-quantity">

                      {
                        selectedItems.find((e) => e._id === item._id) ?

                           

                        businessData && businessData.invoiceSettingData?
                        businessData.invoiceSettingData.tabelQuantityOptions
                         ?

                         <div className="create-invoice-page-table-add-item-table-quantity-added">
                      {selectedItems.find((e) => e._id === item._id).quantity <= 1 ? (
                        <i
                          onClick={() => handleRemoveItem(selectedItems.find((e) => e._id === item._id)._id)}
                          class="fa-solid fa-square-minus"
                        ></i>
                      ) : (
                        <i
                          onClick={() => handleDecreaseQuanitiy(selectedItems.find((e) => e._id === item._id))}
                          class="fa-solid fa-square-minus"
                        ></i>
                      )}

                      <input
                        type="number"
                        defaultValue={selectedItems.find((e) => e._id === item._id).quantity}
                        onChange={(e) =>
                          handleQuantityChange(selectedItems.find((e) => e._id === item._id)._id, e.target.value)
                        }
                        min={0}
                        value={selectedItems.find((e) => e._id === item._id).quantity}
                      />
                      <i
                        onClick={() => handleIncreaseQuanitiy(selectedItems.find((e) => e._id === item._id))}
                        class="fa-solid fa-square-plus"
                      ></i>
                      <div>
                        {item.itemMeasuringUnit ? item.itemMeasuringUnit : "-"}
                      </div>
                    </div>
                         
                         :
                        <div
                        onClick={() => handleRemoveItem(item._id)}
                        className="create-invoice-page-table-add-item-table-quantity-del"
                      >
                        + del
                      </div>

                        :""
                        


                      
                      :
                      <div
                        onClick={() => handleAddItem(item)}
                        className="create-invoice-page-table-add-item-table-quantity-add"
                        >
                        + Add
                      </div>
                      }

                      
                    </div>

                    
                  </div>
                ))}

              {businessData.itemData.filter((e) =>
                e.itemName
                  .toLocaleLowerCase()
                  .includes(searchItem.toLowerCase())
              ).length <= 0 ? (
                <div className="create-invoice-page-table-add-item-table-empty">
                  No Items found
                </div>
              ) : (
                ""
              )}
            </div>

          </div>

          <div className="invoice-small-page-select-item">
            {businessData.itemData
              .filter((e) =>
                e.itemName
                  .toLocaleLowerCase()
                  .includes(searchItem.toLowerCase())
              )
              .reverse()
              .map((item) => (
                <div className="invoice-small-page-select-item-col">
                  <div className="invoice-small-page-select-item-col-details">
                    <div className="invoice-small-page-select-item-col-name">
                      {item.itemName}
                    </div>
                    {viewItemDetails === item._id ? (
                      <div className="invoice-small-page-select-item-view">
                        <div className="invoice-small-page-select-item-view-col">
                          {item.itemDescription ? item.itemDescription : ""}
                        </div>

                        {item.itemCode ? (
                          <div className="invoice-small-page-select-item-view-col">
                            <div>Code :</div>
                            <span>{item.itemCode}</span>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="invoice-small-page-select-item-view-col">
                          <div>Sales Price :</div>
                          <span>
                            {" "}
                            {item.itemPrice ? (
                              <>
                                {item.itemTax === "0" ||
                                item.itemTax === "0.1" ||
                                item.itemTax === "0.25" ||
                                item.itemTax === "1.5" ||
                                item.itemTax === "3" ||
                                item.itemTax === "5" ||
                                item.itemTax === "6" ||
                                item.itemTax === "12" ||
                                item.itemTax === "13.8" ||
                                item.itemTax === "18" ||
                                item.itemTax === "28" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPrice +
                                        (item.itemPrice *
                                          Number(item.itemTax)) /
                                          100
                                    ) * 1}{" "}
                                  </>
                                ) : item.itemTax === "14,12" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPrice +
                                        (item.itemPrice * 14) / 100 +
                                        (item.itemPrice * 12) / 100
                                    ) * 1}{" "}
                                  </>
                                ) : item.itemTax === "28,12" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPrice +
                                        (item.itemPrice * 28) / 100 +
                                        (item.itemPrice * 12) / 100
                                    ) * 1}{" "}
                                  </>
                                ) : item.itemTax === "28,60" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPrice +
                                        (item.itemPrice * 28) / 100 +
                                        (item.itemPrice * 60) / 100
                                    ) * 1}{" "}
                                  </>
                                ) : (
                                  <>₹ {item.itemPrice}</>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>

                        <div className="invoice-small-page-select-item-view-col">
                          <div>Purchase Price :</div>
                          <span>
                            {item.itemPurchasePrice ? (
                              <>
                                {item.itemTax === "0" ||
                                item.itemTax === "0.1" ||
                                item.itemTax === "0.25" ||
                                item.itemTax === "1.5" ||
                                item.itemTax === "3" ||
                                item.itemTax === "5" ||
                                item.itemTax === "6" ||
                                item.itemTax === "12" ||
                                item.itemTax === "13.8" ||
                                item.itemTax === "18" ||
                                item.itemTax === "28" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPurchasePrice +
                                        (item.itemPurchasePrice *
                                          Number(item.itemTax)) /
                                          100
                                    ) * 1}{" "}
                                  </>
                                ) : item.itemTax === "14,12" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPurchasePrice +
                                        (item.itemPurchasePrice * 14) / 100 +
                                        (item.itemPurchasePrice * 12) / 100
                                    ) * 1}{" "}
                                  </>
                                ) : item.itemTax === "28,12" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPurchasePrice +
                                        (item.itemPurchasePrice * 28) / 100 +
                                        (item.itemPurchasePrice * 12) / 100
                                    ) * 1}{" "}
                                  </>
                                ) : item.itemTax === "28,60" ? (
                                  <>
                                    ₹{" "}
                                    {formatNumber(
                                      item.itemPurchasePrice +
                                        (item.itemPurchasePrice * 28) / 100 +
                                        (item.itemPurchasePrice * 60) / 100
                                    ) * 1}{" "}
                                  </>
                                ) : (
                                  <>₹ {item.itemPurchasePrice}</>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>

                        <div className="invoice-small-page-select-item-view-col">
                          <div>Tax :</div>
                          <span>
                            {item.itemTax ? (
                              <>
                                {item.itemTax === "14,12"
                                  ? "14% + Cess 12"
                                  : item.itemTax === "28,12"
                                  ? "28% + Cess 12"
                                  : item.itemTax === "28,60"
                                  ? "28% + Cess 60"
                                  : item.itemTax}
                              </>
                            ) : (
                              "-"
                            )}
                            %
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="invoice-small-page-select-item-col-price">
                        {selectType === "Purchase"?
                          item.itemPurchasePrice ? (
                            <>
                              {item.itemTax === "0" ||
                              item.itemTax === "0.1" ||
                              item.itemTax === "0.25" ||
                              item.itemTax === "1.5" ||
                              item.itemTax === "3" ||
                              item.itemTax === "5" ||
                              item.itemTax === "6" ||
                              item.itemTax === "12" ||
                              item.itemTax === "13.8" ||
                              item.itemTax === "18" ||
                              item.itemTax === "28" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice *
                                        Number(item.itemTax)) /
                                        100
                                  ) * 1}{" "}
                                </>
                              ) : item.itemTax === "14,12" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice * 14) / 100 +
                                      (item.itemPurchasePrice * 12) / 100
                                  ) * 1}{" "}
                                </>
                              ) : item.itemTax === "28,12" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice * 28) / 100 +
                                      (item.itemPurchasePrice * 12) / 100
                                  ) * 1}{" "}
                                </>
                              ) : item.itemTax === "28,60" ? (
                                <>
                                  ₹{" "}
                                  {formatNumber(
                                    item.itemPurchasePrice +
                                      (item.itemPurchasePrice * 28) / 100 +
                                      (item.itemPurchasePrice * 60) / 100
                                  ) * 1}{" "}
                                </>
                              ) : (
                                <>₹ {item.itemPurchasePrice}</>
                              )}
                            </>
                          ) : (
                            "-"
                          )
                          
                          :
                        item.itemPrice ? (
                          <>
                            {item.itemTax === "0" ||
                            item.itemTax === "0.1" ||
                            item.itemTax === "0.25" ||
                            item.itemTax === "1.5" ||
                            item.itemTax === "3" ||
                            item.itemTax === "5" ||
                            item.itemTax === "6" ||
                            item.itemTax === "12" ||
                            item.itemTax === "13.8" ||
                            item.itemTax === "18" ||
                            item.itemTax === "28" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPrice +
                                    (item.itemPrice * Number(item.itemTax)) /
                                      100
                                ) * 1}{" "}
                              </>
                            ) : item.itemTax === "14,12" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPrice +
                                    (item.itemPrice * 14) / 100 +
                                    (item.itemPrice * 12) / 100
                                ) * 1}{" "}
                              </>
                            ) : item.itemTax === "28,12" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPrice +
                                    (item.itemPrice * 28) / 100 +
                                    (item.itemPrice * 12) / 100
                                ) * 1}{" "}
                              </>
                            ) : item.itemTax === "28,60" ? (
                              <>
                                ₹{" "}
                                {formatNumber(
                                  item.itemPrice +
                                    (item.itemPrice * 28) / 100 +
                                    (item.itemPrice * 60) / 100
                                ) * 1}{" "}
                              </>
                            ) : (
                              <>₹ {item.itemPrice}</>
                            )}
                          </>
                        ) : (
                          "-"
                        )
                        }
                        <span onClick={() => setViewItemDetails(item._id)}>
                          view <i className="fa-solid fa-angle-down"></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="invoice-small-page-select-item-col-buttons">
                    {selectedItems.find((e) => e._id === item._id) ? (
                      <div className="invoice-small-page-select-item-col-delete">

                        
{
                        businessData && businessData.invoiceSettingData?
                        businessData.invoiceSettingData.tabelQuantityOptions
                         ?
                      <input
                          type="number"
                          defaultValue={
                            selectedItems.find((e) => e._id === item._id)
                              .quantity
                          }
                          onChange={(e) =>
                            handleQuantityChange(item._id, e.target.value)
                          }
                          min={0}
                          value={
                            selectedItems.find((e) => e._id === item._id)
                              .quantity
                          }
                        />
                        :""
                        :""
                        }

                        <i
                          onClick={() => handleRemoveItem(item._id)}
                          className="fa-solid fa-trash-can"
                        ></i>
                      </div>
                    ) : (
                      <div
                        className="invoice-small-page-select-item-col-add"
                        onClick={() => handleAddItem(item)}
                      >
                        Add
                      </div>
                    )}
                    {viewItemDetails === item._id ? (
                      <div
                        onClick={() => setViewItemDetails(false)}
                        className="invoice-small-page-select-item-col-close"
                      >
                        close
                        <i className="fa-solid fa-angle-up" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}

            {businessData.itemData.filter((e) =>
              e.itemName.toLocaleLowerCase().includes(searchItem.toLowerCase())
            ).length > 0 ? (
              ""
            ) : (
              <div className="invoice-small-page-select-item-empty">
                No items
              </div>
            )}
          </div>

          <div className="create-invoice-page-table-add-item-button">
            <div
              onClick={() => {
                open(false);
                setSearchItem("");
                setViewItemDetails("");
              }}
            >
              Done
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSelectItemsPopup;
